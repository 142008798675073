import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleFunction, SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyleText: SystemStyleFunction = () => {
	return {
		color: "red.500",
		fontSize: "body3",
		fontWeight: "body3",
		lineHeight: "body3",
		letterSpacing: "body3",
	};
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
	text: baseStyleText(props),
});

export default {
	baseStyle,
};
