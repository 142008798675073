import React from "react";

import { Box, HStack, Text } from "@chakra-ui/react";

import { subtitulosSecciones, titulosSecciones } from "../secciones/titulosSecciones";

interface SeccionHeaderProps {
	seccion: string;
	children?: React.ReactNode;
}

export const SeccionHeader: React.FC<SeccionHeaderProps> = ({ seccion, children }) => {
	return (
		<React.Fragment>
			<Text textStyle="body1" color="black" fontWeight="bold" marginBottom="1.5rem" marginTop="1rem">
				{titulosSecciones[`seccion${seccion}`]}
			</Text>
			<HStack justifyContent="space-between" alignItems="center" spacing="1rem">
				{subtitulosSecciones[`seccion${seccion}`] && (
					<Text textStyle="body3" color="gray.600" mb="1.81rem" flex={1}>
						{subtitulosSecciones[`seccion${seccion}`]}
					</Text>
				)}
				<Box flex={1}>{children}</Box>
			</HStack>
		</React.Fragment>
	);
};
