type ModalCambioDeEstadoInfo = {
	estadoActual: string;
	estado: "Completada" | "Supervisada" | "Validada" | "Rechazada";
	texto1: string;
	informar_a: string;
	color: string;
	hoverBoton: string;
};

export const modalSelector: ModalCambioDeEstadoInfo[] = [
	{
		estadoActual: "En proceso",
		estado: "Completada",
		texto1: "La hoja de visita no podrá seguir editándose, y pasará a supervisión para su prevalidación.",
		informar_a: "supervisor",
		color: "blue.400",
		hoverBoton: "blue.500",
	},
	{
		estadoActual: "Completada",
		estado: "Supervisada",
		texto1: "La hoja de resolución no podrá seguir editándose, y pasará a administrador para su validación definitiva.",
		informar_a: "administrador",
		color: "teal.400",
		hoverBoton: "teal.500",
	},
	{
		estadoActual: "Supervisada",
		estado: "Validada",
		texto1:
			"La evaluación quedará cerrada y no podrá seguir editándose. Asegúrate de que todos los cambios están implementados en SF.",
		informar_a: "administrador",
		color: "green.400",
		hoverBoton: "green.500",
	},
	{
		estadoActual: "Rechazada",
		estado: "Supervisada",
		texto1: "La hoja de resolución no podrá seguir editándose, y pasará a administrador para su validación definitiva.",
		informar_a: "administrador",
		color: "teal.400",
		hoverBoton: "teal.500",
	},
];
