import React from "react";

import { Text } from "@chakra-ui/react";

type DataStructure = {
	[key: string]: {
		[week: string]: {
			[day: string]: number;
		};
	};
};

type DisplayInfoProps = {
	dataSource: DataStructure;
	turnosKey: string;
	turnosValue: number;
};

const DisplayInfo: React.FC<DisplayInfoProps> = (props) => {
	const { dataSource, turnosKey, turnosValue } = props;

	const findInfo = () => {
		for (const week in dataSource[turnosKey]) {
			for (const day in dataSource[turnosKey][week]) {
				if (dataSource[turnosKey][week][day] === turnosValue) {
					return `${week}: ${day}`;
				}
			}
		}
		return null;
	};

	return (
		<Text textStyle="body3" mt=".15rem">
			{findInfo()}
		</Text>
	);
};

export default DisplayInfo;
