import React from "react";

import { Flex, Spinner } from "@chakra-ui/react";

const FullsizeSpinner: React.FC = () => {
	return (
		<Flex h="100%" w="100%" alignItems="center" justifyContent="center">
			<Spinner color="red.500" />
		</Flex>
	);
};

export default FullsizeSpinner;
