import React from "react";

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	Input,
	Text,
	VStack,
} from "@chakra-ui/react";
import { debounce } from "lodash";

import InputField from "common/components/InputField/InputField";
import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useContactFieldChangeDetector, useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion01: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_1);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_1_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_1");
	const [showANewInput, setShowANewInput] = React.useState(false);
	const [newContact, setNewContact] = React.useState({
		nombre_contacto: "",
		apellidos_contacto: "",
		cargo_contacto: "",
		email_contacto: "",
		telefono_contacto: "",
	});
	const [debouncedNewContact, setDebouncedNewContact] = React.useState(newContact);

	const isValidContact = (contact: Record<string, string>) => {
		return Object.values(contact).some((value) => value.trim() !== "");
	};

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(2);

	const renderEbInfo = (title: string, value?: string, boldValue = false) => (
		<Flex justifyContent="space-between" alignItems="center" width="100%">
			<Text textStyle="body2" color="gray.600" mr=".25rem">
				{title}
			</Text>
			<Text textStyle="body3" {...(boldValue && { fontWeight: "700" })} color="black" flex="1">
				{value || "-"}
			</Text>
		</Flex>
	);

	const handleAddNewContactButtonClick = () => {
		setShowANewInput(true);
	};

	// const handleAdditionalContactsFieldChange = (nombre: string, apellido: string, fieldName: string) =>
	// 	debounce((event) => {
	// 		const updatedContacts = seccionDataActual?.contacts.map((contact) =>
	// 			contact.nombre_contacto === nombre && contact.apellidos_contacto === apellido
	// 				? { ...contact, [fieldName]: event.target.value }
	// 				: contact,
	// 		);
	// 		sendInputValue("contacts", JSON.stringify(updatedContacts));
	// 	}, 700);

	const handleAdditionalContactsFieldChange = (nombre: string, apellido: string, fieldName: string) =>
		debounce((event: React.ChangeEvent<HTMLInputElement>) => {
			// Ensure contacts array is not undefined
			const contacts = seccionDataActual?.contacts || [];

			let updatedContacts = contacts.map((contact) =>
				contact.nombre_contacto === nombre && contact.apellidos_contacto === apellido
					? { ...contact, [fieldName]: event.target.value.trim() }
					: contact,
			);

			// Filter to ensure no empty contacts
			updatedContacts = updatedContacts.filter((contact) => {
				const isEmpty = !Object.values(contact).some((value) => value.trim() !== "");
				return !isEmpty;
			});

			// Type assertion here
			sendInputValue("contacts", JSON.stringify(updatedContacts));
		}, 700);

	const fieldHasChanged = useContactFieldChangeDetector(evaluacionAnterior, evaluacion);

	React.useEffect(() => {
		if (!seccionDataActual || !isValidContact(debouncedNewContact)) return;
		if (debouncedNewContact.nombre_contacto === "") return;

		const contacts = seccionDataActual.contacts.filter(
			(contact) =>
				contact.nombre_contacto !== debouncedNewContact.nombre_contacto &&
				contact.apellidos_contacto !== debouncedNewContact.apellidos_contacto,
		);
		const updatedContacts = [...contacts, debouncedNewContact];
		const updatedContactsString = JSON.stringify(updatedContacts);

		sendInputValue("contacts", updatedContactsString);
	}, [debouncedNewContact]);

	React.useEffect(() => {
		const debounceTimer = setTimeout(() => {
			setDebouncedNewContact(newContact);
		}, 700);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, [newContact]);

	return (
		<Box>
			<SeccionHeader seccion="01" />
			<Box width="100%">
				<Text textStyle="body3" color="BAMblue" fontWeight="700" mb=".5rem">
					Nombre:&nbsp;{seccionDataActual?.nombre_eb}
				</Text>
				<Grid gridTemplateColumns="repeat(2, 1fr)" mt="0" rowGap={0} columnGap="0.2rem">
					<GridItem>
						<VStack width="100%" alignItems="flex-start" spacing="0">
							{renderEbInfo("Código entidad:", evaluacion?.cod_eb.toString(), true)}
							{renderEbInfo("Actividad:", seccionDataActual?.tipo_de_actividad)}
							{renderEbInfo("Grupo visitadores:", seccionDataActual?.grupo_visitador)}
							{renderEbInfo("Grupo homogéneo:", seccionDataActual?.grupo_homogeneo)}
							{renderEbInfo("Situación:", seccionDataActual?.status)}
						</VStack>
					</GridItem>
					<GridItem>
						<VStack width="100%" alignItems="flex-start" spacing="0">
							{renderEbInfo("NIF:", seccionDataActual?.cif)}
							{renderEbInfo("Registro:", seccionDataActual?.registro)}
							{renderEbInfo("Estatutos:", seccionDataActual?.tiene_estatutos ? "Sí" : "No")}
							{renderEbInfo("Compromisos mutuos:", seccionDataActual?.compromisos_mutuos ? "Sí" : "No")}
						</VStack>
					</GridItem>
				</Grid>
			</Box>
			<Divider margin="1.5rem 0" color="gray.200" />

			<Grid
				gridTemplateColumns="repeat(4, 1fr)"
				rowGap="1.06rem"
				columnGap="2.19rem"
				style={{ pageBreakInside: "avoid" }}
			>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Dirección de la actividad
					</Text>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Dirección actividad</FormLabel>
						<InputField
							defaultValue={seccionDataActual?.direccion_actividad}
							name="direccion_actividad"
							{...changeDetector("direccion_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Municipio</FormLabel>
						<InputField
							isDisabled={isDisabled}
							name="municipio_actividad"
							defaultValue={seccionDataActual?.municipio_actividad}
							{...changeDetector("municipio_actividad")}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>C.P.</FormLabel>
						<InputField
							name="codpost_actividad"
							defaultValue={seccionDataActual?.codpost_actividad}
							{...changeDetector("codpost_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Email general</FormLabel>
						<InputField
							name="email"
							defaultValue={seccionDataActual?.email}
							{...changeDetector("email")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Teléfono</FormLabel>
						<InputField
							name="telefono_actividad"
							defaultValue={seccionDataActual?.telefono_actividad}
							{...changeDetector("telefono_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
			</Grid>
			<Divider margin="1.5rem 0" color="gray.200" />
			<Grid
				gridTemplateColumns="repeat(4, 1fr)"
				rowGap="1.06rem"
				columnGap="2.19rem"
				style={{ pageBreakInside: "avoid" }}
			>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Dirección facturación
					</Text>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Dirección facturación</FormLabel>
						<InputField
							name="direccion_postal_direccion_actividad"
							defaultValue={seccionDataActual?.direccion_postal_direccion_actividad}
							{...changeDetector("direccion_postal_direccion_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Municipio</FormLabel>
						<InputField
							name="direccion_postal_municipio_actividad"
							defaultValue={seccionDataActual?.direccion_postal_municipio_actividad}
							{...changeDetector("direccion_postal_municipio_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>C.P.</FormLabel>
						<InputField
							name="direccion_postal_codpost_actividad"
							defaultValue={seccionDataActual?.direccion_postal_codpost_actividad}
							{...changeDetector("direccion_postal_codpost_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Teléfono</FormLabel>
						<InputField
							name="direccion_postal_telefono_actividad"
							defaultValue={seccionDataActual?.direccion_postal_telefono_actividad}
							{...changeDetector("direccion_postal_telefono_actividad")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
			</Grid>
			<Divider margin="1.5rem 0" color="gray.200" />
			<Grid
				gridTemplateColumns="repeat(4, 1fr)"
				rowGap="1.06rem"
				columnGap="2.19rem"
				style={{ pageBreakInside: "avoid" }}
			>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Persona responsable
					</Text>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Nombre</FormLabel>
						<InputField
							name="nombre_responsable"
							defaultValue={seccionDataActual?.nombre_responsable}
							{...changeDetector("nombre_responsable")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Apellidos</FormLabel>
						<InputField
							name="apellidos_responsable"
							defaultValue={seccionDataActual?.apellidos_responsable}
							{...changeDetector("apellidos_responsable")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Cargo</FormLabel>
						<InputField
							name="cargo_responsable"
							defaultValue={seccionDataActual?.cargo_responsable}
							{...changeDetector("cargo_responsable")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<InputField
							name="email_responsable"
							defaultValue={seccionDataActual?.email_responsable}
							{...changeDetector("email_responsable")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Teléfono</FormLabel>
						<InputField
							name="telefono_responsable"
							defaultValue={seccionDataActual?.telefono_responsable}
							{...changeDetector("telefono_responsable")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
			</Grid>
			<Divider margin="1.5rem 0" color="gray.200" />
			<Grid
				gridTemplateColumns="repeat(4, 1fr)"
				rowGap="1.06rem"
				columnGap="2.19rem"
				style={{ pageBreakInside: "avoid" }}
			>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Persona de contacto
					</Text>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Nombre</FormLabel>
						<InputField
							name="nombre_contacto"
							defaultValue={seccionDataActual?.nombre_contacto}
							{...changeDetector("nombre_contacto")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Apellidos</FormLabel>
						<InputField
							name="apellidos_contacto"
							defaultValue={seccionDataActual?.apellidos_contacto}
							{...changeDetector("apellidos_contacto")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Cargo</FormLabel>
						<InputField
							name="cargo_contacto"
							defaultValue={seccionDataActual?.cargo_contacto}
							{...changeDetector("cargo_contacto")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2}>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<InputField
							name="email_contacto"
							defaultValue={seccionDataActual?.email_contacto}
							{...changeDetector("email_contacto")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Teléfono</FormLabel>
						<InputField
							name="telefono_contacto"
							defaultValue={seccionDataActual?.telefono_contacto}
							{...changeDetector("telefono_contacto")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
			</Grid>
			{seccionDataActual &&
				seccionDataActual?.contacts.length > 0 &&
				seccionDataActual?.contacts.map((contact, i) => {
					return (
						<React.Fragment key={i}>
							<Divider margin="1.5rem 0" width="100%" />
							<HStack justifyContent="space-between" alignItems="center">
								<Text textStyle="body2" color="black" marginBottom="0.75rem" fontWeight="700">
									Persona de contacto {i + 2}
								</Text>
							</HStack>
							<Grid
								gridTemplateColumns="repeat(4, 1fr)"
								rowGap="1.06rem"
								columnGap="2.19rem"
								style={{ pageBreakInside: "avoid" }}
							>
								<GridItem colSpan={2}>
									<FormControl>
										<FormLabel>Nombre</FormLabel>
										<Input
											name="nombre_contacto"
											defaultValue={contact.nombre_contacto}
											onChange={handleAdditionalContactsFieldChange(
												contact.nombre_contacto,
												contact.apellidos_contacto,
												"nombre_contacto",
											)}
											isDisabled={isDisabled}
											{...fieldHasChanged(contact, "nombre_contacto")}
										/>
									</FormControl>
								</GridItem>
								<GridItem colSpan={2}>
									<FormControl>
										<FormLabel>Apellidos</FormLabel>
										<Input
											name="apellidos_contacto"
											defaultValue={contact.apellidos_contacto}
											onChange={handleAdditionalContactsFieldChange(
												contact.nombre_contacto,
												contact.apellidos_contacto,
												"apellidos_contacto",
											)}
											isDisabled={isDisabled}
											{...fieldHasChanged(contact, "apellidos_contacto")}
										/>
									</FormControl>
								</GridItem>
								<GridItem colSpan={2}>
									<FormControl>
										<FormLabel>Cargo</FormLabel>
										<Input
											name="cargo_contacto"
											defaultValue={contact.cargo_contacto}
											onChange={handleAdditionalContactsFieldChange(
												contact.nombre_contacto,
												contact.apellidos_contacto,
												"cargo_contacto",
											)}
											isDisabled={isDisabled}
											{...fieldHasChanged(contact, "cargo_contacto")}
										/>
									</FormControl>
								</GridItem>
								<GridItem colSpan={2}>
									<FormControl>
										<FormLabel>Email</FormLabel>
										<Input
											name="email_contacto"
											defaultValue={contact.email_contacto}
											onChange={handleAdditionalContactsFieldChange(
												contact.nombre_contacto,
												contact.apellidos_contacto,
												"email_contacto",
											)}
											isDisabled={isDisabled}
											{...fieldHasChanged(contact, "email_contacto")}
										/>
									</FormControl>
								</GridItem>
								<GridItem>
									<FormControl>
										<FormLabel>Teléfono</FormLabel>
										<Input
											name="telefono_contacto"
											defaultValue={contact.telefono_contacto}
											onChange={handleAdditionalContactsFieldChange(
												contact.nombre_contacto,
												contact.apellidos_contacto,
												"telefono_contacto",
											)}
											isDisabled={isDisabled}
											{...fieldHasChanged(contact, "telefono_contacto")}
										/>
									</FormControl>
								</GridItem>
							</Grid>
						</React.Fragment>
					);
				})}
			<Divider margin="1.5rem 0" color="gray.200" />
			<Flex justifyContent="flex-start" alignItems="center">
				{!printView && seccionDataActual && !seccionDataActual?.contacts.length ? (
					<Button
						onClick={handleAddNewContactButtonClick}
						backgroundColor={"gray.400"}
						variant="solid"
						size="sm"
						mb="1.5rem"
						_hover={{ bg: "gray.500" }}
						isDisabled={showANewInput || isDisabled}
					>
						Añadir persona de contacto
					</Button>
				) : null}
			</Flex>
			{showANewInput && (
				<React.Fragment>
					<Flex justifyContent="space-between" alignItems="center">
						<Text textStyle="body2" color="black" marginBottom="0.75rem" fontWeight="700">
							Añadir nueva persona de contacto
						</Text>
					</Flex>
					<Grid
						gridTemplateColumns="repeat(4, 1fr)"
						rowGap="1.06rem"
						columnGap="2.19rem"
						style={{ pageBreakInside: "avoid" }}
					>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Nombre</FormLabel>
								<Input
									name="nombre_contacto"
									isDisabled={isDisabled}
									onChange={(e) => setNewContact({ ...newContact, nombre_contacto: e.target.value })}
									defaultValue={newContact.nombre_contacto}
								/>
							</FormControl>
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Apellidos</FormLabel>
								<Input
									name="apellidos_contacto"
									isDisabled={isDisabled}
									onChange={(e) => setNewContact({ ...newContact, apellidos_contacto: e.target.value })}
									defaultValue={newContact.apellidos_contacto}
								/>
							</FormControl>
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Cargo</FormLabel>
								<Input
									name="cargo_contacto"
									isDisabled={isDisabled}
									onChange={(e) => setNewContact({ ...newContact, cargo_contacto: e.target.value })}
									defaultValue={newContact.cargo_contacto}
								/>
							</FormControl>
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Email</FormLabel>
								<Input
									name="email_contacto"
									isDisabled={isDisabled}
									onChange={(e) => setNewContact({ ...newContact, email_contacto: e.target.value })}
									defaultValue={newContact.email_contacto}
								/>
							</FormControl>
						</GridItem>
						<GridItem>
							<FormControl>
								<FormLabel>Teléfono</FormLabel>
								<Input
									name="telefono_contacto"
									isDisabled={isDisabled}
									onChange={(e) => setNewContact({ ...newContact, telefono_contacto: e.target.value })}
									defaultValue={newContact.telefono_contacto}
								/>
							</FormControl>
						</GridItem>
					</Grid>
				</React.Fragment>
			)}
			<Divider mb="1rem" mt={showANewInput ? "1rem" : 0} color="gray.200" />
			<FormControl>
				<FormLabel>Otras modificaciones</FormLabel>
				<TextAreaInput
					name="otras_modificaciones"
					defaultValue={seccionDataActual?.otras_modificaciones}
					{...changeDetector("otras_modificaciones")}
					isDisabled={isDisabled}
				/>
			</FormControl>
			{!printView && (
				<SeccionSwitch
					hasNextSection
					seccionCompletada={seccionDataActual?.seccion_1_completada === 1}
					nextSection={handleNextSectionNavigation}
					onSeccionCompletadaChange={(flag) => {
						sendInputValue("seccion_1_completada", flag ? "1" : "0");
					}}
					isDisabled={selectorDisabled}
				/>
			)}
			{/* {evaluacion && (
				<ConfirmationModal isOpen={isOpen} onClose={onClose} newContacts={newContacts} evaluacion={evaluacion} />
			)} */}
		</Box>
	);
};

export default Seccion01;
