import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { StatePropWithThunkState, THUNK_STATE } from "config/types";
import { usuarioOutput } from "config/types/usuario";

import { getPermisosExtraReducer } from "./extraReducers";
import { SLICE_NAMES } from "../constant";

export interface ISlice {
	datosDeUsuario: {
		name: string;
		familyName: string;
		picture: string;
		email: string;
	};
	entidadesActivas: StatePropWithThunkState<string[]>;
	permissions: StatePropWithThunkState<usuarioOutput[]>;
	printView: boolean;
	thunkState: THUNK_STATE;
}

const sliceInitialState: ISlice = {
	datosDeUsuario: {
		name: "",
		familyName: "",
		picture: "",
		email: "",
	},
	entidadesActivas: { thunkState: THUNK_STATE.IDLE, data: [] },
	permissions: { thunkState: THUNK_STATE.IDLE, data: [] },
	printView: false,
	thunkState: THUNK_STATE.IDLE,
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.USUARIO}`,
	initialState: sliceInitialState,
	reducers: {
		setDatosdeUsuario: (state, action: PayloadAction<ISlice["datosDeUsuario"]>) => {
			state.datosDeUsuario = action.payload;
		},
		setPrintView: (state, action: PayloadAction<boolean>) => {
			state.printView = action.payload;
		},
	},
	extraReducers(builder) {
		getPermisosExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
