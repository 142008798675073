import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

type CardContainerProps = BoxProps;

export const CardContainer: React.FC<CardContainerProps> = (props) => {
	return (
		<Box padding="1.25rem 2rem" backgroundColor={props.bgColor || "white"} borderRadius="0.375rem" {...props}>
			{props.children}
		</Box>
	);
};
