import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		control: {
			borderColor: "gray.600",
			_checked: {
				bg: "gray.600",
				borderColor: "gray.600",
			},
		},
		label: {},
	},
	variants: {
		primary: {
			control: {
				_checked: {
					bg: "gray.500",
					borderColor: "gray.500",
				},
			},
		},
		greenCheckbox: {
			control: {
				borderColor: "gray.200",
				_checked: {
					bg: "#85DB80",
					borderColor: "#85DB80",
					_hover: {
						bg: "green.600",
						borderColor: "green.600",
					},
				},
			},
		},
		tealCheckbox: {
			control: {
				borderColor: "#52C4BA",
				_checked: {
					bg: "#52C4BA",
					borderColor: "#52C4BA",
					_hover: {
						bg: "teal.600",
						borderColor: "teal.600",
					},
				},
			},
		},
		orangeCheckbox: {
			control: {
				borderColor: "#FEB377",
				_checked: {
					bg: "#FEB377",
					borderColor: "#FEB377",
					_hover: {
						bg: "orange.600",
						borderColor: "orange.600",
					},
				},
			},
		},
		redCheckbox: {
			control: {
				borderColor: "red.500",
				_checked: {
					bg: "red.400",
					borderColor: "red.400",
					_hover: {
						bg: "red.600",
						borderColor: "red.600",
					},
				},
			},
		},
		blueCheckbox: {
			control: {
				borderColor: "blue.300",
				_checked: {
					bg: "blue.300",
					borderColor: "blue.300",
					_hover: {
						bg: "blue.600",
						borderColor: "blue.600",
					},
				},
			},
		},
		grayCheckbox: {
			control: {
				borderColor: "gray.500",
				_checked: {
					bg: "gray.500",
					borderColor: "gray.500",
					_hover: {
						bg: "gray.600",
						borderColor: "gray.600",
					},
				},
			},
		},
		pinkCheckbox: {
			control: {
				borderColor: "pink.400",
				_checked: {
					bg: "pink.400",
					borderColor: "pink.400",
					_hover: {
						bg: "pink.600",
						borderColor: "pink.600",
					},
				},
			},
		},
		lightCheckbox: {
			control: {
				borderColor: "gray.300",
				_checked: {
					bg: "gray.300",
					borderColor: "gray.300",
					_hover: {
						bg: "gray.600",
						borderColor: "gray.600",
					},
				},
			},
		},
		whiteCheckbox: {
			control: {
				borderColor: "gray.400",
				_checked: {
					bg: "white",
					borderColor: "gray.400",
					_hover: {
						bg: "gray.300",
						borderColor: "gray.300",
					},
				},
			},
			icon: {
				color: "gray.600",
				_checked: {
					color: "gray.600",
				},
				_hover: {
					color: "gray.100",
				},
			},
		},
		BAMcheckbox: {
			label: {
				color: "gray.700",
			},
			control: {
				borderColor: "gray.200",
				_checked: {
					bg: "BAMblue",
					borderColor: "BAMblue",
					_hover: {
						bg: "BAMblue",
						borderColor: "BAMblue",
					},
				},
			},
		},
	},
};

export default componentOverride;
