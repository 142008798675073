import React from "react";

import {
	Text,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Divider,
	Box,
} from "@chakra-ui/react";

import { Mapa, Marker } from "common/components/Mapa/Mapa";

import { DataMapa } from "./getEntidadesCoordinates";

interface ModalMapaProps {
	isOpen: boolean;
	onClose: () => void;
	dataMapa: DataMapa;
	marker: Marker;
}

export const ModalMapa: React.FC<ModalMapaProps> = (props) => {
	const { isOpen, onClose, dataMapa, marker } = props;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<ModalOverlay />
			<ModalContent containerProps={{ pt: "10rem" }}>
				<ModalHeader>
					<Text textStyle="body2" color="BAMblue" w="80%">
						{dataMapa.nombre_eb}
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody>
					<Box maxW="100%" maxH="100%">
						<Mapa marker={marker} />
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
