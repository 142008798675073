import React from "react";

import {
	Avatar,
	Flex,
	Link,
	List,
	ListIcon,
	ListItem,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
} from "@chakra-ui/react";
import { googleLogout } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { IToken } from "helpers/checkToken";
import { useAppDispatch, useAppSelector } from "store/store";
import { actions } from "store/usuario/reducer";

export const UserInfo: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const userInfo = useAppSelector((state) => state.usuario.datosDeUsuario);

	React.useEffect(() => {
		const token = localStorage.getItem("bam_token");
		if (token) {
			const decoded = jwtDecode<IToken>(token);
			dispatch(
				actions.setDatosdeUsuario({
					picture: decoded.picture || "",
					name: decoded.given_name || "",
					familyName: decoded.family_name || "",
					email: decoded.email || "",
				}),
			);
		}
	}, []);

	const logoutHandler = () => {
		localStorage.removeItem("bam_token");
		localStorage.removeItem("bam_refresh_token");
		googleLogout();
		navigate("/login");
		window.location.reload();
	};

	if (!userInfo.email) return null;

	return (
		<Popover placement="bottom-end">
			<PopoverTrigger>
				<Flex
					alignItems="center"
					_hover={{
						cursor: "pointer",
					}}
				>
					<Text pr=".5rem" textStyle="body3" color="white">
						{userInfo.name}&nbsp;{userInfo.familyName}
					</Text>
					<Avatar name={`${userInfo.name} ${userInfo.familyName}`} src={userInfo.picture} size="xs" />
				</Flex>
			</PopoverTrigger>
			<PopoverContent width="fit-content">
				<PopoverBody>
					<List>
						<ListItem>
							<Flex alignItems="center">
								<ListIcon as={FiLogOut} color="black" />
								<Link onClick={logoutHandler}>
									<Text textStyle="body3" color="black">
										Cerrar sesión
									</Text>
								</Link>
							</Flex>
						</ListItem>
					</List>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
