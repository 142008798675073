import React from "react";

import { Box, Divider, FormControl, FormLabel, Grid, GridItem, Input, Text } from "@chakra-ui/react";

import InputField from "common/components/InputField/InputField";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion09: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_9);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_9_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_9");

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(10);

	const totalIngresosAno = React.useMemo(() => {
		return (
			(Number(seccionDataActual?.subvenciones_oficiales_importe) || 0) +
			(Number(seccionDataActual?.ayudas_privadas_importe) || 0) +
			(Number(seccionDataActual?.aportaciones_prestacion_importe) || 0) +
			(Number(seccionDataActual?.ap6_otras_fuentes_importe) || 0)
		);
	}, [seccionDataActual]);

	const totalGastosAno = React.useMemo(() => {
		return (
			(Number(seccionDataActual?.cargas_sociales_importe) || 0) +
			(Number(seccionDataActual?.alquileres_servicios_importe) || 0) +
			(Number(seccionDataActual?.compra_alimentos_importe) || 0) +
			(Number(seccionDataActual?.otros_importe) || 0) +
			(Number(seccionDataActual?.importe_salarios) || 0)
		);
	}, [seccionDataActual]);

	const diferenciaIngresosGastos = React.useMemo(() => {
		return totalIngresosAno - totalGastosAno;
	}, [totalIngresosAno, totalGastosAno]);

	React.useEffect(() => {
		if (totalIngresosAno !== seccionDataActual?.total_ingresos_ano) {
			sendInputValue("total_ingresos_ano", totalIngresosAno);
		}
		if (totalGastosAno !== seccionDataActual?.total_gastos_ano) {
			sendInputValue("total_gastos_ano", totalGastosAno);
		}
	}, [totalIngresosAno, totalGastosAno]);

	return (
		<Box>
			<SeccionHeader seccion="09" />
			<Divider marginBottom="1.5rem" color="gray.200" />
			<FormControl>
				<FormLabel textStyle="body2" color="black" fontWeight="700">
					La entidad benéfica asegura que las subvenciones recibidas se destinan a
				</FormLabel>
				<InputField
					name="destino_subvenciones"
					defaultValue={seccionDataActual?.destino_subvenciones}
					{...changeDetector("destino_subvenciones")}
					isDisabled={isDisabled}
				/>
			</FormControl>
			<Divider marginY="1.5rem" color="gray.200" />
			<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1.25rem" style={{ pageBreakInside: "avoid" }}>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Ingresos
					</Text>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Subvenciones oficiales (organismo)</FormLabel>
						<InputField
							name="subvenciones_oficiales_nombre"
							defaultValue={seccionDataActual?.subvenciones_oficiales_nombre}
							{...changeDetector("subvenciones_oficiales_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="subvenciones_oficiales_importe"
							defaultValue={formatNumber(seccionDataActual?.subvenciones_oficiales_importe)}
							{...changeDetector("subvenciones_oficiales_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Ayudas privadas</FormLabel>
						<InputField
							name="ayudas_privadas_nombre"
							defaultValue={seccionDataActual?.ayudas_privadas_nombre}
							{...changeDetector("ayudas_privadas_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="ayudas_privadas_importe"
							defaultValue={formatNumber(seccionDataActual?.ayudas_privadas_importe)}
							{...changeDetector("ayudas_privadas_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Aportaciones por prestación</FormLabel>
						<InputField
							name="ingresos_aportaciones_prestacion_nombre"
							defaultValue={seccionDataActual?.ingresos_aportaciones_prestacion_nombre}
							{...changeDetector("ingresos_aportaciones_prestacion_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="aportaciones_prestacion_importe"
							defaultValue={formatNumber(seccionDataActual?.aportaciones_prestacion_importe)}
							{...changeDetector("aportaciones_prestacion_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Otras fuentes de ingresos</FormLabel>
						<InputField
							name="otras_fuentes_nombre"
							defaultValue={seccionDataActual?.otras_fuentes_nombre}
							{...changeDetector("otras_fuentes_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="ap6_otras_fuentes_importe"
							defaultValue={formatNumber(seccionDataActual?.ap6_otras_fuentes_importe)}
							{...changeDetector("ap6_otras_fuentes_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3} />
				<GridItem>
					<FormControl>
						<FormLabel>Total ingresos año</FormLabel>
						<InputField
							isDisabled
							value={formatNumber(seccionDataActual?.total_ingresos_ano)}
							{...changeDetector("total_ingresos_ano")}
						/>
					</FormControl>
				</GridItem>
			</Grid>
			<Divider marginY="1.5rem" color="gray.200" />
			<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1.25rem" alignItems="end" style={{ pageBreakInside: "avoid" }}>
				<GridItem colSpan={4}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Gastos
					</Text>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Nº personas con salario</FormLabel>
						<InputField
							name="numero_asalariados"
							defaultValue={seccionDataActual?.numero_asalariados}
							{...changeDetector("numero_asalariados")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="importe_salarios"
							defaultValue={formatNumber(seccionDataActual?.importe_salarios)}
							{...changeDetector("importe_salarios")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={2} />
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Cargas sociales</FormLabel>
						<InputField
							name="cargas_sociales_nombre"
							defaultValue={seccionDataActual?.cargas_sociales_nombre}
							{...changeDetector("cargas_sociales_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="cargas_sociales_importe"
							defaultValue={formatNumber(seccionDataActual?.cargas_sociales_importe)}
							{...changeDetector("cargas_sociales_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Alquiler / servicios</FormLabel>
						<InputField
							name="alquileres_servicios_nombre"
							defaultValue={seccionDataActual?.alquileres_servicios_nombre}
							{...changeDetector("alquileres_servicios_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="alquileres_servicios_importe"
							defaultValue={formatNumber(seccionDataActual?.alquileres_servicios_importe)}
							{...changeDetector("alquileres_servicios_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Compra de alimentos</FormLabel>
						<InputField
							name="compra_alimentos_nombre"
							defaultValue={seccionDataActual?.compra_alimentos_nombre}
							{...changeDetector("compra_alimentos_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="compra_alimentos_importe"
							defaultValue={formatNumber(seccionDataActual?.compra_alimentos_importe)}
							{...changeDetector("compra_alimentos_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3}>
					<FormControl>
						<FormLabel>Otros gastos</FormLabel>
						<InputField
							name="otros_nombre"
							defaultValue={seccionDataActual?.otros_nombre}
							{...changeDetector("otros_nombre")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem>
					<FormControl>
						<FormLabel>Importe anual (€)</FormLabel>
						<InputField
							name="otros_importe"
							defaultValue={formatNumber(seccionDataActual?.otros_importe)}
							{...changeDetector("otros_importe")}
							isDisabled={isDisabled}
						/>
					</FormControl>
				</GridItem>
				<GridItem colSpan={3} />
				<GridItem>
					<FormControl>
						<FormLabel>Total gastos año</FormLabel>
						<Input isDisabled value={formatNumber(totalGastosAno)} {...changeDetector("total_gastos_ano")} />
					</FormControl>
				</GridItem>
			</Grid>
			<Divider marginY="1.5rem" color="gray.200" />
			<Grid gridTemplateColumns="repeat(4, 1fr)" gap="1.25rem" alignItems="end">
				<GridItem colSpan={3} />
				<GridItem>
					<FormControl>
						<FormLabel>Diferencia ingresos y gastos</FormLabel>
						<Input isDisabled value={formatNumber(diferenciaIngresosGastos)} />
					</FormControl>
				</GridItem>
			</Grid>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_9_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_9_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion09;
