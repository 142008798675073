import React from "react";

import { Flex, Box } from "@chakra-ui/react";

type PropsType = {
	value: number;
	colorSchema?: string[];
};

export const BatteryChart: React.FC<PropsType> = (props) => {
	const { value, colorSchema } = props;
	const n = 5;
	const emptySlots = Math.max(n - value - 1, 0);
	return (
		<Flex h="100%" flexDir="row" justifyContent="flex-start" alignItems="center">
			{[...Array(value)].map((v, i) => {
				return (
					<Box
						w="0.35rem"
						mx="0.075rem"
						h="1rem"
						bgColor={colorSchema ? colorSchema[i] : `gray.${400 + i * 100}`}
						borderRadius="0.175rem"
						key={i}
					/>
				);
			})}

			{[...Array(emptySlots)].map((v, i) => {
				return <Box w="0.25rem" mx="0.094rem" h="0.875rem" bgColor="gray.100" borderRadius="0.125rem" key={i} />;
			})}
		</Flex>
	);
};
