import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { Layout } from "common/core/Layout";
import { Entidad } from "views/entidad";
import { Portfolio } from "views/portfolio";

export const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<Layout>
						<Portfolio />
					</Layout>
				}
			/>
			<Route
				path="/:codEB/:numeroEvaluacion"
				element={
					<Layout>
						<Entidad />
					</Layout>
				}
			/>
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};
