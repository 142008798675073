import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/anton";
import "@fontsource/cabin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import FullsizeSpinner from "common/components/FullsizeSpinner";
import { Layout } from "common/core/Layout";
import { AxiosInterceptor } from "config/AxiosInterceptor";
import { envConfig } from "config/env";
import { AppRoutes } from "config/routes";
import { theme } from "config/theme";
import { store, useAppDispatch } from "store/store";
import { getPermisosThunk } from "store/usuario/thunks";
import { LoginView } from "views/LoginView/LoginView";
import "mapbox-gl/dist/mapbox-gl.css";

export const App: React.FC = () => (
	<GoogleOAuthProvider clientId={envConfig.GOOGLE_CLIENT_ID}>
		<ProtectedApp />
	</GoogleOAuthProvider>
);

const ProtectedRoutes = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const token = localStorage.getItem("bam_token");
		const bam_refresh_token = localStorage.getItem("bam_refresh_token");

		if (!token || !bam_refresh_token) {
			navigate("/login");
		}
		dispatch(getPermisosThunk());
	}, []);

	return (
		<Routes>
			<Route
				path="/*"
				element={
					<React.Fragment>
						<AxiosInterceptor />
						<AppRoutes />
					</React.Fragment>
				}
			/>
		</Routes>
	);
};

const ProtectedApp: React.FC = () => {
	return (
		<ChakraProvider theme={theme}>
			<Provider store={store}>
				<React.Suspense fallback={<FullsizeSpinner />}>
					<BrowserRouter>
						<Routes>
							<Route
								path="/login"
								element={
									<Layout>
										<LoginView />
									</Layout>
								}
							/>
							<Route path="/*" element={<ProtectedRoutes />} />
						</Routes>
					</BrowserRouter>
				</React.Suspense>
			</Provider>
		</ChakraProvider>
	);
};
