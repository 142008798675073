import React from "react";

import { Box } from "@chakra-ui/react";
import { Point } from "geojson";
import mapboxgl from "mapbox-gl";
import { Map, Marker, MapRef } from "react-map-gl";

import { envConfig } from "config/env";

export type Marker = {
	id: string;
	name: string;
	geometry: Point;
};

interface IMapProps {
	marker: Marker;
}

export const Mapa: React.FC<IMapProps> = (props) => {
	const { marker } = props;
	const mapRef = React.useRef() as React.MutableRefObject<MapRef>;

	const onMapLoad = React.useCallback(() => {
		const bounds = new mapboxgl.LngLatBounds();
		const p = marker;
		bounds.extend([p.geometry.coordinates[0], p.geometry.coordinates[1]]);
		mapRef.current.fitBounds(bounds, { padding: 50, duration: 3000 });
	}, []);

	return (
		<Box id="map-view" width="100%" height="15rem" mb="2rem">
			<Map
				initialViewState={{
					longitude: marker.geometry.coordinates[0],
					latitude: marker.geometry.coordinates[1],
					zoom: 10,
				}}
				style={{ width: "100%", height: "100%" }}
				mapStyle="mapbox://styles/dhub/ckvtbd7q20luz14paghmwq4s1"
				mapboxAccessToken={envConfig.MAPBOX_TOKEN}
				attributionControl={false}
				onLoad={() => onMapLoad()}
				maxZoom={14}
				ref={mapRef}
			>
				<Marker
					key={marker.id}
					longitude={marker.geometry.coordinates[0]}
					latitude={marker.geometry.coordinates[1]}
					color="#224C7C"
					scale={0.8}
				/>
			</Map>
		</Box>
	);
};
