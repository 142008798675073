import { VStack, Img, useToast, Text, Box, Button } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import bamBlueLogo from "assets/images/bam_login_logo_blue.svg";
import { CardContainer } from "common/components/CardContainer";
import { envConfig } from "config/env";

export const LoginView: React.FC = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const googleLogin = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async (codeResponse) => {
			const res = await axios.post(`${envConfig.API_URL}/auth/google`, { code: codeResponse.code });
			localStorage.setItem("bam_token", res.data.id_token);
			localStorage.setItem("bam_refresh_token", res.data.refresh_token);
			navigate("/");
		},
		onError: () => {
			toast({
				title: "Error al iniciar sesión",
				description: "No se pudo iniciar sesión con Google. Por favor, intente nuevamente.",
				status: "error",
			});
		},
	});

	return (
		<VStack width="120%" justifyContent="center" backgroundColor="BAMblue" h="100%" m="0 -7rem">
			<CardContainer
				position="relative"
				top="-10rem"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				display="flex"
			>
				<Img src={bamBlueLogo} alt="BAM Logo" objectFit="cover" height="8rem" />
				<Box m="1rem 0">
					<Text ml="1rem" color="BAMblue" textStyle="heading1" textAlign={"center"}>
						Gestor de visitas
					</Text>
				</Box>
				<Button
					variant="solid"
					background="BAMblue"
					onClick={() => {
						googleLogin();
					}}
					_hover={{ bg: "gray.200" }}
					_active={{ bg: "gray.50" }}
					borderRadius="0.375rem"
				>
					Iniciar sesión con Google
				</Button>
				<Text ml="1rem" color="BAMblue" textStyle="body3" textAlign="center" fontWeight={"bold"} mt="2rem">
					BANCO DE ALIMENTOS DE MADRID
				</Text>
			</CardContainer>
		</VStack>
	);
};
