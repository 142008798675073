import { extendTheme } from "@chakra-ui/react";

import Button from "./Components/Button";
import Checkbox from "./Components/Checkbox";
import FormError from "./Components/FormError";
import FormLabel from "./Components/FormLabel";
import Input from "./Components/Input";
import Link from "./Components/Link";
import Popover from "./Components/Popover";
import Radio from "./Components/Radio";
import Select from "./Components/Select";
import Switch from "./Components/Switch";
import Textarea from "./Components/Textarea";

const fontSizeBreakpoints = {
	"@media screen and (max-width: 1480px)": {
		fontSize: "12px",
	},
	"@media screen and (min-width: 1480px) and (max-width: 1560px)": {
		fontSize: "14px",
	},
	"@media screen and (min-width: 1560px)": {
		fontSize: "16px",
	},
	"@media screen and (min-width: 2500px)": {
		fontSize: "24px",
	},
};

export const theme = extendTheme({
	colors: {
		teal: {
			100: "#BFF7EA",
			200: "#9DEDDA",
			300: "#77DCCC",
			400: "#52C4BA",
			500: "#32A4A0",
			600: "#21808B",
			700: "#14586B",
			800: "#104B65",
			900: "#0D3D59",
			lightest: "#D6FFF5",
			darkest: "#092B3E",
		},
		red: {
			100: "#FFD9D9",
			200: "#FFB1B3",
			300: "#FA777C",
			400: "#F2454D",
			500: "#E61E28",
			600: "#D10911",
			700: "#B60007",
			800: "#B60007",
			900: "#6D0003",
			lightest: "#FFEEEF",
			darkest: "#4D0002",
		},
		green: {
			100: "#CCF7C9",
			200: "#A3EB9F",
			300: "#85DB80",
			400: "#68C163",
			500: "#4BA046",
			600: "#4A7F45",
			700: "#3E5B3C",
			800: "#3D4E3B",
			900: "#2B372A",
			lightest: "#E4FDE3",
			darkest: "#20291F",
		},
		blue: {
			100: "#C3EAFE",
			200: "#96DCFD",
			300: "#6CC9F6",
			400: "#43B3EA",
			500: "#1E98D7",
			600: "#197CBE",
			700: "#005AAA",
			800: "#16477F",
			900: "#15335E",
			lightest: "#DBF2FF",
			darkest: "#0E223F",
		},
		orange: {
			100: "#FFE3C7",
			200: "#FFCC99",
			300: "#FEB377",
			400: "#F59148",
			500: "#E66E23",
			600: "#CC4A0D",
			700: "#AD2E01",
			800: "#8B1E00",
			900: "#691300",
			lightest: "#FFEDDB",
			darkest: "#4C0E00",
		},
		yellow: {
			100: "#FFFAA8",
			200: "#FFF780",
			300: "#FFEA66",
			400: "#FFDF4C",
			500: "#FFD10A",
			600: "#E1A900",
			700: "#BD8400",
			800: "#986100",
			900: "#724403",
			lightest: "#FFEDDB",
			darkest: "#4B2C02",
		},
		violet: {
			100: "#F0DBF3",
			200: "#E3B2EE",
			300: "#CD9CE3",
			400: "#A76BC7",
			500: "#7D4196",
			600: "#622B82",
			700: "#4F1E75",
			800: "#3F166E",
			900: "#2E1259",
			lightest: "#F9E9FC",
			darkest: "#1F0C3B",
		},
		pink: {
			100: "#FBD5EC",
			200: "#F6BEE2",
			300: "#ED90CB",
			400: "#DE63B1",
			500: "#C83C96",
			600: "#B31B79",
			700: "#99075E",
			800: "#7C044A",
			900: "#5F0136",
			lightest: "#FFF0F9",
			darkest: "#4C012B",
		},
		gray: {
			50: "#F7FAFC",
			100: "#EDF2F7",
			200: "#E2E8F0",
			300: "#CBD5E0",
			400: "#A0AEC0",
			500: "#718096",
			600: "#4A5568",
			700: "#2D3748",
			800: "#1A202C",
			900: "#171923",
			lightest: "#FAFAFA",
			darkest: "#1C1C1C",
			background: "#F6F6F6",
			table: "#F9FBFD",
		},
		blackAlpha: {
			50: "#0000000A",
			100: "#0000000F",
			200: "#00000014",
			300: "#00000029",
			400: "#0000003D",
			500: "#0000005C",
			600: "#0000007A",
			700: "#000000A3",
			800: "#000000CC",
			900: "#000000EB",
		},
		whiteAlpha: {
			50: "#FFFFFF0A",
			100: "#FFFFFF0F",
			200: "#FFFFFF14",
			300: "#FFFFFF29",
			400: "#FFFFFF3D",
			500: "#FFFFFF5C",
			600: "#FFFFFF7A",
			700: "#FFFFFFA3",
			800: "#FFFFFFCC",
			900: "#FFFFFFEB",
		},
		white: "#FFFFFF",
		black: "#000000",
		BAMblue: "#224C7C",
		Completada0: "#1E9BD7",
		Completada2: "#DBF2FF",
		Proceso0: "#E66E23",
		Proceso2: "#FFEDDB ",
		Supervisada0: "#32A4A0",
		Supervisada2: "#D6FFF5",
		Validada0: "#4BA046 ",
		Validada2: "#E4FDE3",
	},
	fonts: {
		heading: "Cabin",
		body: "Cabin",
		times: "Times New Roman",
	},
	fontWeights: {
		heading5: "normal",
	},
	fontSizes: {
		heading1: "3.75rem",
		heading2: "3rem",
		heading3: "2.25rem",
		heading4: "1.75rem",
		heading5: "1.5rem",
		heading6: "1.25rem",
		body0: "1.25rem",
		body1: "1.125rem",
		body2: "0.95rem",
		body3: "0.90rem",
		body4: "0.625rem",
	},
	lineHeights: {
		heading1: "3.75rem",
		heading2: "3rem",
		heading3: "2.5rem",
		heading4: "2.25rem",
		heading5: "2rem",
		heading6: "1.75rem",
		body0: "1.75rem",
		body1: "1.5rem",
		body2: "1.25rem",
		body3: "1rem",
		body4: "1rem",
	},
	components: {
		Button,
		Checkbox,
		Link,
		Input,
		Select,
		Textarea,
		FormLabel,
		Popover,
		FormError,
		Switch,
		Radio,
	},
	textStyles: {
		heading: {
			fontFamily: "heading",
			fontSize: "heading1",
			fontWeight: "heading1",
			lineHeight: "heading1",
			letterSpacing: "heading1",
		},
		heading2: {
			fontFamily: "heading",
			fontSize: "heading2",
			fontWeight: "heading2",
			lineHeight: "heading2",
			letterSpacing: "heading2",
		},
		heading3: {
			fontFamily: "heading",
			fontSize: "heading3",
			fontWeight: "heading3",
			lineHeight: "heading3",
			letterSpacing: "heading3",
		},
		heading4: {
			fontFamily: "heading",
			fontSize: "heading4",
			fontWeight: "heading4",
			lineHeight: "heading4",
			letterSpacing: "heading4",
		},
		heading5: {
			fontFamily: "heading",
			fontSize: "heading5",
			fontWeight: "heading5",
			lineHeight: "heading5",
			letterSpacing: "heading5",
		},
		heading6: {
			fontFamily: "heading",
			fontSize: "heading6",
			fontWeight: "heading6",
			lineHeight: "heading6",
			letterSpacing: "heading6",
		},
		body0: {
			fontFamily: "body",
			fontSize: "body0",
			fontWeight: "body0",
			lineHeight: "body0",
			letterSpacing: "body0",
		},
		body1: {
			fontFamily: "body",
			fontSize: "body1",
			fontWeight: "body1",
			lineHeight: "body1",
			letterSpacing: "body1",
		},
		body2: {
			fontFamily: "body",
			fontSize: "body2",
			fontWeight: "body2",
			lineHeight: "body2",
			letterSpacing: "body2",
		},
		body3: {
			fontFamily: "body",
			fontSize: "body3",
			fontWeight: "body3",
			lineHeight: "body3",
			letterSpacing: "body3",
		},
		body4: {
			fontFamily: "body",
			fontSize: "body4",
			fontWeight: "body4",
			lineHeight: "body4",
			letterSpacing: "body4",
		},
	},
	shadows: {
		outlinePrimary: "0 0 1px 2px #E61E2899",
		primary: "2px 2px 4px rgba(0, 0, 0, 0.2)",
	},
	styles: {
		global: () => ({
			"*": {
				boxSizing: "border-box",
			},
			html: {
				height: "100%",
				...fontSizeBreakpoints,
			},
			body: {
				height: "100%",
				fontFamily: "body",
				lineHeight: "body1",
				bg: "gray.50",
				"#root": {
					height: "100%",
					whiteSpace: "pre-line",
				},
			},
			"::-webkit-scrollbar": {
				width: "0.5rem",
			},
			"::-webkit-scrollbar-track": {
				background: "white.200",
			},
			"::-webkit-scrollbar-thumb": {
				background: "gray.200",
				borderRadius: "6px",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "gray.100",
			},
		}),
	},
	breakpoints: {
		xs: "0px",
		sm: "415px",
		md: "1200px",
		lg: "1560px",
		xl: "1736px",
	},
});
