export const dataCCOld = {
	canalseco_2do_turno: {
		"Semana 2": {
			lunes: 6,
			martes: 7,
			miércoles: 8,
			jueves: 9,
			viernes: 10,
		},
	},
	canalseco_3er_turno: {
		"Semana 3": {
			lunes: 11,
			martes: 12,
			miércoles: 13,
			jueves: 14,
			viernes: 15,
		},
	},
	canalseco_4to_turno: {
		"Semana 4": {
			lunes: 16,
			martes: 17,
			miércoles: 18,
			jueves: 19,
			viernes: 20,
		},
	},
};

export const dataFYHOld = {
	fyh_2do_turno: {
		"Semana 2": {
			lunes: 6,
			martes: 7,
			miércoles: 8,
			jueves: 9,
			viernes: 10,
		},
	},
	fyh_3er_turno: {
		"Semana 3": {
			lunes: 11,
			martes: 12,
			miércoles: 13,
			jueves: 14,
			viernes: 15,
		},
	},
	fyh_4to_turno: {
		"Semana 4": {
			lunes: 16,
			martes: 17,
			miércoles: 18,
			jueves: 19,
			viernes: 20,
		},
	},
};

export const dataCongOld = {
	cong_2do_turno: {
		"Semana 2": {
			lunes: 6,
			martes: 7,
			miércoles: 8,
			jueves: 9,
			viernes: 10,
		},
	},
	cong_3er_turno: {
		"Semana 3": {
			lunes: 11,
			martes: 12,
			miércoles: 13,
			jueves: 14,
			viernes: 15,
		},
	},
	cong_4to_turno: {
		"Semana 4": {
			lunes: 16,
			martes: 17,
			miércoles: 18,
			jueves: 19,
			viernes: 20,
		},
	},
};

export const dataMMOld = {
	mm_2do_turno: {
		"Semana 2": {
			lunes: 6,
			martes: 7,
			miércoles: 8,
			jueves: 9,
			viernes: 10,
		},
	},
	mm_3er_turno: {
		"Semana 3": {
			lunes: 11,
			martes: 12,
			miércoles: 13,
			jueves: 14,
			viernes: 15,
		},
	},
	mm_4to_turno: {
		"Semana 4": {
			lunes: 16,
			martes: 17,
			miércoles: 18,
			jueves: 19,
			viernes: 20,
		},
	},
	mm_5to_turno: {
		"Semana 4": {
			lunes: 16,
			martes: 17,
			miércoles: 18,
			jueves: 19,
			viernes: 20,
		},
	},
};

export const almacenesMapping = {
	"Canal Seco": ["canalseco_1er_turno", "canalseco_2do_turno", "canalseco_3er_turno", "canalseco_4to_turno"],
	"Frutas y Hortalizas": ["fyh_1er_turno", "fyh_2do_turno", "fyh_3er_turno", "fyh_4to_turno"],
	Congelados: ["cong_1er_turno", "cong_2do_turno", "cong_3er_turno", "cong_4to_turno"],
	Mercamadrid: ["mm_1er_turno", "mm_2do_turno", "mm_3er_turno", "mm_4to_turno", "mm_5to_turno"],
};

export const almacenKeyMapping = {
	"Canal Seco": "canalseco_almacen",
	"Frutas y Hortalizas": "fyh_almacen",
	Congelados: "cong_almacen",
	Mercamadrid: "mm_almacen",
};
