import axios, { AxiosRequestConfig } from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { ComentarioInput, ComentarioOutput } from "config/types/comentario";
import {
	EvaluacionOutput,
	EvaluacionItemListOutput,
	AdelantarVisitaInput,
	CambiarEstadoEvaluacionInput,
	ProgramarEvaluacionOutput,
	ProgramarEvaluacionInput,
} from "config/types/evaluacion";

interface getEvaluacionParams {
	codEB: string;
	numeroEvaluacion?: string;
}

export const getEvaluacion: CustomAxiosRequest<getEvaluacionParams, EvaluacionOutput> = (params) => {
	const { codEB, numeroEvaluacion } = params;
	const numeroEvaluacionNumber = numeroEvaluacion ? parseInt(numeroEvaluacion) : undefined;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}`;
	const config: AxiosRequestConfig<EvaluacionOutput> = {
		method: "GET",
		url,
		params: { numeroEvaluacion: numeroEvaluacionNumber },
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

interface getEvaluacionAnteriorParams {
	codEB: string;
	numeroEvaluacion: number;
}

export const getEvaluacionAnterior: CustomAxiosRequest<getEvaluacionAnteriorParams, EvaluacionOutput> = (params) => {
	const { codEB, numeroEvaluacion } = params;
	const numeroEvaluacionNumber = numeroEvaluacion ? Number(numeroEvaluacion) : undefined;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}/anterior`;
	const config: AxiosRequestConfig<EvaluacionOutput> = {
		method: "GET",
		url,
		params: { numeroEvaluacion: numeroEvaluacionNumber },
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

interface getEvaluacionesParams {
	codEB: string;
}

export const getEvaluaciones: CustomAxiosRequest<getEvaluacionesParams, EvaluacionItemListOutput[]> = (params) => {
	const { codEB } = params;
	const url = `${envConfig.API_URL}/evaluaciones/${codEB}`;
	const config: AxiosRequestConfig<EvaluacionItemListOutput[]> = {
		method: "GET",
		url,
		params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

interface putAdelantarEvaluacionParams {
	codEB: string;
	data: AdelantarVisitaInput;
}

export const putAdelantarEvaluacion: CustomAxiosRequest<putAdelantarEvaluacionParams, EvaluacionOutput> = (params) => {
	const { codEB, data } = params;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}/adelantar`;
	const config: AxiosRequestConfig<AdelantarVisitaInput> = {
		method: "PATCH",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data,
	};
	return axios(config);
};

interface patchCambiarEstadoEvaluacionParams {
	codEB: string;
	data: CambiarEstadoEvaluacionInput;
}

export const patchCambiarEstadoEvaluacion: CustomAxiosRequest<patchCambiarEstadoEvaluacionParams, EvaluacionOutput> = (
	params,
) => {
	const { codEB, data } = params;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}/cambiarEstado`;
	const config: AxiosRequestConfig<CambiarEstadoEvaluacionInput> = {
		method: "PATCH",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data,
	};
	return axios(config);
};

interface actualizarCampoEvaluacionParams {
	codEB: string;
	codEvaluacion: string;
	campo: string;
	valor: unknown;
}
export const actualizarCampoEvaluacion: CustomAxiosRequest<
	actualizarCampoEvaluacionParams,
	{ campo: string; valor: unknown }
> = (params) => {
	const { codEB, codEvaluacion, campo, valor } = params;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}/${codEvaluacion}`;

	const config: AxiosRequestConfig<{ campo: string; valor: unknown }> = {
		method: "PATCH",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: { campo, valor },
	};
	return axios(config);
};

interface postComentarioParams {
	codEB: string;
	comentario: ComentarioInput;
}

export const postComentario: CustomAxiosRequest<postComentarioParams, ComentarioOutput> = (params) => {
	const { comentario, codEB } = params;
	const url = `${envConfig.API_URL}/evaluacion/${codEB}/comentario`;
	const config: AxiosRequestConfig<ComentarioInput> = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: comentario,
	};
	return axios(config);
};

interface postProgramarEvaluacionParams {
	codeEB: string;
	nombre_eb: string;
	fecha_visita: string;
}

export const postProgramarEvaluacion: CustomAxiosRequest<postProgramarEvaluacionParams, ProgramarEvaluacionOutput> = (
	params,
) => {
	const { codeEB, nombre_eb, fecha_visita } = params;
	const url = `${envConfig.API_URL}/evaluacion/${codeEB}`;
	const config: AxiosRequestConfig<ProgramarEvaluacionInput> = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: { nombre_eb, fecha_visita },
	};
	return axios(config);
};
