import React from "react";

import { Text, Button } from "@chakra-ui/react";

import { checkPermission } from "helpers/checkPermissions";
import { useAppSelector } from "store/store";

interface BotonCambiarEstadoProps {
	onOpen: () => void;
	isDisabled?: boolean;
}

const BUTTON_DATA = {
	"En proceso": { texto: "Marcar como completada", color: "blue.400", hover: "blue.500" },
	Adelantada: { texto: "Marcar como completada", color: "blue.400", hover: "blue.500" },
	Completada: { texto: "Marcar como supervisada", color: "teal.400", hover: "teal.500" },
	Supervisada: { texto: "Marcar como validada", color: "green.400", hover: "green.500" },
	Rechazada: { texto: "Marcar como supervisada", color: "teal.400", hover: "teal.500" },
};

export const BotonCambiarEstado: React.FC<BotonCambiarEstadoProps> = ({ isDisabled, onOpen }) => {
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);

	const isVisitador = checkPermission(permisosUsuario, "visitador");
	const isSupervisor = checkPermission(permisosUsuario, "visitador supervisor");

	const isButtonDisabled = React.useMemo(() => {
		if (isDisabled && evaluacionSeleccionada?.estado_evaluacion !== "Programada") return true;
		if (isVisitador && evaluacionSeleccionada?.estado_evaluacion !== "En proceso") return true;
		if (isSupervisor && evaluacionSeleccionada?.estado_evaluacion === "Supervisada") return true;
		return false;
	}, [isDisabled, evaluacionSeleccionada, isVisitador, isSupervisor]);

	const estadoBoton =
		evaluacionSeleccionada?.estado_evaluacion && BUTTON_DATA[evaluacionSeleccionada.estado_evaluacion];

	return (
		<>
			{estadoBoton && (
				<Button
					backgroundColor={estadoBoton.color}
					w="12rem"
					_hover={{ bg: estadoBoton.hover }}
					loadingText="Enviando"
					isDisabled={isButtonDisabled}
					_active={{ bg: "gray.400" }}
					onClick={onOpen}
				>
					<Text textStyle="body3" color="white">
						{estadoBoton.texto}
					</Text>
				</Button>
			)}
		</>
	);
};
