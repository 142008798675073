import React from "react";

import { Text, Button } from "@chakra-ui/react";

interface BotonAdelantarVisitaProps {
	onOpenAdelantar: () => void;
}

export const BotonAdelantarVisita: React.FC<BotonAdelantarVisitaProps> = (props) => {
	const { onOpenAdelantar } = props;

	return (
		<React.Fragment>
			<Button
				backgroundColor="gray.400"
				w="12rem"
				_hover={{ bg: "gray.500" }}
				loadingText="Enviando"
				_active={{ bg: "gray.400" }}
				onClick={onOpenAdelantar}
				mr="1rem"
			>
				<Text textStyle="body3" color="white">
					Adelantar visita
				</Text>
			</Button>
		</React.Fragment>
	);
};
