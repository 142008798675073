import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/react";
import { PartsStyleObject } from "@chakra-ui/theme-tools";

export const baseStyle: PartsStyleObject<typeof parts> = {
	field: {
		fontSize: "body2",
		fontWeight: "body2",
		lineHeight: "body2",
		letterSpacing: "body2",
		color: "gray.700",
		border: "1px solid",
		borderColor: "gray.200",
		borderRadius: "0.375rem",
		padding: "0.6875rem 0.75rem",
		"&:focus": {
			outline: "2px solid",
			outlineColor: "blue.500",
			outlineOffset: "0",
		},

		_disabled: {
			fontSize: "body2",
			backgroundColor: "gray.50",
			color: "gray.700",
			fontWeight: "500",
			opacity: "1",
		},

		"&[data-ischanged='true']": {
			color: "green.400",
			borderColor: "green.400",
		},
	},
};

const componentOverride: ComponentStyleConfig = {
	parts: ["field"],
	baseStyle: baseStyle,
	defaultProps: {
		variant: "",
		size: "",
	},
};

export default componentOverride;
