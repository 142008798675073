import React from "react";

import { Box, Divider, Flex, FormControl, FormLabel, Grid, GridItem, Text } from "@chakra-ui/react";

import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import InputField from "common/components/InputField/InputField";
import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion05: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_5);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_5_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_5");

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(6);

	return (
		<Box>
			<SeccionHeader seccion="05" />
			<Box width="100%">
				<Divider marginBottom="1.5rem" color="gray.200" />
				<Grid templateColumns="repeat(3, 1fr)" columnGap="2rem" rowGap="1.5rem">
					<GridItem colSpan={1}>
						<Text textStyle="body2" color="black" marginBottom="0.62rem" fontWeight="700">
							Alimentos
						</Text>
						<Text textStyle="body2" color="gray.600">
							Congelados:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactcong)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							Alcalá:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactalcala)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							San Fernando:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactsf)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							Alcorcón:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactalcorcon)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							Mercamadrid:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactmerca)}&nbsp;kg
							</Text>
						</Text>
					</GridItem>
					<GridItem colSpan={1}>
						<Text textStyle="body2" color="black" marginBottom="0.62rem" fontWeight="700">
							Frutas y hortalizas
						</Text>
						<Text textStyle="body2" color="gray.600">
							Alcalá:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactfyhalcala)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							San Fernando:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactfyhsf)}&nbsp;kg
							</Text>
						</Text>
						<Text textStyle="body2" color="gray.600">
							Alcorcón:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactfyhalcorcon)}&nbsp;kg
							</Text>
						</Text>
					</GridItem>
					<GridItem colSpan={1}>
						<Text textStyle="body2" color="black" marginBottom="0.62rem" fontWeight="700">
							FEAD
						</Text>
						<Text textStyle="body2" color="gray.600">
							Alcalá:&nbsp;
							<Text as="span" color="black">
								{formatNumber(evaluacion?.seccion_5.anoactfeadalcala)}&nbsp;kg
							</Text>
						</Text>
					</GridItem>
					<Flex alignItems="center" gridColumn="1 / -1" gap={5}>
						<GridItem>
							<FormControl>
								<FormLabel>Total kg alimentos</FormLabel>
								<InputField isDisabled value={formatNumber(evaluacion?.seccion_5.total_kg_alimentos)} />
							</FormControl>
						</GridItem>
						<GridItem>
							<FormControl>
								<FormLabel>Total frutas y hortalizas</FormLabel>
								<InputField isDisabled value={formatNumber(evaluacion?.seccion_5.total_kg_fyh)} />
							</FormControl>
						</GridItem>
						<GridItem>
							<FormControl>
								<FormLabel>Total recibido del BAM</FormLabel>
								<InputField isDisabled value={formatNumber(evaluacion?.seccion_5.total_kg_recibido_bam)} />
							</FormControl>
						</GridItem>
					</Flex>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Grid
					templateColumns="repeat(2, 1fr)"
					columnGap="2.25rem"
					rowGap="1.75rem"
					style={{ pageBreakInside: "avoid" }}
				>
					<GridItem>
						{seccionDataActual?.datos_correctos_5 !== undefined && (
							<FormControl>
								<FormLabel>¿Los datos son correctos?</FormLabel>
								<Box width="fit-content">
									<CheckboxGroup
										disableNA
										name="datos_correctos_5"
										defaultValue={seccionDataActual?.datos_correctos_5}
										onChange={(name, value) => sendInputValue(name, value)}
										changed={changeDetector("datos_correctos_5")}
										isDisabled={isDisabled}
									/>
								</Box>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={3} mb=".5rem">
						<FormControl>
							<FormLabel>Comentarios</FormLabel>
							<TextAreaInput
								name="comentarios_5"
								defaultValue={seccionDataActual?.comentarios_5}
								{...changeDetector("comentarios_5")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
			</Box>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_5_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_5_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion05;
