import axios, { AxiosRequestConfig } from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { DocumentoInput, DocumentoRecord } from "config/types/documento";

interface getDocumentosParams {
	codigoEvaluacion: string;
	numeroEvaluacion: string;
}

export const getDocumentos: CustomAxiosRequest<getDocumentosParams, DocumentoRecord[]> = (params) => {
	const url = `${envConfig.API_URL}/evaluacion/documentos`;
	const config: AxiosRequestConfig<DocumentoRecord[]> = {
		method: "GET",
		url,
		params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

interface postDocumentoParams {
	data: DocumentoInput;
}

export const postDocumento: CustomAxiosRequest<postDocumentoParams, DocumentoRecord> = (params) => {
	const { data } = params;
	const url = `${envConfig.API_URL}/evaluacion/documentos`;
	const config: AxiosRequestConfig<DocumentoInput> = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return axios(config);
};

export type DeleteDocumentosParams = {
	idDocumento: string;
};

export const deleteDocumento: CustomAxiosRequest<DeleteDocumentosParams, void> = (params) => {
	const { idDocumento } = params;
	const url = `${envConfig.API_URL}/evaluacion/documentos/${idDocumento}}`;
	const config: AxiosRequestConfig = {
		method: "DELETE",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

export type GetDocumentoParams = {
	idEntidad: string;
};

export const getGoogleFolderId: CustomAxiosRequest<GetDocumentoParams, { id: string; name: string }> = (params) => {
	const url = `${envConfig.API_URL}/evaluacion/documentos/getGoogleDriveFolderId`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		params,
	};
	return axios(config);
};

export type GetGoogleDriveFileListParams = {
	folderId: string;
};

export const getGoogleDriveFileList: CustomAxiosRequest<
	GetGoogleDriveFileListParams,
	{ id: string; name: string }[]
> = (params) => {
	const url = `${envConfig.API_URL}/evaluacion/documentos/getGoogleDriveFileList`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		params,
	};
	return axios(config);
};

export const uploadToGoogleDrive: CustomAxiosRequest<{ file: File; folderId: string }, void> = (params) => {
	const url = `${envConfig.API_URL}/evaluacion/documentos/uploadToGoogleDrive`;
	const config: AxiosRequestConfig = {
		method: "POST",
		url,
		headers: {
			uploadType: "multipart",
		},
		data: params,
	};
	return axios(config);
};
