import React from "react";

import { Box, HStack, Text } from "@chakra-ui/react";

import { ComentarioOutput } from "config/types/comentario";

interface MessageProps {
	comentario: ComentarioOutput;
}

export const Message: React.FC<MessageProps> = ({ comentario }) => {
	const backgroundColorSelector = (accion: string) => {
		switch (accion) {
			case "Comentario":
				return "gray.50";
			case "Rechazada":
				return "pink.50";
			case "Completada":
				return "blue.50";
			case "Supervisada":
				return "teal.50";
			case "Validada":
				return "green.50";
			default:
				return "gray.50";
		}
	};

	return (
		<Box
			padding="1rem .5rem"
			backgroundColor={backgroundColorSelector(comentario.accion)}
			borderRadius="0.375rem"
			mt="1.25rem"
		>
			<React.Fragment>
				<Text textStyle="body3" color="black">
					Comentario:
				</Text>
				<HStack justifyContent={"space-between"}>
					<Text textStyle="body3" color="gray.600">
						{comentario.creado_por}
					</Text>
					<Text textStyle="body3" color="gray.600">
						{new Date(comentario.fecha_creacion).toLocaleString("es-ES", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						})}
					</Text>
				</HStack>
				<Text textStyle="body2" color="gray.800" mt="1rem">
					{comentario.comentario}
				</Text>
			</React.Fragment>
		</Box>
	);
};
