import React from "react";

import { Box, VStack, Text, HStack, Icon, IconButton, Divider } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import { EntidadOutput } from "config/types/entidad";

interface EntidadProps {
	entidad?: EntidadOutput;
}

export const SidebarHeader: React.FC<EntidadProps> = (props) => {
	const { entidad } = props;
	const { codEB } = useParams();
	const navigate = useNavigate();

	return (
		<Box position="relative" width="100%">
			<VStack spacing="0" alignItems="flex-start" width="90%">
				<Text color="gray.600" textStyle="body3" marginBottom="0.5rem" mt=".75rem">
					{codEB ? "Entidad Beneficiaria" : "Entidades Beneficiarias"}
				</Text>
				<Text textStyle="heading6" width="100%" color="BAMblue" noOfLines={2} fontFamily={"Anton"}>
					{codEB ? entidad?.nombre_eb : "Estado de las evaluaciones"}
				</Text>
			</VStack>
			<HStack position="absolute" top="0" right="0" justifyContent="flex-end" width="100%">
				{codEB && (
					<IconButton aria-label="back" variant="icon-only" onClick={() => navigate("/")}>
						<Icon as={FiArrowLeft} color="gray.500" fontSize="1.5rem" strokeWidth="1" />
					</IconButton>
				)}
			</HStack>
			<Divider mt="1rem" mb="1.5rem" />
		</Box>
	);
};
