import React from "react";

import { Flex, Icon, Text } from "@chakra-ui/react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

import { EntidadOutput } from "config/types/entidad";
import { actions as EntidadesActions } from "store/entidades/reducer";
import { useAppDispatch } from "store/store";

export type OrderByFilter = { column: keyof EntidadOutput; direction: "asc" | "desc" };

interface IProps<EntidadOutput> {
	displayText: string;
	column: keyof EntidadOutput;
	orderBy: OrderByFilter;
}

export const ColumnSorter = ({ displayText, orderBy, column }: React.PropsWithChildren<IProps<EntidadOutput>>) => {
	const dispatch = useAppDispatch();

	const handleColumnSort = (orderBy: OrderByFilter, column: keyof EntidadOutput) => {
		if (orderBy.column === column) {
			dispatch(
				EntidadesActions.setOrderBy({
					column,
					direction: orderBy.direction === "asc" ? "desc" : "asc",
				}),
			);
		} else {
			dispatch(EntidadesActions.setOrderBy({ column, direction: "asc" }));
		}
	};

	return (
		<Flex alignItems="center">
			<Text color="gray.600" textStyle="body3" cursor="pointer" onClick={() => handleColumnSort(orderBy, column)}>
				{displayText}
			</Text>
			{orderBy.column === column && (
				<Icon
					as={orderBy.direction === "asc" ? FiChevronUp : FiChevronDown}
					color="red.500"
					fontSize="caption"
					marginLeft="0.5rem"
				/>
			)}
		</Flex>
	);
};
