import React from "react";

import { Box, Button, Divider, Flex, Text, Textarea } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { CardContainer } from "common/components/CardContainer";
import { ComentarioInput } from "config/types/comentario";
import { postComentarioThunk } from "store/evaluaciones/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { Message } from "./Message";

export const Conversation: React.FC = () => {
	const { codEB } = useParams();
	const [newComment, setNewComment] = React.useState<ComentarioInput>();
	const dispatch = useAppDispatch();
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (!e.target.value) return setNewComment(undefined);
		const inputValue: string = e.target.value;
		setNewComment({ comentario: inputValue });
	};

	const handleSubmit = () => {
		if (!codEB || !newComment) return;
		dispatch(postComentarioThunk({ codEB, comentario: newComment }));
		setNewComment(undefined);
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		}
		const textarea = document.querySelector("textarea");
		if (textarea) {
			textarea.value = "";
		}
	};

	React.useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		}
	}, [evaluacionSeleccionada?.comentarios]);

	return (
		<Flex direction="column" w="100%" h="95%">
			<CardContainer pb="0">
				<Text textStyle="body3" mb="1rem" color="gray.600">
					Comentarios
				</Text>
			</CardContainer>
			<Box maxH="60vh" overflowY="auto" pb="0" backgroundColor="white" p="0 2rem" ref={scrollRef}>
				{evaluacionSeleccionada &&
					evaluacionSeleccionada.comentarios &&
					evaluacionSeleccionada?.comentarios?.length > 0 && (
						<>
							{evaluacionSeleccionada?.comentarios
								?.slice()
								.filter((comentario) => comentario.comentario)
								.filter((comentario) => comentario.accion !== "Adelantada")
								.sort((a, b) => a.id - b.id)
								.map((comentario) => (
									<Message key={comentario.id} comentario={comentario} />
								))}
						</>
					)}
			</Box>
			<CardContainer pt="0">
				{evaluacionSeleccionada?.estado_evaluacion !== "Validada" && (
					<React.Fragment>
						<Divider m="1rem 0" />
						<Textarea onChange={handleInputChange} size="sm" resize="none" />
						<Flex justifyContent="flex-end">
							<Button
								color="white"
								mt="1rem"
								w="100%"
								h="1.75rem"
								variant="solid"
								backgroundColor="gray.400"
								_hover={{ bg: "gray.500" }}
								onClick={handleSubmit}
							>
								<Text textStyle="body3" color="white">
									Publicar
								</Text>
							</Button>
						</Flex>
					</React.Fragment>
				)}
			</CardContainer>
		</Flex>
	);
};
