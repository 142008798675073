import React from "react";
import "./Styles.css";

import { Box } from "@chakra-ui/react";

import { useAppDispatch } from "store/store";
import { actions } from "store/usuario/reducer";

import {
	Seccion01,
	Seccion02,
	Seccion03,
	Seccion04,
	Seccion05,
	Seccion06,
	Seccion07,
	Seccion08,
	Seccion09,
	Seccion10,
} from "../secciones";

const sections = [
	Seccion01,
	Seccion02,
	Seccion03,
	Seccion04,
	Seccion05,
	Seccion06,
	Seccion07,
	Seccion08,
	Seccion09,
	Seccion10,
];

export const Print: React.FC = () => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		window.print();
		dispatch(actions.setPrintView(false));
	}, []);

	return (
		<div className="print">
			<React.Fragment>
				{sections.map((Section, index) => (
					<Box key={index}>
						<Section />
					</Box>
				))}
			</React.Fragment>
		</div>
	);
};
