import mapboxSdk from "@mapbox/mapbox-sdk/services/geocoding";

import { Marker } from "common/components/Mapa/Mapa";
import { envConfig } from "config/env";

export interface DataMapa {
	nombre_eb: string;
	direccion_actividad: string;
	municipio_actividad: string;
	codigo_postal_actividad?: string;
	latitud?: number | null;
	longitud?: number | null;
}

export const getEntidadCoordinates = (dataMapa: DataMapa, setMarker: (marker: Marker) => void) => {
	if (dataMapa.latitud && dataMapa.longitud) {
		setMarker({
			id: dataMapa.nombre_eb,
			name: dataMapa.nombre_eb,
			geometry: {
				type: "Point",
				coordinates: [dataMapa.longitud, dataMapa.latitud],
			},
		});
		return;
	} else {
		const geocodingClient = mapboxSdk({
			accessToken: envConfig.MAPBOX_TOKEN,
		});

		const addressQuery = `${dataMapa.direccion_actividad}, ${dataMapa.municipio_actividad}, ${
			dataMapa.codigo_postal_actividad || ""
		}`;

		return geocodingClient
			.forwardGeocode({
				query: dataMapa.direccion_actividad ? addressQuery : dataMapa.nombre_eb,
				countries: ["es"],
				limit: 1,
			})
			.send()
			.then((response) => {
				if (!response || !response.body || !response.body.features || !response.body.features.length) {
					console.error("Invalid response:");
					console.error(response);
					return;
				}
				setMarker({
					id: dataMapa.nombre_eb,
					name: dataMapa.nombre_eb,
					geometry: {
						type: "Point",
						coordinates: [
							response.body.features[0].geometry.coordinates[0],
							response.body.features[0].geometry.coordinates[1],
						],
					},
				});
				return;
			});
	}
};
