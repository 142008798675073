import axios, { InternalAxiosRequestConfig } from "axios";

import { isTokenExpired } from "helpers/checkToken";
import { refreshToken } from "helpers/refreshToken";

import { devConfig } from "./env/dev";
import { localConfig } from "./env/local";
import { prodConfig } from "./env/prod";

export const AxiosInterceptor: React.FC = () => {
	const matcher = (r: InternalAxiosRequestConfig) =>
		!!r.url?.startsWith(localConfig.API_URL) ||
		!!r.url?.startsWith(devConfig.API_URL) ||
		!!r.url?.startsWith(prodConfig.API_URL);

	axios.interceptors.request.use(async (request) => {
		if (matcher(request) && !request.url?.includes("auth/google")) {
			const token = localStorage.getItem("bam_token");
			const bam_refresh_token = localStorage.getItem("bam_refresh_token");

			if (!token || !bam_refresh_token) {
				window.location.href = "/login";
			} else {
				if (isTokenExpired(token)) {
					const res = await refreshToken(bam_refresh_token);
					if (res.status === 200) {
						localStorage.setItem("bam_token", res.data.id_token);
						localStorage.setItem("bam_refresh_token", res.data.refresh_token);
					} else {
						window.location.href = "/login";
					}
				}
			}
			const newToken = localStorage.getItem("bam_token");
			if (request.headers !== undefined) {
				request.headers["Authorization"] = `Bearer ${newToken}`;
			}
		}
		return request;
	});
	return <></>;
};
