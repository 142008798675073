// ENTIDAD && EVALUACION TYPES //

// MODULE CONFIGURATION
export type OrderByFilter<T> = { column: keyof T; direction: "asc" | "desc" };

export type EnabledFormFieldType =
	| "text"
	| "longtext"
	| "select"
	| "select_multiple"
	| "checkbox"
	| "integer"
	| "float"
	| "timestamp"
	| "date"
	| "attachments";

export type FormValidations = "required" | "email";

export type ModuleConfigFormat = {
	type: "currency";
	symbol: string;
};

// PAGINATION TYPES //
export type Pagination = {
	total: number;
	lastPage: number;
	perPage: number;
	currentPage: number;
	from: number;
	to: number;
};

export type ApiPaginatedResponse<T> = {
	data: T;
	pagination: Pagination;
};

// THUNK TYPES //
export enum THUNK_STATE {
	IDLE = "IDLE",
	FULFILLED = "FULFILLED",
	PENDING = "PENDING",
	REJECTED = "REJECTED",
}

export type StatePropWithThunkState<T> = {
	thunkState: THUNK_STATE;
	data: T;
};

export type SelectOption = {
	label: string;
	value: string | boolean;
};

export type CheckboxOption = {
	label: string;
	value: string;
	checked: boolean;
};

export interface IAttachedFile {
	id?: string;
	name: string;
	file?: File;
	type?: string;
	filepath?: string;
}
