import React from "react";

import { Text, HStack, SimpleGrid, Grid, Box, Flex, GridItem, Icon, useDisclosure } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FiFile, FiPrinter } from "react-icons/fi";
import { useParams, useSearchParams } from "react-router-dom";

import { EvaluacionOutput } from "config/types/evaluacion";
import { getGoogleFolderIdThunk } from "store/evaluaciones/thunks";
import { useAppDispatch, useAppSelector } from "store/store";
import { actions } from "store/usuario/reducer";

import { GoogleDriveDocuments } from "../GoogleDriveDocuments/GoogleDriveDocuments";

interface HojadeVisitaProps {
	evaluacion: EvaluacionOutput;
}

export const HojaDeVisita: React.FC<HojadeVisitaProps> = ({ evaluacion }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { codEB } = useParams();
	const dispatch = useAppDispatch();
	const folderId = useAppSelector((state) => state.evaluaciones.googleDrive.data.documentsFolder?.id);

	const [searchParams] = useSearchParams();
	const resolucion = searchParams.get("resolucion");

	const evaluacionToUse = React.useMemo(() => {
		if (!evaluacion) return null;
		return [
			{
				label: "Programación",
				value: evaluacion.fecha_adelantada || evaluacion.fecha_visita_programada || "",
			},
			{
				label: "Visitador",
				value: evaluacion.nombre_visitador || "",
			},
			{
				label: "Visita",
				value: evaluacion.fecha_visita || evaluacion.fecha_en_proceso || "",
			},
			{
				label: "Revisor",
				value: evaluacion.nombre_supervisor || "",
			},
			{
				label: "Validación",
				value: evaluacion.fecha_validada || "",
			},
		];
	}, [evaluacion]);

	const leyendaInfo = [
		{
			label: "Datos precargados",
			color: "gray.600",
		},
		{
			label: "Datos actualizados",
			color: "green.400",
		},
		{
			label: "Sección completada",
			color: "blue.400",
		},
	];

	React.useEffect(() => {
		if (codEB) {
			dispatch(getGoogleFolderIdThunk({ idEntidad: codEB }));
		}
	}, [codEB]);

	return (
		<Grid templateColumns="repeat(5, 1fr)" gap={5}>
			<GridItem colSpan={3}>
				<SimpleGrid columns={2}>
					{evaluacionToUse &&
						evaluacionToUse.map((item, index) => (
							<HStack key={index}>
								<Text textStyle="body3" color="gray.600" m=".1rem 0">
									{item.label}:
								</Text>
								<Text textStyle="body3" color="black" fontWeight={item.label === "Código entidad" ? "bold" : "normal"}>
									{item.value && item.label === "Programación"
										? DateTime.fromISO(String(item.value)).setLocale("es").toFormat("LLL. yyyy").toString()
										: item.value && (item.label === "Visita" || item.label === "Validación")
										? DateTime.fromISO(String(item.value)).toFormat("dd.MM.yyyy").toString()
										: String(item.value)}
								</Text>
							</HStack>
						))}
				</SimpleGrid>
			</GridItem>
			{!resolucion && (
				<React.Fragment>
					<GridItem colSpan={1}>
						<SimpleGrid columns={1}>
							{leyendaInfo.map((item, index) => (
								<HStack key={index} w="max-content" mt=".15rem">
									<Box w="10px" h="10px" bg={item.color} borderRadius="50%" />
									<Text textStyle="body3" color="gray.600">
										{item.label}
									</Text>
								</HStack>
							))}
						</SimpleGrid>
					</GridItem>
					<GridItem colSpan={1}>
						<Flex align="flex-start" justify="flex-start" pl="1rem">
							{folderId && (
								<Icon
									as={FiFile}
									color="gray.500"
									fontSize="1.25rem"
									strokeWidth="1"
									mr="1rem"
									_hover={{ cursor: "pointer" }}
									onClick={onOpen}
								/>
							)}
							<Icon
								as={FiPrinter}
								color="gray.500"
								fontSize="1.25rem"
								strokeWidth="1"
								_hover={{ cursor: "pointer" }}
								display={{ sm: "none", md: "block" }}
								onClick={() => dispatch(actions.setPrintView(true))}
							/>
						</Flex>
					</GridItem>
				</React.Fragment>
			)}
			{codEB && <GoogleDriveDocuments isOpen={isOpen} onClose={onClose} />}
		</Grid>
	);
};
