import React from "react";

import {
	Box,
	Text,
	HStack,
	Icon,
	IconButton,
	Button,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
} from "@chakra-ui/react";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import { actions as portfolioActions } from "store/entidades/reducer";
import { useAppDispatch, useAppSelector } from "store/store";
import { Conversation } from "views/entidad/components/Conversation/Conversation";
import { DatosEntidad } from "views/entidad/components/DatosEntidad/DatosEntidad";
import { FiltrosSelect } from "views/portfolio/components/FiltrosSelect/FiltrosSelect";

import StatusIndicator from "../StatusIndicator";

export const TabletTableHeader: React.FC = () => {
	const { codEB } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isOpen: isOpenComentarios, onOpen: onOpenComentarios, onClose: onCloseComentarios } = useDisclosure();
	const { isOpen: isOpenDatosEntidad, onOpen: onOpenDatosEntidad, onClose: onCloseDatosEntidad } = useDisclosure();
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const entidad = useAppSelector((state) => state.entidades.entidades?.data[0]);

	const [isFilterActive, setIsFilterActive] = React.useState(false);

	const isPortfolioView = !codEB;
	const isEntidadView = codEB;

	return (
		<Box position="relative" width="100%" display={{ sm: "block", md: "none" }} marginBottom="1rem">
			{isPortfolioView && (
				<Box>
					<HStack justifyContent="flex-start" width="100%">
						<Text textStyle="heading6" width="100%" color="BAMblue" noOfLines={2} fontFamily={"Anton"} mb="1rem">
							Lista de mis Entidades Beneficiarias
						</Text>
						<Flex align="center" justify="flex-end" width="100%">
							{isFilterActive && (
								<Button
									variant="solid"
									backgroundColor="gray.400"
									borderRadius="0.375rem"
									color="white"
									_hover={{ bg: "gray.200" }}
									_active={{ bg: "gray.50" }}
									mr="1.5rem"
									onClick={() => dispatch(portfolioActions.resetFilterSelection())}
									p="0 1.5rem"
								>
									Limpiar Filtros
								</Button>
							)}
							<Button
								variant="solid"
								backgroundColor="white"
								borderRadius="0.375rem"
								color="BAMBlue"
								_hover={{ bg: "gray.200" }}
								_active={{ bg: "gray.50" }}
								onClick={() => setIsFilterActive(!isFilterActive)}
								p="0 1.5rem"
							>
								{isFilterActive ? "Cerrar Filtros" : "Filtros"}
							</Button>
						</Flex>
					</HStack>
					{isFilterActive && <FiltrosSelect />}
				</Box>
			)}
			{isEntidadView && (
				<HStack justifyContent="flex-start" width="100%">
					<IconButton
						aria-label="back"
						variant="icon-only"
						onClick={() => navigate("/")}
						backgroundColor="white"
						mr=".75rem"
					>
						<Icon as={FiArrowLeft} color="gray.500" fontSize="1.5rem" strokeWidth="1" />
					</IconButton>
					{entidad && (
						<React.Fragment>
							<Text textStyle="heading6" width="100%" color="BAMblue" noOfLines={2} fontFamily={"Anton"} mt="2rem">
								{entidad.nombre_eb}
							</Text>
							<Flex align="center" justify="flex-end" width="100%">
								<Button
									variant="solid"
									backgroundColor="white"
									borderRadius="0.375rem"
									color="BAMBlue"
									_hover={{ bg: "gray.200" }}
									_active={{ bg: "gray.50" }}
									onClick={onOpenDatosEntidad}
									mr="1.5rem"
								>
									Datos EB
								</Button>
								<Button
									variant="solid"
									backgroundColor="white"
									borderRadius="0.375rem"
									color="BAMBlue"
									_hover={{ bg: "gray.200" }}
									_active={{ bg: "gray.50" }}
									onClick={onOpenComentarios}
									mr="1.5rem"
								>
									Comentarios
								</Button>
								<Flex width="100%" h="2rem" alignItems="center" justifyContent="space-between" maxW="25%">
									{evaluacionSeleccionada?.estado_evaluacion && (
										<StatusIndicator
											estado={evaluacionSeleccionada?.estado_evaluacion}
											style={{ textStyle: "body2" }}
										/>
									)}
								</Flex>
							</Flex>
						</React.Fragment>
					)}
				</HStack>
			)}
			<Drawer placement={"left"} onClose={onCloseDatosEntidad} isOpen={isOpenDatosEntidad} size="md">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerBody>
						{entidad && evaluacionSeleccionada && (
							<React.Fragment>
								<Icon
									as={FiX}
									color="gray.500"
									fontSize="1.5rem"
									strokeWidth="1"
									onClick={onCloseDatosEntidad}
									position="absolute"
									top="1.5rem"
									right="1.5rem"
									_hover={{ cursor: "pointer" }}
								/>
								<Text textStyle="body3" width="100%" color="BAMblue" noOfLines={2} fontFamily={"Anton"} mt="2rem">
									{entidad.nombre_eb}
								</Text>
								<DatosEntidad entidad={entidad} evaluacion={evaluacionSeleccionada} />
							</React.Fragment>
						)}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<Drawer placement={"right"} onClose={onCloseComentarios} isOpen={isOpenComentarios} size="md">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerBody>
						<Icon
							as={FiX}
							color="gray.500"
							fontSize="1.5rem"
							strokeWidth="1"
							onClick={onCloseComentarios}
							position="absolute"
							top="1.5rem"
							right="1.5rem"
							_hover={{ cursor: "pointer" }}
						/>
						<Conversation />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
};
