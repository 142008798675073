import React from "react";

import { Flex, Img, Text, Box, FlexProps } from "@chakra-ui/react";

import arupWhiteLogo from "assets/images/arup_white.svg";
import { envConfig } from "config/env/index";

type BottomBarProps = FlexProps;

export const BottomBar: React.FC<BottomBarProps> = (props) => {
	const navigateToArup = () => {
		window.open("https://dhub.arup.com/", "_blank");
	};

	return (
		<Flex width="100%" height="2rem" backgroundColor="black" zIndex="3" {...props}>
			<Flex justifyContent="flex-start" alignItems="center" width="100%">
				<Text color="white" textStyle="body4">
					v.{envConfig.RELEASE}
				</Text>
			</Flex>
			<Flex justifyContent="flex-end" alignItems="center" width="100%">
				<Text color="white" textStyle="body3">
					Plataforma desarrollada por:
				</Text>
				<Box onClick={navigateToArup} cursor="pointer">
					<Img src={arupWhiteLogo} alt="Arup White Logo" />
				</Box>
			</Flex>
		</Flex>
	);
};
