import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import { PaginationComponent } from "common/components/Pagination/Pagination";
import { getEntidadesThunk } from "store/entidades/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { RowData } from "./Row";

export const TableRow: React.FC = () => {
	const { data: entidades, pagination } = useAppSelector((state) => state.entidades.entidades);
	const { thunkState, filterSelection, orderBy } = useAppSelector((state) => state.entidades);

	const dispatch = useAppDispatch();

	const [page, setPage] = React.useState<number>(1);

	const ref = React.useRef<HTMLDivElement>(null);

	const autoScrollToTop = React.useCallback(() => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	}, [ref]);

	React.useEffect(() => {
		dispatch(
			getEntidadesThunk({
				filterSelection: { ...filterSelection, estadoEvaluacion: undefined },
				page: page,
				order: `${orderBy.column}.${orderBy.direction}`,
			}),
		).then(() => {
			autoScrollToTop();
		});
	}, [page, orderBy]);

	return (
		<Box ref={ref}>
			{entidades &&
				entidades.map((entidad, i) => (
					<React.Fragment key={entidad.cod_eb}>
						<RowData entidad={entidad} index={i} />
					</React.Fragment>
				))}
			<Flex justifyContent="center">
				<PaginationComponent pagination={pagination} thunkState={thunkState} page={page} setPage={setPage} />
			</Flex>
		</Box>
	);
};
