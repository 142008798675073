export const dataCC = {
	canalseco_1er_turno: {
		"Semana 1": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	canalseco_2do_turno: {
		"Semana 2": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	canalseco_3er_turno: {
		"Semana 3": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	canalseco_4to_turno: {
		"Semana 4": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
};

export const dataFYH = {
	fyh_1er_turno: {
		"Semana 1": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	fyh_2do_turno: {
		"Semana 2": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	fyh_3er_turno: {
		"Semana 3": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
	fyh_4to_turno: {
		"Semana 4": {
			lunes: 1,
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
		},
	},
};

export const dataCong = {
	cong_1er_turno: {
		"Semana 1": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	cong_2do_turno: {
		"Semana 2": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	cong_3er_turno: {
		"Semana 3": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	cong_4to_turno: {
		"Semana 4": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
};

export const dataMM = {
	mm_1er_turno: {
		"Semana 1": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	mm_2do_turno: {
		"Semana 2": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	mm_3er_turno: {
		"Semana 3": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	mm_4to_turno: {
		"Semana 4": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
	mm_5to_turno: {
		"Semana 4": {
			martes: 2,
			miércoles: 3,
			jueves: 4,
			viernes: 5,
			sábado: 6,
		},
	},
};

export const almacenesMapping = {
	"Canal Seco": ["canalseco_1er_turno", "canalseco_2do_turno", "canalseco_3er_turno", "canalseco_4to_turno"],
	"Frutas y Hortalizas": ["fyh_1er_turno", "fyh_2do_turno", "fyh_3er_turno", "fyh_4to_turno"],
	Congelados: ["cong_1er_turno", "cong_2do_turno", "cong_3er_turno", "cong_4to_turno"],
	Mercamadrid: ["mm_1er_turno", "mm_2do_turno", "mm_3er_turno", "mm_4to_turno", "mm_5to_turno"],
};

export const almacenKeyMapping = {
	"Canal Seco": "canalseco_almacen",
	"Frutas y Hortalizas": "fyh_almacen",
	Congelados: "cong_almacen",
	Mercamadrid: "mm_almacen",
};
