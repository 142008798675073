import React from "react";

import { Flex, Icon } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

import { CardContainer } from "common/components/CardContainer";
import { SidebarHeader } from "common/components/SidebarHeader";
import StatusIndicator from "common/components/StatusIndicator";
import { useAppSelector } from "store/store";

import { DatosEntidad } from "../DatosEntidad/DatosEntidad";
import { SelectorEvaluacion } from "../SelectorEvaluacion/SelectorEvaluacion";
type EstadoEvaluacion =
	| "En proceso"
	| "Completada"
	| "Supervisada"
	| "Adelantada"
	| "Programada"
	| "Validada"
	| "Rechazada";

export const Sidebar: React.FC = () => {
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const entidad = useAppSelector((state) => state.entidades.entidades?.data[0]);

	const [estadoEvaluacion, setEstadoEvaluacion] = React.useState<EstadoEvaluacion>("En proceso");

	React.useEffect(() => {
		if (evaluacionSeleccionada) {
			setEstadoEvaluacion(evaluacionSeleccionada.estado_evaluacion);
		}
	}, [evaluacionSeleccionada]);

	return (
		<CardContainer minW="200px">
			{entidad && evaluacionSeleccionada && (
				<React.Fragment>
					<SidebarHeader entidad={entidad} />
					<SelectorEvaluacion />
					<Flex width="100%" h="2rem" alignItems="center" justifyContent="space-between" w="100%">
						{evaluacionSeleccionada && <StatusIndicator estado={estadoEvaluacion} style={{ textStyle: "body1" }} />}
						{evaluacionSeleccionada.existen_anomalias > 0 && (
							<Icon as={FiAlertTriangle} fill="pink.500" color="white" fontSize="1.5rem" strokeWidth="1" ml="1.5rem" />
						)}
					</Flex>
					<DatosEntidad entidad={entidad} evaluacion={evaluacionSeleccionada} />
				</React.Fragment>
			)}
		</CardContainer>
	);
};
