import React from "react";

import { Select } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { getEvaluacionAnteriorThunk } from "store/evaluaciones/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

export const SelectorEvaluacion: React.FC = () => {
	const { codEB, numeroEvaluacion } = useParams();
	const evaluaciones = useAppSelector((state) => state.evaluaciones.evaluaciones.data);

	const navigate = useNavigate();
	const numeroEvaluacionInt = Number(numeroEvaluacion);
	const evaluacionSelected = evaluaciones?.find((evaluacion) => numeroEvaluacionInt === evaluacion.numero_evaluacion);

	const dispatch = useAppDispatch();

	const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (!e.target.value) return;
		if (!isNaN(Number(numeroEvaluacion)) && Number(numeroEvaluacion) > 1 && codEB && numeroEvaluacion)
			dispatch(getEvaluacionAnteriorThunk({ codEB, numeroEvaluacion: Number(e.target.value) }));
		navigate(`/${codEB}/${e.target.value}`);
	};

	return (
		<React.Fragment>
			{evaluaciones && evaluaciones.length > 1 && evaluacionSelected && (
				<Select
					onChange={handleOnChange}
					placeholder={new Date(evaluacionSelected.fecha).toLocaleString("es-ES", {
						month: "long",
						year: "numeric",
					})}
					m="1rem 0"
					fontWeight="bold"
				>
					{evaluaciones
						?.filter((evaluacion) => numeroEvaluacionInt !== evaluacion.numero_evaluacion)
						.sort((a, b) => b.numero_evaluacion - a.numero_evaluacion)
						.map((evaluacion) => (
							<option value={evaluacion.numero_evaluacion} key={evaluacion.numero_evaluacion}>
								{new Date(evaluacion.fecha).toLocaleString("es-ES", { month: "long", year: "numeric" })}
							</option>
						))}
				</Select>
			)}
		</React.Fragment>
	);
};
