import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { THUNK_STATE } from "config/types";

import { ISlice } from "./reducer";
import { getPermisosThunk } from "./thunks";

export const getPermisosExtraReducer = (builder: ActionReducerMapBuilder<ISlice>) => [
	builder.addCase(getPermisosThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getPermisosThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getPermisosThunk.fulfilled, (state, action) => ({
		...state,
		permissions: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];
