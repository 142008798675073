import React from "react";

import { Checkbox, HStack } from "@chakra-ui/react";

interface ICheckboxGroupProps {
	name: string;
	defaultValue: number | null;
	onChange: (name: string, value: number | null) => void;
	changed: {
		"data-ischanged": "true" | "false";
	};
	isDisabled?: boolean;
	disableNA?: boolean;
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
	name,
	defaultValue,
	onChange,
	changed,
	isDisabled,
	disableNA = false,
}) => {
	const initialValue = defaultValue === null ? 2 : defaultValue;
	const [checked, setChecked] = React.useState<number>(initialValue);

	const handleChange = (value: number) => {
		if (checked === value) {
			if (disableNA) {
				setChecked(2);
				onChange(name, null);
			} else {
				setChecked(value);
				onChange(name, null);
			}
		} else {
			setChecked(value);
			onChange(name, value === 2 ? null : value);
		}
	};

	React.useEffect(() => {
		const newValue = defaultValue === null ? 2 : defaultValue;
		setChecked(newValue);
	}, [defaultValue, disableNA]);

	return (
		<HStack justifyContent="space-between" width="100%" spacing="1rem">
			<Checkbox
				size="lg"
				iconSize="2rem"
				value={1}
				isChecked={checked === 1}
				onChange={() => handleChange(1)}
				isDisabled={isDisabled}
				variant={changed["data-ischanged"] === "true" ? "greenCheckbox" : "BAMcheckbox"}
			>
				Sí
			</Checkbox>
			<Checkbox
				size="lg"
				iconSize="2rem"
				value={0}
				isChecked={checked === 0}
				onChange={() => handleChange(0)}
				isDisabled={isDisabled}
				variant={changed["data-ischanged"] === "true" ? "greenCheckbox" : "BAMcheckbox"}
			>
				No
			</Checkbox>
			{!disableNA && (
				<Checkbox
					size="lg"
					iconSize="2rem"
					value={2}
					isChecked={checked === 2 && !disableNA}
					onChange={() => handleChange(2)}
					isDisabled={isDisabled || disableNA}
					variant={changed["data-ischanged"] === "true" ? "greenCheckbox" : "BAMcheckbox"}
				>
					N/A
				</Checkbox>
			)}
		</HStack>
	);
};
