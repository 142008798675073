import React from "react";

import { Grid, GridItem, IconButton } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { FiDownload } from "react-icons/fi";
import * as XLSX from "xlsx";

import { getEntidades } from "api/entidad";
import { ColumnSorter } from "common/components/ColumnSorter/ColumnSorter";
import { ApiPaginatedResponse } from "config/types";
import { EntidadOutput } from "config/types/entidad";
import { useAppSelector } from "store/store";

import { headerData } from "./headerData";

export const TableHeader: React.FC = () => {
	const { orderBy } = useAppSelector((state) => state.entidades);
	const { pagination: pagination } = useAppSelector((state) => state.entidades.entidades);
	const filterSelection = useAppSelector((state) => state.entidades.filterSelection);

	const formatFechaProgramada = (fecha) => {
		const date = new Date(fecha);
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${month}-${year}`;
	};

	const fetchAllEntidades = async () => {
		const allEntidades: EntidadOutput[] = [];
		const paginationTotalPages = pagination.lastPage;
		const promises: Promise<AxiosResponse<ApiPaginatedResponse<EntidadOutput[]>>>[] = [];

		for (let i = 1; i <= paginationTotalPages; i++) {
			const promise = getEntidades({
				...(filterSelection && filterSelection.busqueda && { busqueda: filterSelection.busqueda }),
				...(filterSelection && filterSelection.grupoVisitador && { grupoVisitador: filterSelection.grupoVisitador }),
				...(filterSelection && filterSelection.visitador && { visitador: filterSelection.visitador }),
				...(filterSelection && filterSelection.tipoDeActividad && { tipoDeActividad: filterSelection.tipoDeActividad }),
				...(filterSelection &&
					filterSelection.existenAnomalias && { existenAnomalias: filterSelection.existenAnomalias }),
				...(filterSelection &&
					filterSelection.estadoEvaluacion && { estadoEvaluacion: filterSelection.estadoEvaluacion.toString() }),
				...(filterSelection && filterSelection.fechaInicio && { fechaInicio: filterSelection.fechaInicio }),
				...(filterSelection && filterSelection.fechaFinal && { fechaFinal: filterSelection.fechaFinal }),
				...(filterSelection && filterSelection.status && { status: filterSelection.status.toString() }),
				...(filterSelection && filterSelection.poblacion && { poblacion: filterSelection.poblacion.toString() }),
				page: i,
			});
			promises.push(promise);
		}

		await Promise.all(promises)
			.then((responses) => {
				responses.forEach((response) => {
					allEntidades.push(...response.data.data);
				});
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});

		return allEntidades;
	};

	const downloadData = async () => {
		const allEntidades = await fetchAllEntidades();

		const wsData = allEntidades.map((entidad) => {
			return [
				entidad.cod_eb,
				entidad.nombre_eb,
				entidad.tipo_de_actividad,
				entidad.relevancia,
				entidad.grupo_visitador,
				formatFechaProgramada(entidad.fecha_programada),
				entidad.estado_evaluacion,
				entidad.existen_anomalias,
				entidad.nombre_visitador,
				entidad.email_visitador,
			];
		});

		const header = [
			"Codigo",
			"Entidad Beneficiaria",
			"Actividad",
			"Relevancia",
			"Grupo",
			"Fecha Programada",
			"Estado Evaluacion",
			"Anomalías",
			"Visitador",
			"Email Visitador",
		];

		const data = [header, ...wsData];
		const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Entidades");

		// Create blob and download
		XLSX.writeFile(wb, "listado_entidades.xls", { bookType: "xls", bookSST: true });

		const linkElement = document.getElementById("download-entidades");
		if (linkElement) {
			linkElement.remove();
		}
	};

	return (
		<Grid templateColumns="repeat(11, 1fr)" gap={5} mb="1rem" p="0 1rem" alignItems="center">
			{headerData.map((item, i) => (
				<GridItem colSpan={item.span} key={i}>
					<ColumnSorter displayText={item.label} orderBy={orderBy} column={item.column} />
				</GridItem>
			))}
			<IconButton
				icon={<FiDownload />}
				color="gray.600"
				fontSize="1.15rem"
				justifySelf="flex-end"
				aria-label="Exportar"
				variant="solid"
				_hover={{ bg: "gray.200", backgroundColor: "gray.300" }}
				_active={{ bg: "gray.50" }}
				backgroundColor="gray.300"
				onClick={downloadData}
			/>
		</Grid>
	);
};
