import { createSlice } from "@reduxjs/toolkit";

import { StatePropWithThunkState, THUNK_STATE } from "config/types";
import { DocumentoRecord } from "config/types/documento";
import { EvaluacionItemListOutput, EvaluacionOutput } from "config/types/evaluacion";
import { GoogleDriveInfo } from "config/types/google";

import {
	actualizarCampoEvaluacionExtraReducer,
	deleteDocumentoExtraReducer,
	getDocumentosExtraReducer,
	getEvaluacionAnteriorExtraReducer,
	getEvaluacionesExtraReducer,
	getEvaluacionExtraReducer,
	getGoogleDriveFileListExtraReducer,
	getGoogleFolderIdExtraReducer,
	patchCambiarEstadoEvaluacionExtraReducer,
	postComentarioExtraReducer,
	postDocumentosExtraReducer,
	postGoogleTokenExtraReducer,
	putAdelantarEvaluacionExtraReducer,
} from "./extraReducers";
import { SLICE_NAMES } from "../constant";

export interface EvaluacionesSlice {
	evaluaciones: StatePropWithThunkState<EvaluacionItemListOutput[] | null>;
	evaluacionSeleccionada: StatePropWithThunkState<EvaluacionOutput | null>;
	evaluacionSeleccionadaAnterior: StatePropWithThunkState<EvaluacionOutput | null>;
	documentosDeEvaluacion: StatePropWithThunkState<DocumentoRecord[]>;
	googleDrive: StatePropWithThunkState<GoogleDriveInfo>;
}

const sliceInitialState: EvaluacionesSlice = {
	evaluaciones: {
		data: null,
		thunkState: THUNK_STATE.IDLE,
	},
	evaluacionSeleccionada: {
		data: null,
		thunkState: THUNK_STATE.IDLE,
	},
	evaluacionSeleccionadaAnterior: {
		data: null,
		thunkState: THUNK_STATE.IDLE,
	},
	documentosDeEvaluacion: {
		data: [],
		thunkState: THUNK_STATE.IDLE,
	},
	googleDrive: {
		data: {
			documentsFolder: undefined,
			files: [],
			authToken: null,
		},
		thunkState: THUNK_STATE.IDLE,
	},
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.EVALUACIONES}`,
	initialState: sliceInitialState,
	reducers: {
		resetEvaluacionesSlice: (state) => ({
			...sliceInitialState,
			googleDrive: {
				...sliceInitialState.googleDrive,
				data: {
					authToken: state.googleDrive.data.authToken,
				},
			},
		}),
	},

	extraReducers(builder) {
		getEvaluacionesExtraReducer(builder);
		getEvaluacionExtraReducer(builder);
		getEvaluacionAnteriorExtraReducer(builder);
		postComentarioExtraReducer(builder);
		putAdelantarEvaluacionExtraReducer(builder);
		patchCambiarEstadoEvaluacionExtraReducer(builder);
		getDocumentosExtraReducer(builder);
		postDocumentosExtraReducer(builder);
		deleteDocumentoExtraReducer(builder);
		actualizarCampoEvaluacionExtraReducer(builder);
		getGoogleFolderIdExtraReducer(builder);
		getGoogleDriveFileListExtraReducer(builder);
		postGoogleTokenExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
