import { devConfig } from "./dev";
import { localConfig } from "./local";
import { prodConfig } from "./prod";

export type EnvConfigurationType = {
	ENV_NAME: string;
	AWS_REGION: string;
	GOOGLE_CLIENT_ID: string;
	API_URL: string;
	PROJECT_REF: string;
	MAPBOX_TOKEN: string;
	RELEASE: string;
};

const runConfig = () => {
	if (process.env.NODE_ENV === "production") {
		if (window.location.host === "bamvisitas.dhub.arup.com") return prodConfig;
		else if (window.location.host === "bam.dhub.arup.com") return devConfig;
		else return devConfig;
	} else {
		return localConfig;
	}
};

export const envConfig = runConfig();
