import React from "react";

import { Box, InputGroup, InputLeftElement, Input, InputRightElement, Button } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

import { useAppDispatch, useAppSelector } from "store/store";

import { actions as portfolioActions } from "../../../store/entidades/reducer";

export const SearchInput: React.FC = () => {
	const busqueda = useAppSelector((state) => state.entidades.filterSelection.busqueda);
	const [search, setSearch] = React.useState<string>("");
	const filterSelection = useAppSelector((state) => state.entidades.filterSelection);

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		setSearch(busqueda || "");
	}, [busqueda]);

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (search && e.key === "Enter") {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, busqueda: search }));
		} else if (!search && e.key === "Enter") {
			dispatch(portfolioActions.setFilterSelection({ ...filterSelection, busqueda: undefined }));
		}
	};

	const handleSearchButton = () => {
		search
			? dispatch(portfolioActions.setFilterSelection({ ...filterSelection, busqueda: search }))
			: dispatch(portfolioActions.setFilterSelection({ ...filterSelection, busqueda: undefined }));
	};

	return (
		<Box w="100%" mt="1.5rem">
			<InputGroup>
				<InputLeftElement pointerEvents="none" color="gray.300" p=".8rem">
					<FiSearch />
				</InputLeftElement>
				<Input
					pl="2rem"
					value={search || ""}
					onChange={(e) => setSearch(e.target.value)}
					onKeyDown={handleSearch}
					placeholder="Buscar entidad"
					_placeholder={{ color: "gray.400" }}
				/>
				<InputRightElement width="5rem" p=".5rem">
					<Button h="1.75rem" size="sm" onClick={handleSearchButton} colorScheme="gray" w="100%" background="gray.200">
						Buscar
					</Button>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};
