import React from "react";

import { Flex, IconButton, Text, NumberInput, NumberInputField, Divider, Icon } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

import { Pagination, THUNK_STATE } from "config/types";

interface IProps {
	pagination: Pagination | undefined;
	thunkState: string;
	page: number | string;
	setPage: (page: number) => void;
}

export const PaginationComponent: React.FC<IProps> = (props) => {
	const { pagination, thunkState, setPage } = props;

	const paginationRef: Pagination = pagination || {
		total: 0,
		lastPage: 0,
		perPage: 0,
		currentPage: 0,
		from: 0,
		to: 0,
	};

	return (
		<React.Fragment>
			{thunkState !== THUNK_STATE.PENDING && paginationRef.lastPage > 1 && (
				<Flex justifyContent="space-between" m={5} alignItems="center">
					<Divider />
					<Flex>
						<IconButton
							onClick={() => setPage(0)}
							isDisabled={paginationRef.currentPage === 1}
							icon={<Icon as={FiChevronsLeft} h={6} w={6} boxSize="1.75rem" p="0.25rem" />}
							mr={4}
							aria-label={"First page"}
							colorScheme="blue"
							isRound={true}
						/>
						<IconButton
							onClick={() => setPage(paginationRef.currentPage - 1)}
							isDisabled={paginationRef.currentPage === 1}
							icon={<Icon as={FiChevronLeft} h={6} w={6} boxSize="1.75rem" p="0.25rem" />}
							mr={4}
							aria-label={"Previous page"}
							colorScheme="blue"
							isRound={true}
						/>
					</Flex>
					<Flex alignItems="center" justifyContent="center" m="0 1rem">
						<Text as="span" mr={2} fontSize={".8rem"}>
							Página
						</Text>
						<Flex alignItems="center" justifyContent="center" mr={2}>
							<NumberInput
								size="sm"
								ml={2}
								mr={3}
								w={20}
								min={1}
								max={paginationRef.lastPage}
								onChange={(_, value: number) => {
									if (value > paginationRef.lastPage) {
										setPage(paginationRef.lastPage);
									} else if (value < 1) {
										setPage(1);
									} else {
										setPage(value);
									}
								}}
								value={paginationRef.currentPage}
							>
								<NumberInputField defaultValue={paginationRef.currentPage} />
							</NumberInput>
						</Flex>
						<Text fontSize=".8rem" as="span" mr={2}>
							de
						</Text>
						<Text fontSize=".8rem" fontWeight="bold" as="span" mr={2}>
							{paginationRef.lastPage}
						</Text>
					</Flex>
					<Flex>
						<IconButton
							onClick={() => setPage(paginationRef.currentPage + 1)}
							isDisabled={paginationRef.lastPage === paginationRef.currentPage}
							icon={<Icon as={FiChevronRight} h={6} w={6} boxSize="1.75rem" p="0.25rem" />}
							aria-label={"Next page"}
							colorScheme="blue"
							isRound={true}
						/>
						<IconButton
							onClick={() => setPage(paginationRef.lastPage)}
							isDisabled={paginationRef.lastPage === paginationRef.currentPage}
							icon={<Icon as={FiChevronsRight} h={6} w={6} boxSize="1.75rem" p="0.25rem" />}
							ml={4}
							aria-label={"Last Page"}
							colorScheme="blue"
							isRound={true}
						/>
					</Flex>
				</Flex>
			)}
			{thunkState === THUNK_STATE.FULFILLED && paginationRef.total === 0 && (
				<Flex justifyContent="center" m={5} alignItems="center">
					<Text textColor="gray.600" pt={4} textStyle="body3">
						No hay más resultados
					</Text>
				</Flex>
			)}
		</React.Fragment>
	);
};
