import React from "react";

import { Flex, Box } from "@chakra-ui/react";

import { BottomBar } from "./BottomBar";
import { Topbar } from "./TopBar";
interface IProps {
	children: React.ReactNode;
}

export const Layout: React.FC<IProps> = (props) => {
	const smallScreenPadding = "0 3rem";
	const largeScreenPadding = "0 7rem";
	const tabletPadding = "0 4rem";
	const contentHeight = "calc(100dvh - 5rem)";

	const isLoginView = window.location.pathname === "/login";

	return (
		<Flex direction="column" maxH="100dvh">
			{isLoginView && <Box h="3rem" backgroundColor="BAMblue" />}
			{!isLoginView && <Topbar padding={{ sm: tabletPadding, md: largeScreenPadding }} />}
			<Box
				overflow="hidden"
				padding={{ sm: tabletPadding, md: smallScreenPadding, lg: largeScreenPadding }}
				h={contentHeight}
			>
				{props.children}
			</Box>
			<BottomBar padding={{ sm: tabletPadding, md: largeScreenPadding }} />
		</Flex>
	);
};
