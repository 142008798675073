import React from "react";

import {
	Text,
	Select,
	Grid,
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Flex,
	HStack,
	SimpleGrid,
	Input,
	VStack,
} from "@chakra-ui/react";

import { SearchInput } from "common/components/SearchInput";
import { formatDate } from "helpers/formatDate";
import { actions as portfolioActions } from "store/entidades/reducer";
import {
	getEntidadesThunk,
	getEntidadesStatsThunk,
	getVisitadoresThunk,
	getPoblacionesThunk,
} from "store/entidades/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { checkboxFilters } from "../Sidebar/checkBoxFiltersData";

export const FiltrosSelect: React.FC = () => {
	const { data: entidadesStats } = useAppSelector((state) => state.entidades.entidadesStats);
	const { orderBy, filterSelection } = useAppSelector((state) => state.entidades);
	const grupoVisitadores = useAppSelector((state) => state.entidades.grupoVisitadores.data);
	const visitadores = useAppSelector((state) => state.entidades.visitadores.data);
	const { data: poblaciones } = useAppSelector((state) => state.entidades.poblaciones);

	const dispatch = useAppDispatch();

	const grupoVisitadoresSorted = React.useMemo(() => {
		if (!grupoVisitadores) return [];

		return [...grupoVisitadores].sort((a, b) => {
			const numA = parseInt(a.nombre.replace(/\D/g, ""));
			const numB = parseInt(b.nombre.replace(/\D/g, ""));
			return numA - numB;
		});
	}, [grupoVisitadores]);

	const visitadoresSorted = React.useMemo(() => {
		if (!visitadores) return [];

		return [...visitadores].sort((a, b) => {
			return a.nombre.localeCompare(b.nombre);
		});
	}, [visitadores]);

	const estadosEvaluacion = [
		{ value: "En proceso", label: "En proceso" },
		{ value: "Completada", label: "Completada" },
		{ value: "Supervisada", label: "Supervisada" },
		{ value: "Adelantada", label: "Adelantada" },
		{ value: "Programada", label: "Programada" },
		{ value: "Validada", label: "Validada" },
		{ value: "Rechazada", label: "Rechazada" },
	];

	const onChangeSelectFilterHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectId = e.target.id;
		const selectValue = e.target.value;
		dispatch(portfolioActions.setFilterSelection({ ...filterSelection, [selectId]: selectValue }));
	};

	const onChangeInitialDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(
			portfolioActions.setFilterSelection({ ...filterSelection, fechaInicio: formatDate(new Date(e.target.value)) }),
		);
	};

	const onChangeFinalDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fechaFinal = new Date(e.target.value);
		dispatch(portfolioActions.setFilterSelection({ ...filterSelection, fechaFinal: formatDate(fechaFinal) }));
	};

	const onChangeCheckboxFilterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checkboxId = e.target.id;
		const checked = e.target.checked;

		dispatch(
			portfolioActions.setFilterSelection({
				...filterSelection,
				estadoEvaluacion: checked
					? [...(filterSelection?.estadoEvaluacion || []), checkboxId]
					: filterSelection?.estadoEvaluacion?.filter((id) => id !== checkboxId),
			}),
		);
	};

	const allStatesSelected: boolean = filterSelection.estadoEvaluacion?.length === estadosEvaluacion.length;

	const onChangeSelectUnselectAll = () => {
		dispatch(
			portfolioActions.setFilterSelection({
				...filterSelection,
				estadoEvaluacion: allStatesSelected ? ["none"] : estadosEvaluacion.map((estado) => estado.value),
			}),
		);
	};

	React.useEffect(() => {
		if (filterSelection) {
			dispatch(
				getEntidadesThunk({ filterSelection: filterSelection, order: `${orderBy.column}.${orderBy.direction}` }),
			);
			dispatch(getEntidadesStatsThunk({ filterSelection: filterSelection }));
			dispatch(getVisitadoresThunk({ grupoVisitadores: filterSelection?.grupoVisitador || undefined }));
			dispatch(getPoblacionesThunk());
		}
	}, [filterSelection, orderBy]);

	return (
		<Box padding={{ sm: "1.25rem 2rem", md: "0" }} backgroundColor="white" borderRadius={{ sm: "0.375rem", md: "0" }}>
			<Grid
				templateColumns="repeat(2, 1fr)"
				templateRows="repeat(3, 1fr)"
				p=".5rem .5rem"
				display={{ sm: "none", md: "grid" }}
			>
				<Checkbox
					id="all"
					onChange={onChangeSelectUnselectAll}
					mb=".75rem"
					isChecked={allStatesSelected}
					variant="whiteCheckbox"
				>
					<Flex gap="0.25rem">
						<Text textStyle="body2" color="gray.600">
							Todos
						</Text>
					</Flex>
				</Checkbox>
				{checkboxFilters(entidadesStats).map((filter) => (
					<CheckboxGroup key={filter.id} value={filterSelection?.estadoEvaluacion || []}>
						<Checkbox
							id={filter.id}
							variant={filter.variant}
							onChange={onChangeCheckboxFilterHandler}
							isChecked={filterSelection?.estadoEvaluacion?.includes(filter.id)}
							mb=".75rem"
						>
							<Flex gap="0.25rem">
								<Text textStyle="body2" fontWeight="bold" color="black">
									{filter.count}
								</Text>
								<Text textStyle="body2" color="gray.600">
									{filter.label}
								</Text>
							</Flex>
						</Checkbox>
					</CheckboxGroup>
				))}
			</Grid>
			<Box gap={4} p={{ sm: "0 1rem", md: "0" }} mt={{ sm: "0", md: "1rem" }}>
				<SimpleGrid pr={{ sm: "0", md: "1.5rem" }} columns={{ sm: 2, md: 1 }} gap="1rem">
					<Flex direction="row" gap="0.5rem" alignItems="center" display={{ md: "none" }}>
						<SearchInput />
					</Flex>
					<Flex gap="0.5rem" alignItems="center" display={{ sm: "flex", md: "none" }}>
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Estados
						</Text>
						<Select
							id="estadoEvaluacion"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todas los estados"
							value={filterSelection?.estadoEvaluacion || []}
							w="90%"
						>
							{estadosEvaluacion.map((estado) => (
								<option key={estado.value} value={estado.value}>
									{estado.label}
								</option>
							))}
						</Select>
					</Flex>
					<Flex direction="row" gap="0.5rem" alignItems="center">
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Actividad
						</Text>
						<Select
							id="tipoDeActividad"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todas los tipos de actividad"
							value={filterSelection?.tipoDeActividad || ""}
							w="90%"
						>
							<option value="Consumo">Consumo</option>
							<option value="Reparto">Reparto</option>
						</Select>
					</Flex>
					<Flex direction="row" gap="0.5rem" alignItems="center">
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Grupos
						</Text>
						<Select
							id="grupoVisitador"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todos los grupos"
							value={filterSelection?.grupoVisitador || ""}
							w="90%"
						>
							{grupoVisitadoresSorted?.map((grupo) => (
								<option key={grupo.id} value={grupo.nombre}>
									{grupo.nombre}
								</option>
							))}
						</Select>
					</Flex>
					<Flex direction="row" gap="0.5rem" alignItems="center">
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Visitadores
						</Text>
						<Select
							id="visitador"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todos los visitadores"
							value={filterSelection?.visitador || ""}
							w="90%"
						>
							{visitadoresSorted.map((visitador) => (
								<option key={visitador.id} value={visitador.email}>
									{visitador.nombre}
								</option>
							))}
						</Select>
					</Flex>
					<Flex direction="row" gap="0.5rem" alignItems="center">
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Anomalías
						</Text>
						<Select
							id="existenAnomalias"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todas las opciones"
							value={filterSelection?.existenAnomalias || ""}
							w="90%"
						>
							<option value="true">Con anomalías</option>
							<option value="false">Sin anomalías</option>
						</Select>
					</Flex>
					<Flex direction="row" gap="0.5rem" alignItems="center">
						<Text textStyle="body2" color="gray.600" mr="2rem" w="40%">
							Población
						</Text>
						<Select
							id="poblacion"
							onChange={onChangeSelectFilterHandler}
							placeholder="Todas las poblaciones"
							value={filterSelection?.poblacion || ""}
							w="90%"
						>
							{poblaciones?.map((poblacion) => (
								<option key={poblacion} value={poblacion}>
									{poblacion}
								</option>
							))}
						</Select>
					</Flex>
					<VStack gap="0.5rem" alignItems="flex-start">
						<HStack w="100%">
							<Text textStyle="body2" color="gray.600" pr="5.25rem">
								De
							</Text>
							<Input
								type="date"
								name="fecha_inicio"
								onChange={onChangeInitialDate}
								value={filterSelection.fechaInicio || ""}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</HStack>
						<HStack w="100%">
							<Text textStyle="body2" color="gray.600" pr="5.75rem">
								A
							</Text>
							<Input
								type="date"
								name="fecha_final"
								onChange={onChangeFinalDate}
								value={filterSelection.fechaFinal || ""}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</HStack>
					</VStack>
				</SimpleGrid>
			</Box>
			<Button
				variant="solid"
				display={{ sm: "none", md: "block" }}
				w="100%"
				backgroundColor="gray.400"
				borderRadius="0.375rem"
				color="white"
				_hover={{ bg: "gray.200" }}
				_active={{ bg: "gray.50" }}
				mr="1.5rem"
				onClick={() => dispatch(portfolioActions.resetFilterSelection())}
				p="0 1.5rem"
				m="1.5rem 0"
			>
				Limpiar Filtros
			</Button>
		</Box>
	);
};
