import { ActionReducerMapBuilder, current } from "@reduxjs/toolkit";

import { THUNK_STATE } from "config/types";

import { EvaluacionesSlice } from "./reducer";
import {
	actualizarCampoEvaluacionThunk,
	deleteDocumentoThunk,
	getDocumentosThunk,
	getEvaluacionAnteriorThunk,
	getEvaluacionesThunk,
	getEvaluacionThunk,
	getGoogleDriveFileListThunk,
	getGoogleFolderIdThunk,
	patchCambiarEstadoEvaluacionThunk,
	postComentarioThunk,
	postDocumentoThunk,
	postGoogleTokenThunk,
	postProgramarEvaluacionThunk,
	putAdelantarEvaluacionThunk,
} from "./thunks";

export const getEvaluacionesExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getEvaluacionesThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getEvaluacionesThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getEvaluacionesThunk.fulfilled, (state, action) => ({
		...state,
		evaluaciones: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const getEvaluacionExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getEvaluacionThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getEvaluacionThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getEvaluacionThunk.fulfilled, (state, action) => ({
		...state,
		evaluacionSeleccionada: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const getEvaluacionAnteriorExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getEvaluacionAnteriorThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getEvaluacionAnteriorThunk.rejected, (state) => ({
		...state,
		evaluacionSeleccionadaAnterior: {
			thunkState: THUNK_STATE.REJECTED,
			data: null,
		},
	})),
	builder.addCase(getEvaluacionAnteriorThunk.fulfilled, (state, action) => ({
		...state,
		evaluacionSeleccionadaAnterior: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const putAdelantarEvaluacionExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(putAdelantarEvaluacionThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(putAdelantarEvaluacionThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(putAdelantarEvaluacionThunk.fulfilled, (state, action) => ({
		...state,
		evaluacionSeleccionada: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const patchCambiarEstadoEvaluacionExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(patchCambiarEstadoEvaluacionThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(patchCambiarEstadoEvaluacionThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(patchCambiarEstadoEvaluacionThunk.fulfilled, (state, action) => ({
		...state,
		evaluacionSeleccionada: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const actualizarCampoEvaluacionExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(actualizarCampoEvaluacionThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(actualizarCampoEvaluacionThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(actualizarCampoEvaluacionThunk.fulfilled, (state, action) => {
		state.evaluacionSeleccionada.thunkState = THUNK_STATE.FULFILLED;

		if (state.evaluacionSeleccionada.data) {
			const evaluacion = current(state.evaluacionSeleccionada.data);
			const searchKey = action.payload.campo;
			const isRoot = evaluacion[searchKey] !== undefined;

			if (isRoot) {
				state.evaluacionSeleccionada.data = {
					...evaluacion,
					[searchKey]: action.payload.valor,
				};
			} else {
				const seccion = Object.entries(evaluacion)
					.filter(([key]) => key.includes("seccion"))
					.find(([, value]) => {
						return value[searchKey] !== undefined;
					});

				if (seccion) {
					state.evaluacionSeleccionada.data = {
						...evaluacion,
						[seccion[0]]: {
							...state.evaluacionSeleccionada.data[seccion[0]],
							[searchKey]: action.payload.valor,
						},
					};
				}
			}
		}
	}),
];

export const postComentarioExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(postComentarioThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(postComentarioThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(postComentarioThunk.fulfilled, (state, action) => {
		const evaluacionSeleccionada = state.evaluacionSeleccionada.data;

		if (evaluacionSeleccionada) {
			return {
				...state,
				evaluacionSeleccionada: {
					thunkState: THUNK_STATE.FULFILLED,
					data: {
						...evaluacionSeleccionada,
						comentarios: [...evaluacionSeleccionada.comentarios, action.payload],
					},
				},
			};
		} else {
			return state;
		}
	}),
];

export const postProgramarEvaluacionExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(postProgramarEvaluacionThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(postProgramarEvaluacionThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(postProgramarEvaluacionThunk.fulfilled, (state, action) => {
		const evaluaciones = state.evaluaciones.data;

		if (evaluaciones) {
			return {
				...state,
				evaluaciones: {
					thunkState: THUNK_STATE.FULFILLED,
					data: [
						...evaluaciones,
						{
							numero_evaluacion: action.payload.numero_evaluacion,
							fecha: action.payload.fecha_visita,
						},
					],
				},
			};
		} else {
			return state;
		}
	}),
];

// Documentos

export const getDocumentosExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getDocumentosThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getDocumentosThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getDocumentosThunk.fulfilled, (state, action) => ({
		...state,
		documentosDeEvaluacion: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const postDocumentosExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(postDocumentoThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(postDocumentoThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(postDocumentoThunk.fulfilled, (state, action) => {
		const documentosDeEvaluacion = state.documentosDeEvaluacion.data;

		if (documentosDeEvaluacion) {
			return {
				...state,
				documentosDeEvaluacion: {
					thunkState: THUNK_STATE.FULFILLED,
					data: [...documentosDeEvaluacion, action.payload],
				},
			};
		} else {
			return state;
		}
	}),
];

export const deleteDocumentoExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(deleteDocumentoThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(deleteDocumentoThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(deleteDocumentoThunk.fulfilled, (state, action) => {
		const documentosDeEvaluacion = state.documentosDeEvaluacion.data;
		if (documentosDeEvaluacion) {
			return {
				...state,
				documentosDeEvaluacion: {
					thunkState: THUNK_STATE.FULFILLED,
					data: documentosDeEvaluacion.filter((f) => String(f.id) !== action.meta.arg.idDocumento),
				},
			};
		} else {
			return state;
		}
	}),
];

export const getGoogleFolderIdExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getGoogleFolderIdThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getGoogleFolderIdThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getGoogleFolderIdThunk.fulfilled, (state, action) => ({
		...state,
		googleDrive: {
			thunkState: THUNK_STATE.FULFILLED,
			data: {
				...state.googleDrive.data,
				documentsFolder: action.payload,
			},
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const getGoogleDriveFileListExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(getGoogleDriveFileListThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getGoogleDriveFileListThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getGoogleDriveFileListThunk.fulfilled, (state, action) => ({
		...state,
		googleDrive: {
			thunkState: THUNK_STATE.FULFILLED,
			data: {
				...state.googleDrive.data,
				files: action.payload,
			},
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const postGoogleTokenExtraReducer = (builder: ActionReducerMapBuilder<EvaluacionesSlice>) => [
	builder.addCase(postGoogleTokenThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(postGoogleTokenThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(postGoogleTokenThunk.fulfilled, (state, action) => ({
		...state,
		googleDrive: {
			thunkState: THUNK_STATE.FULFILLED,
			data: {
				...state.googleDrive.data,
				authToken: action.payload,
			},
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];
