import { useState } from "react";

import { AxiosError } from "axios";

import { actualizarCampoEvaluacionThunk } from "store/evaluaciones/thunks";
import { useAppDispatch } from "store/store";

export const useAutoInputSend = (codEb: string, codEv: string) => {
	const dispatch = useAppDispatch();
	const [isSending, setIsSending] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const sendInputValue = async (fieldName: string, value: unknown) => {
		setIsSending(true);
		setIsError(false);
		setErrorMessage("");

		try {
			await dispatch(
				actualizarCampoEvaluacionThunk({
					codEB: codEb,
					codEvaluacion: codEv,
					campo: fieldName,
					valor: value,
				}),
			);
		} catch (error) {
			setIsError(true);
			if (error instanceof AxiosError) setErrorMessage(error.message);
		} finally {
			setIsSending(false);
		}

		return;
	};

	return {
		isSending,
		isError,
		errorMessage,
		sendInputValue,
	};
};
