import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApiPaginatedResponse, StatePropWithThunkState, THUNK_STATE } from "config/types";
import { EntidadesFilterSelection, EntidadesStatsOutput, EntidadOutput } from "config/types/entidad";
import { grupoVisitadoresOutput, visitadorOutput } from "config/types/visitadores";

import {
	getEntidadesExtraReducer,
	getEntidadesStatsExtraReducer,
	getGrupoVisitadoresExtraReducer,
	getPoblacionesExtraReducer,
	getVisitadoresExtraReducer,
} from "./extraReducers";
import { SLICE_NAMES } from "../constant";

export interface EntidadesSlice {
	entidades: ApiPaginatedResponse<EntidadOutput[]>;
	entidadesStats: StatePropWithThunkState<EntidadesStatsOutput | null>;
	grupoVisitadores: StatePropWithThunkState<grupoVisitadoresOutput[] | null>;
	visitadores: StatePropWithThunkState<visitadorOutput[] | null>;
	poblaciones: StatePropWithThunkState<string[] | null>;
	filterSelection: EntidadesFilterSelection;
	orderBy: { column: keyof EntidadOutput; direction: "asc" | "desc" };
	page: number;
	thunkState: THUNK_STATE;
}

const sliceInitialState: EntidadesSlice = {
	entidades: {
		data: [],
		pagination: {
			total: -1,
			lastPage: -1,
			perPage: -1,
			currentPage: -1,
			from: -1,
			to: -1,
		},
	},
	entidadesStats: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	grupoVisitadores: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	visitadores: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	poblaciones: {
		thunkState: THUNK_STATE.IDLE,
		data: null,
	},
	filterSelection: {
		estadoEvaluacion: ["En proceso", "Completada", "Supervisada", "Adelantada", "Programada", "Validada", "Rechazada"],
	},
	orderBy: { column: "cod_eb", direction: "asc" },
	page: 1,
	thunkState: THUNK_STATE.IDLE,
};

const customSlice = createSlice({
	name: `${SLICE_NAMES.ENTIDADES}`,
	initialState: sliceInitialState,
	reducers: {
		setFilterSelection: (state, action: PayloadAction<EntidadesFilterSelection>) => ({
			...state,
			filterSelection: { ...action.payload },
		}),
		resetFilterSelection: (state) => ({
			...state,
			filterSelection: {
				estadoEvaluacion: [
					"En proceso",
					"Completada",
					"Supervisada",
					"Adelantada",
					"Programada",
					"Validada",
					"Rechazada",
				],
			},
		}),
		setOrderBy: (state, action: PayloadAction<{ column: keyof EntidadOutput; direction: "asc" | "desc" }>) => ({
			...state,
			orderBy: { ...action.payload },
		}),
		setPage(state, action: PayloadAction<number>) {
			state.page = action.payload;
		},
	},

	extraReducers(builder) {
		getEntidadesExtraReducer(builder);
		getEntidadesStatsExtraReducer(builder);
		getGrupoVisitadoresExtraReducer(builder);
		getVisitadoresExtraReducer(builder);
		getPoblacionesExtraReducer(builder);
	},
});

export const actions = customSlice.actions;
export default customSlice.reducer;
