import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	variants: {
		topbarLink: {
			transition: "all 0.5s",
			color: "gray.600",
			height: "100%",
			textStyle: "body2",
			_hover: {
				color: "red.400",
				textDecoration: "none",
			},
			'&[data-isactive="true"]': {
				color: "red.500",
			},
		},
		reverse: {
			color: "black.100",
			"&:hover": {
				color: "red.500",
			},
		},
	},
	defaultProps: {
		size: "md",
	},
};

export default componentOverride;
