import React from "react";

import { Box, Divider, Flex, FormControl, FormLabel, Grid, GridItem, Text, VStack } from "@chakra-ui/react";

import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";
import {
	almacenKeyMapping,
	almacenesMapping,
	dataCC,
	dataCong,
	dataFYH,
	dataMM,
} from "views/entidad/components/secciones/turnosInfo";

import DisplayInfo from "./DisplayTurnos";
import { dataCCOld, dataCongOld, dataFYHOld, dataMMOld } from "./turnosInfoOld";
import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion04: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_4);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_4_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_4");

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(5);

	const dataMapping = {
		canalseco_1er_turno: dataCC,
		canalseco_2do_turno: dataCC,
		canalseco_3er_turno: dataCC,
		canalseco_4to_turno: dataCC,
		fyh_1er_turno: dataFYH,
		fyh_2do_turno: dataFYH,
		fyh_3er_turno: dataFYH,
		fyh_4to_turno: dataFYH,
		cong_1er_turno: dataCong,
		cong_2do_turno: dataCong,
		cong_3er_turno: dataCong,
		cong_4to_turno: dataCong,
		mm_1er_turno: dataMM,
		mm_2do_turno: dataMM,
		mm_3er_turno: dataMM,
		mm_4to_turno: dataMM,
		mm_5to_turno: dataMM,
	};

	const dataMappingOld = {
		canalseco_1er_turno: dataCCOld,
		canalseco_2do_turno: dataCCOld,
		canalseco_3er_turno: dataCCOld,
		canalseco_4to_turno: dataCCOld,
		fyh_1er_turno: dataFYHOld,
		fyh_2do_turno: dataFYHOld,
		fyh_3er_turno: dataFYHOld,
		fyh_4to_turno: dataFYHOld,
		cong_1er_turno: dataCongOld,
		cong_2do_turno: dataCongOld,
		cong_3er_turno: dataCongOld,
		cong_4to_turno: dataCongOld,
		mm_1er_turno: dataMMOld,
		mm_2do_turno: dataMMOld,
		mm_3er_turno: dataMMOld,
		mm_4to_turno: dataMMOld,
		mm_5to_turno: dataMMOld,
	};
	const numeroTurnosFEGA = React.useMemo(() => {
		return (
			(seccionDataActual?.fyh_1er_turno ? 1 : 0) +
			(seccionDataActual?.fyh_2do_turno ? 1 : 0) +
			(seccionDataActual?.fyh_3er_turno ? 1 : 0) +
			(seccionDataActual?.fyh_4to_turno ? 1 : 0)
		);
	}, []);
	return (
		<Box>
			<SeccionHeader seccion="04" />
			<Box width="100%">
				<Grid templateColumns="repeat(2, 1fr)" columnGap="2.25rem" rowGap="1.75rem">
					{seccionDataActual &&
						Object.entries(almacenesMapping).map(([almacen, keys]) => (
							<VStack key={almacen} alignItems="start" spacing=".25rem">
								<Text fontWeight="bold" textStyle="body2">
									{almacen}
								</Text>
								<Text color="gray.600" textStyle="body3">
									Almacén: {seccionDataActual[almacenKeyMapping[almacen]]}
								</Text>
								<Grid templateColumns="repeat(1, 1fr)" columnGap="1rem" justifyContent="start">
									{keys.map((key) => {
										const dataSource = dataMapping[key];
										const dataSourceOld = dataMappingOld[key];
										const value = Number(seccionDataActual[key]);
										if (!dataSource) return null;
										return (
											<GridItem key={key} justifyItems={"start"}>
												<DisplayInfo dataSource={dataSource} turnosKey={key} turnosValue={value} />
												<DisplayInfo dataSource={dataSourceOld} turnosKey={key} turnosValue={value} />
											</GridItem>
										);
									})}
								</Grid>
							</VStack>
						))}
					<VStack alignItems="start" spacing=".25rem">
						<Text fontWeight="bold" textStyle="body2">
							FEAD
						</Text>
						<Text color="gray.600" textStyle="body3">
							Almacén: {seccionDataActual?.almacen_FEAD}
						</Text>
					</VStack>
				</Grid>
				<Divider margin="1.5rem 0" color="gray.200" />
				<Flex mt="1rem" alignItems="center">
					<Text textStyle="body2">Factor de distribución:</Text>
					<Text textStyle="body2" color="gray.700" ml=".25rem">
						{formatNumber(seccionDataActual?.factor_distribucion)}
					</Text>
				</Flex>
				<Flex mt="1rem" alignItems="center">
					<Text textStyle="body2">Max kg. fruta por mes:</Text>
					<Text textStyle="body2" color="gray.700" ml=".25rem">
						{numeroTurnosFEGA * (seccionDataActual?.maximo_kilos_fega_frutas_mes ?? 0)}
					</Text>
				</Flex>
				<Flex mt="1rem" alignItems="center">
					<Text textStyle="body2">Max kg. hortaliza por mes:</Text>
					<Text textStyle="body2" color="gray.700" ml=".25rem">
						{numeroTurnosFEGA * (seccionDataActual?.maximo_kilos_fega_hortalizas_mes ?? 0)}
					</Text>
				</Flex>
				<Flex mt="1rem" alignItems="center">
					<Text textStyle="body2">Max. kg. FyH por mes:</Text>
					<Text textStyle="body2" color="gray.700" ml=".25rem">
						{numeroTurnosFEGA *
							(Number(seccionDataActual?.maximo_kilos_fega_frutas_mes) +
								Number(seccionDataActual?.maximo_kilos_fega_hortalizas_mes))}
					</Text>
				</Flex>
				{(seccionDataActual?.factor_distribucion ||
					seccionDataActual?.maximo_kilos_fega_frutas_mes ||
					seccionDataActual?.maximo_kilos_fega_hortalizas_mes) && <Divider margin="1.5rem 0" color="gray.200" />}
				<Grid
					templateColumns="repeat(2, 1fr)"
					columnGap="2.25rem"
					rowGap="1.75rem"
					style={{ pageBreakInside: "avoid" }}
				>
					<GridItem>
						{seccionDataActual?.datos_correctos_4 !== undefined && (
							<FormControl>
								<FormLabel>¿Los datos son correctos?</FormLabel>
								<Box width="fit-content">
									<CheckboxGroup
										disableNA
										name="datos_correctos_4"
										defaultValue={seccionDataActual?.datos_correctos_4}
										onChange={(name, value) => sendInputValue(name, value)}
										changed={changeDetector("datos_correctos_4")}
										isDisabled={isDisabled}
									/>
								</Box>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={3} mb=".5rem">
						<FormControl>
							<FormLabel>Comentarios</FormLabel>
							<TextAreaInput
								name="comentarios_4"
								defaultValue={seccionDataActual?.comentarios_4}
								{...changeDetector("comentarios_4")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
			</Box>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_4_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_4_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion04;
