export const checkboxFilters = (entidadesStats) => {
	return [
		{
			id: "Rechazada",
			count: entidadesStats?.["Rechazada"],
			label: "Rechazadas",
			variant: "redCheckbox",
		},
		{
			id: "Programada",
			count: entidadesStats?.["Programada"],
			label: "Programadas",
			variant: "lightCheckbox",
		},
		{
			id: "Completada",
			count: entidadesStats?.["Completada"],
			label: "Completadas",
			variant: "blueCheckbox",
		},

		{
			id: "Adelantada",
			count: entidadesStats?.["Adelantada"],
			label: "Adelantadas",
			variant: "grayCheckbox",
		},
		{
			id: "Supervisada",
			count: entidadesStats?.["Supervisada"],
			label: "Supervisadas",
			variant: "tealCheckbox",
		},
		{
			id: "En proceso",
			count: entidadesStats?.["En proceso"],
			label: "En proceso",
			variant: "orangeCheckbox",
		},
		{
			id: "Validada",
			count: entidadesStats?.["Validada"],
			label: "Validadas",
			variant: "greenCheckbox",
		},
	];
};
