import React from "react";

import {
	Text,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Divider,
	Textarea,
	Grid,
	GridItem,
	useToast,
} from "@chakra-ui/react";

import { patchCambiarEstadoEvaluacionThunk } from "store/evaluaciones/thunks";
import { useAppDispatch } from "store/store";

interface ModalRechazadaProps {
	codEB: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ModalRechazada: React.FC<ModalRechazadaProps> = (props) => {
	const { codEB, isOpen, onClose } = props;

	const [isLoading, setIsLoading] = React.useState(false);
	const [comentario, setComentario] = React.useState("");

	const dispatch = useAppDispatch();
	const toast = useToast();

	const handleOnClick = async () => {
		setIsLoading(true);
		try {
			const res = await dispatch(
				patchCambiarEstadoEvaluacionThunk({
					codEB: codEB,
					data: {
						comentario: comentario,
						estado: "Rechazada",
					},
				}),
			);
			if (res.meta.requestStatus === "fulfilled") {
				toast({
					title: `Hoja de visita rechazada`,
					description: `La hoja de visita ha sido marcada como rechazada con éxito`,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (err) {
			toast({
				title: "Error",
				description: `La hoja de visita no ha podido ser marcada como rechazada`,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		onClose();
		setIsLoading(false);
	};

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent containerProps={{ pt: "10rem" }}>
				<ModalHeader m=".5rem 0">
					<Text textStyle="body1" color="pink.500">
						Marcar hoja de visita como rechazada
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody pb={6}>
					<Grid templateColumns="repeat(2, 1fr)" gap={4} mt="1rem">
						<GridItem colSpan={1}>
							<Text textStyle="body3" color="gray.800" mt=".25rem">
								La Hoja de visita volverá al visitador para que pueda hacer los cambios necesarios.
							</Text>
							<Text textStyle="body3" color="gray.800" mt=".5rem">
								Por favor indícale en esta caja de comentarios qué necesita revisar.
							</Text>
						</GridItem>
						<GridItem colSpan={1} mb="1rem">
							<Text textStyle="body3" color="gray.800" mb=".3rem">
								Dejar mensaje en el chat
							</Text>
							<Textarea h="100%" onChange={(e) => setComentario(e.target.value)} name="comentario" />
						</GridItem>
					</Grid>
				</ModalBody>
				<Divider />
				<ModalFooter m=".75rem 0">
					<Button bgColor="gray.400" onClick={onClose} mr={3} w="100%" _hover={{ bg: "gray.500" }}>
						Cancelar
					</Button>
					<Button
						bgColor="pink.500"
						w="100%"
						_hover={{ bg: { color: "pink.600" } }}
						onClick={handleOnClick}
						isLoading={isLoading}
						loadingText="Enviando"
					>
						Marcar como rechazada
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
