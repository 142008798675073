import React from "react";

import {
	Text,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Divider,
	Textarea,
	Grid,
	GridItem,
	Select,
	Box,
	Input,
	FormControl,
	useToast,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

import { putAdelantarEvaluacionThunk } from "store/evaluaciones/thunks";
import { useAppDispatch } from "store/store";

interface ModalAdelantarVisitaProps {
	codEB?: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ModalAdelantarVisita: React.FC<ModalAdelantarVisitaProps> = (props) => {
	const { codEB, isOpen, onClose } = props;
	const [isLoading, setIsLoading] = React.useState(false);
	const [formValues, setFormValues] = React.useState({
		motivo: "",
		fecha: DateTime.now().toISODate() || "",
		existenAnomalias: false,
	});

	const dispatch = useAppDispatch();
	const toast = useToast();

	const today = DateTime.now();
	const currentMonthYear = today.toFormat("yyyy-MM");

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
		const { name, value } = e.target;
		let formattedValue: string;

		if (name === "fecha") {
			const [year, month] = value.split("-");
			formattedValue = DateTime.fromObject({ year: Number(year), month: Number(month) }).toISODate() || "";
		} else {
			formattedValue = value;
		}

		setFormValues({ ...formValues, [name]: formattedValue });
	};

	const handleOnClick = async () => {
		setIsLoading(true);
		if (!codEB) return;
		try {
			const res = await dispatch(
				putAdelantarEvaluacionThunk({
					codEB,
					data: {
						motivo: formValues.motivo,
						fecha: formValues.fecha,
						existenAnomalias: formValues.existenAnomalias,
					},
				}),
			);

			if (res.meta.requestStatus === "fulfilled") {
				setIsLoading(false);
				toast({
					title: "Visita adelantada",
					description: "La visita ha sido adelantada exitosamente",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
				onClose();
			} else {
				setIsLoading(false);
				toast({
					title: "Error al adelantar visita",
					description: "Ha ocurrido un error al adelantar la visita",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent containerProps={{ pt: "10rem" }}>
				<ModalHeader m=".5rem 0">
					<Text textStyle="body1" color="pink.500">
						Adelantar visita a la Entidad Beneficiaria
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<Divider />
				<FormControl id="adelantar-evaluacion" mt="1rem">
					<ModalBody pb={6}>
						<Grid templateColumns="repeat(2, 1fr)" gap={4} mt="1rem">
							<GridItem colSpan={1} mb="1rem">
								<Text textStyle="body3" color="gray.800" mb=".3rem">
									Motivo del adelanto:
								</Text>
								<Textarea h="100%" onChange={(e) => handleChange(e)} name="motivo" isRequired />
							</GridItem>
							<GridItem colSpan={1} mb="1rem">
								<Text textStyle="body3" color="gray.800" mb=".3rem">
									¿Se trata de una anomalía?
								</Text>
								<Select
									placeholder="Selecciona una opción"
									mb="1rem"
									defaultValue="false"
									onChange={(e) => handleChange(e)}
									name="existenAnomalias"
									isRequired
								>
									<option value="true">Si</option>
									<option value="false">No</option>
								</Select>
								<Text textStyle="body3" color="gray.800" m=".3rem 0">
									Nueva fecha de visita:
								</Text>
								<Box w="100%">
									<Input
										type="month"
										defaultValue={currentMonthYear}
										onChange={handleChange}
										width="100%"
										isRequired
										name="fecha"
										min={currentMonthYear}
									/>
								</Box>
							</GridItem>
						</Grid>
					</ModalBody>
					<Divider />
					<ModalFooter m=".75rem 0">
						<Button bgColor="gray.400" onClick={onClose} mr={3} w="100%" _hover={{ bg: "gray.500" }}>
							Cancelar
						</Button>
						<Button
							bgColor="pink.400"
							w="100%"
							_hover={{ bg: "pink.500" }}
							onClick={handleOnClick}
							isLoading={isLoading}
							loadingText="Enviando"
						>
							Adelantar visita
						</Button>
					</ModalFooter>
				</FormControl>
			</ModalContent>
		</Modal>
	);
};
