import React from "react";

import { Box, GridItem, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

import { TabletTableHeader } from "common/components/TabletHeader";
import { THUNK_STATE } from "config/types";
import { useAppSelector } from "store/store";

import { Sidebar } from "./components/Sidebar";
import { TableHeader } from "./components/TableHeader";
import { TableRow } from "./components/TableRow";

export const Portfolio: React.FC = () => {
	const { thunkState, permissions } = useAppSelector((state) => state.usuario);
	const userStatus = permissions && permissions.data && permissions.data[0]?.situacion;
	const userIsActive = userStatus === "Alta";

	return (
		<React.Fragment>
			{THUNK_STATE.FULFILLED === thunkState && userIsActive && (
				<SimpleGrid
					columns={{ sm: 1, md: 4 }}
					spacingX="1.5rem"
					spacingY="1.5rem"
					mt="2rem"
					maxH={"calc(95dvh - 10dvh)"}
				>
					<GridItem colSpan={1} display={{ sm: "none", md: "block" }}>
						<Sidebar />
					</GridItem>
					<GridItem colSpan={3}>
						<TabletTableHeader />
						<TableHeader />
						<Box
							overflow="auto"
							maxH={"calc(95dvh - 15dvh)"}
							sx={{
								"::-webkit-scrollbar": {
									width: ".75rem",
								},
							}}
						>
							<TableRow />
						</Box>
					</GridItem>
				</SimpleGrid>
			)}
			{THUNK_STATE.FULFILLED === thunkState && !userIsActive && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					mt="2rem"
					maxH={"calc(95dvh - 10dvh)"}
				>
					<Box
						p="2rem"
						borderRadius="0.5rem"
						backgroundColor="white"
						mt="5rem"
						textAlign="center"
						borderColor={"gray.200"}
						borderWidth="2px"
					>
						<Icon as={FiAlertTriangle} boxSize="2rem" color="red" h="2.5rem" w="2.5rem" />
						<Text textStyle="heading5" fontWeight="bold" color="gray.700">
							No tienes permisos para ver esta página
						</Text>
						<Text textStyle="body1" color="gray.600">
							Contacta con el administrador
						</Text>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
};
