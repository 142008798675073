import axios, { AxiosRequestConfig } from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { usuarioOutput } from "config/types/usuario";

export const getPermisos: CustomAxiosRequest<void, usuarioOutput[]> = () => {
	const url = `${envConfig.API_URL}/permisos/`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};
