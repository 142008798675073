import React from "react";

import { Box, BoxProps, Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";

import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";

interface IProps {
	estado: "En proceso" | "Completada" | "Supervisada" | "Adelantada" | "Programada" | "Validada" | "Rechazada";
	style?: TextProps | BoxProps | FlexProps;
}

const StatusIndicator: React.FC<IProps> = (props) => {
	const { estado, style } = props;

	const statusColorSelector = (estado: string) => {
		switch (estado) {
			case "En proceso":
				return "Proceso2";
			case "Rechazada":
				return "red.100";
			case "Completada":
				return "Completada2";
			case "Supervisada":
				return "Supervisada2";
			case "Adelantada":
				return "gray.200";
			case "Programada":
				return "gray.50";
			case "Validada":
				return "Validada2";

			default:
				return "transparent";
		}
	};

	const fontColorSelector = (estado: string) => {
		switch (estado) {
			case "En proceso":
				return "Proceso0";
			case "Rechazada":
				return "red.500";
			case "Completada":
				return "Completada0";
			case "Supervisada":
				return "Supervisada0";
			case "Adelantada":
				return "gray.600";
			case "Programada":
				return "gray.500";
			case "Validada":
				return "Validada0";

			default:
				return "transparent";
		}
	};

	const statusBoxProps: BoxProps = {
		borderRadius: ".35rem",
		justifyContent: "center",
		alignContent: "center",
		h: "100%",
		w: "100%",
		bgColor: (estado && statusColorSelector(estado)) || "transparent",
	};

	return (
		<Flex h="100%" w="100%">
			<Box {...statusBoxProps}>
				<Text
					textStyle={style ? style.textStyle : "body3"}
					m=".25rem"
					color={fontColorSelector(estado)}
					textAlign="center"
					justifyContent="center"
					fontWeight={style ? style.fontWeight : "normal"}
				>
					{capitalizeFirstLetter(estado)}
				</Text>
			</Box>
		</Flex>
	);
};

export default StatusIndicator;
