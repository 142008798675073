import React from "react";

import {
	Button,
	Divider,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { patchCambiarEstadoEvaluacionThunk } from "store/evaluaciones/thunks";
import { useAppDispatch } from "store/store";

interface ModalDeEstadosProps {
	codEB: string;
	estado: "En proceso" | "Completada" | "Supervisada" | "Validada" | "Rechazada";
	texto1: string;
	informar_a: string;
	color: string;
	hoverBoton: string;
	isOpen: boolean;
	onClose: () => void;
}

export const ModalDeEstados: React.FC<ModalDeEstadosProps> = (props) => {
	const { codEB, estado, texto1, informar_a, color, hoverBoton, isOpen, onClose } = props;
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = React.useState(false);
	const [comentario, setComentario] = React.useState("");

	const dispatch = useAppDispatch();
	const toast = useToast();

	const handleOnClick = async () => {
		setIsLoading(true);
		if (!codEB) return;
		try {
			const res = await dispatch(
				patchCambiarEstadoEvaluacionThunk({
					codEB: codEB,
					data: {
						comentario: comentario,
						estado: estado,
					},
				}),
			);
			if (res.meta.requestStatus === "fulfilled") {
				toast({
					title: `Hoja de visita ${estado}`,
					description: `La hoja de visita ha sido marcada como ${estado} con éxito`,
					status: "success",
					duration: 5000,
					isClosable: true,
				});

				if (estado === "Validada") {
					navigate("?resolucion=true");
				}
			} else {
				toast({
					title: "Error",
					description: `La hoja de visita no ha podido ser marcada como ${estado}`,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error(error);
		}

		onClose();
		setIsLoading(false);
	};

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent containerProps={{ pt: "10rem" }}>
				<ModalHeader m=".5rem 0">
					<Text textStyle="body1" color={color}>
						Marcar hoja de visita como {estado}
					</Text>
				</ModalHeader>
				{!isLoading && <ModalCloseButton />}
				<Divider />
				<ModalBody pb={6}>
					<Grid templateColumns="repeat(2, 1fr)" gap={4} mt="1rem">
						<GridItem colSpan={1}>
							<Text textStyle="body2" color="gray.800" fontWeight="semibold">
								¡Este paso es definitivo!
							</Text>
							<Text textStyle="body3" color="gray.800" mt=".25rem">
								{texto1}
							</Text>
							<Text textStyle="body3" color="gray.800" mt=".5rem">
								Puedes dejar un comentario para informar al {informar_a} del estado de la hoja de visita.
							</Text>
						</GridItem>
						<GridItem colSpan={1} mb="1rem">
							<Text textStyle="body3" color="gray.800" mb=".3rem">
								Dejar mensaje en el chat
							</Text>
							<Textarea h="100%" onChange={(e) => setComentario(e.target.value)} name="comentario" />
						</GridItem>
					</Grid>
				</ModalBody>
				<Divider />
				<ModalFooter m=".75rem 0">
					<Button
						bgColor="gray.400"
						onClick={onClose}
						mr={3}
						w="100%"
						_hover={{ bg: "gray.500" }}
						isDisabled={isLoading}
					>
						Cancelar
					</Button>
					<Button
						bgColor={color}
						w="100%"
						_hover={{ bg: { hoverBoton } }}
						onClick={handleOnClick}
						isLoading={isLoading}
						loadingText="Enviando"
					>
						Marcar como {estado}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
