import { EvaluacionOutput } from "config/types/evaluacion";
import { ISeccion1ContactInformationOutput } from "config/types/secciones/seccion1";

export const useInputDiffDetector = (
	prevEv: EvaluacionOutput | null,
	currentEv: EvaluacionOutput,
	seccion?: string,
) => {
	const detectChanges = (
		fieldName: string,
	): {
		"data-ischanged": "true" | "false";
	} => {
		if (!prevEv) return { "data-ischanged": "false" };
		// compare prevEv and currentEv
		let prevValue = "";
		let currentValue = "";

		if (seccion) {
			prevValue = prevEv[seccion][fieldName];
			currentValue = currentEv[seccion][fieldName];
		} else {
			prevValue = prevEv[fieldName];
			currentValue = currentEv[fieldName];
		}

		return {
			"data-ischanged": prevValue !== currentValue ? "true" : "false",
		};
	};

	return detectChanges;
};

export const useContactInputDiffDetector = (prevEv: EvaluacionOutput | null, currentEv: EvaluacionOutput | null) => {
	const detectChanges = (nombre: string, apellidos: string): { "data-ischanged": "true" | "false" } => {
		if (!prevEv) return { "data-ischanged": "true" };
		if (!currentEv) return { "data-ischanged": "false" };

		const prevContact = prevEv["seccion_1"]["contacts"].find(
			(contact: ISeccion1ContactInformationOutput) =>
				contact.nombre_contacto === nombre && contact.apellidos_contacto === apellidos,
		);

		const currentContact = currentEv["seccion_1"]["contacts"].find(
			(contact: ISeccion1ContactInformationOutput) =>
				contact.nombre_contacto === nombre && contact.apellidos_contacto === apellidos,
		);

		const isObject = (obj: ISeccion1ContactInformationOutput) => obj === Object(obj);

		const isObjectEqual = (a: ISeccion1ContactInformationOutput, b: ISeccion1ContactInformationOutput) => {
			const aProps = Object.getOwnPropertyNames(a);
			const bProps = Object.getOwnPropertyNames(b);

			if (aProps.length !== bProps.length) {
				return false;
			}

			for (let i = 0; i < aProps.length; i++) {
				const propName = aProps[i];

				if (a[propName] !== b[propName]) {
					return false;
				}
			}

			return true;
		};

		if (!prevContact && currentContact) return { "data-ischanged": "true" };

		if (prevContact && currentContact && isObject(prevContact) && isObject(currentContact)) {
			return {
				"data-ischanged": isObjectEqual(prevContact, currentContact) ? "false" : "true",
			};
		}

		return { "data-ischanged": "false" };
	};

	return detectChanges;
};

export const useContactFieldChangeDetector = (prevEv: EvaluacionOutput | null, currentEv: EvaluacionOutput | null) => {
	const detectChanges = (
		contact: ISeccion1ContactInformationOutput,
		fieldName: string,
	): { "data-ischanged": "true" | "false" } => {
		if (!prevEv) return { "data-ischanged": "true" };
		if (!currentEv) return { "data-ischanged": "false" };

		const prevContact = prevEv["seccion_1"]["contacts"].find(
			(v: ISeccion1ContactInformationOutput) =>
				v.nombre_contacto === contact.nombre_contacto && v.apellidos_contacto === contact.apellidos_contacto,
		);

		const currentContact = currentEv["seccion_1"]["contacts"].find(
			(v: ISeccion1ContactInformationOutput) =>
				v.nombre_contacto === contact.nombre_contacto && v.apellidos_contacto === contact.apellidos_contacto,
		);

		const previousValue = prevContact ? prevContact[fieldName] : "";
		const currentValue = currentContact ? currentContact[fieldName] : "";
		if (!prevContact && currentContact) return { "data-ischanged": "true" };
		if (prevContact && currentContact && previousValue === currentValue) return { "data-ischanged": "false" };
		if (prevContact && currentContact && previousValue !== currentValue) return { "data-ischanged": "true" };

		return { "data-ischanged": "true" };
	};
	return detectChanges;
};
