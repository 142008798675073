import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: "body",
		fontSize: "body0",
		fontWeight: "body0",
		lineHeight: "body0",
		letterSpacing: "body0",
	},
	variants: {
		solidModules: {
			backgroundColor: "white",
			color: "gray.600",
			border: "none",
			_hover: {
				color: "red.500",
				backgroundColor: "white",
			},
			_active: {
				color: "white",
				backgroundColor: "red.500",
			},
			_disabled: {
				opacity: 0.4,
			},
		},
		outlineModules: {
			backgroundColor: "transparent",
			color: "gray.600",
			border: "1px solid",
			borderColor: "gray.200",
			_hover: {
				color: "red.500",
				backgroundColor: "white",
			},
			_active: {
				color: "white",
				backgroundColor: "red.500",
				borderColor: "red.500",
			},
			_disabled: {
				opacity: 0.4,
			},
		},
		solidBlack: {
			backgroundColor: "gray.600",
			color: "white",
			border: "none",
			_hover: {
				color: "white",
				backgroundColor: "gray.700",
			},
			_active: {
				color: "white",
				backgroundColor: "gray.800",
			},
			_disabled: {
				opacity: 0.4,
			},
		},
		solidTab: {
			backgroundColor: "transparent",
			color: "gray.500",
			borderColor: "gray.200",
			border: "1px solid",
			_hover: {
				color: "gray.600",
				borderColor: "gray.400",
				backgroundColor: "gray.50",
			},
			_active: {
				color: "gray.800",
				borderColor: "gray.600",
			},
			_disabled: {
				opacity: 0.4,
			},
			"&[data-isactive='true']": {
				color: "gray.800",
				borderColor: "gray.600",
			},
		},
	},
	defaultProps: {
		size: "sm",
		colorScheme: "red",
		variant: "solid",
	},
};

export default componentOverride;
