import React from "react";

import { Box, Divider, FormControl, FormLabel, Grid, GridItem, Input, Select, Text, VStack } from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";

import TextAreaInput from "common/components/TextAreaInput/TextAreaInput";
import { checkPermission } from "helpers/checkPermissions";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion10: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);

	const seccionDataAnterior = useAppSelector(
		(state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data?.seccion_10,
	);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_10);
	const printView = useAppSelector((state) => state.usuario.printView);

	const oneYearFromNow = DateTime.now().set({ day: 2 }).plus({ years: 1 }).toFormat("yyyy-MM-dd");
	const initialDate = evaluacion?.fecha_proxima_visita
		? DateTime.fromJSDate(new Date(evaluacion.fecha_proxima_visita)).toFormat("yyyy-MM-dd")
		: oneYearFromNow;

	const [selectedDate, setSelectedDate] = React.useState(initialDate);

	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);

	const isVisitador = checkPermission(permisosUsuario, "visitador");
	const isSupervisor = checkPermission(permisosUsuario, "visitador supervisor");

	const isButtonDisabled = React.useMemo(() => {
		if (isVisitador && evaluacionSeleccionada?.estado_evaluacion !== "En proceso") return true;
		if (isSupervisor && evaluacionSeleccionada?.estado_evaluacion === "Supervisada") return true;
		return false;
	}, [evaluacionSeleccionada, isVisitador, isSupervisor]);

	const isDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const debouncedSendInputValue = React.useMemo(
		() =>
			debounce((name: string, value: string) => {
				sendInputValue(name, value);
			}, 1000),
		[seccionDataActual],
	);

	const seccionesAvailable = Object.keys(evaluacion || {}).filter((key) => key.startsWith("seccion_"));
	const allSectionsCompleted =
		(evaluacion && seccionesAvailable.every((seccion) => evaluacion[seccion][`${seccion}_completada`])) || false;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value } = event.target;
		setSelectedDate(value);
		debouncedSendInputValue(name, value);
	};

	const renderComentarios = React.useCallback(
		(title: string, text: string) => (
			<React.Fragment>
				<Text textStyle="body2" color="gray.600" margin="0">
					{title}
				</Text>
				<Text textStyle="body2" color="black" margin="0">
					{text}
				</Text>
			</React.Fragment>
		),
		[evaluacionSeleccionada?.numero_evaluacion],
	);

	const next14Months = React.useMemo(() => {
		const fechaVisitaDate = new Date();
		const months: Date[] = [];
		for (let i = 1; i < 13; i++) {
			const newDate = new Date(fechaVisitaDate.getFullYear(), fechaVisitaDate.getMonth(), 2);
			newDate.setMonth(newDate.getMonth() + i);
			months.push(newDate);
		}
		return months;
	}, [evaluacion]);

	React.useEffect(() => {
		if (evaluacion?.estado_evaluacion === "En proceso" && !evaluacion?.fecha_proxima_visita) {
			sendInputValue("fecha_proxima_visita", selectedDate);
		}
	}, []);

	return (
		<Box>
			<SeccionHeader seccion="10" />
			<Box width="100%">
				<Divider color="gray.200" />
				<Grid templateColumns="repeat(1, 1fr)" gap="1rem" style={{ pageBreakInside: "avoid" }}>
					{(seccionDataAnterior?.observaciones ||
						seccionDataAnterior?.sugerencias_de_la_eb ||
						seccionDataAnterior?.resumen_visitador) && (
						<Text textStyle="body2" color="black" mt="1.5rem" fontWeight="700">
							Evaluación anterior
						</Text>
					)}
					<GridItem>
						<VStack alignItems="flex-start" spacing=".5rem">
							{seccionDataAnterior?.observaciones &&
								renderComentarios("Observaciones del visitador", seccionDataAnterior?.observaciones)}
							{seccionDataAnterior?.sugerencias_de_la_eb &&
								renderComentarios("Sugerencias de la EB", seccionDataAnterior?.sugerencias_de_la_eb)}
							{seccionDataAnterior?.resumen_visitador &&
								renderComentarios("Resumen del visitador", seccionDataAnterior?.resumen_visitador)}
						</VStack>
					</GridItem>
					<GridItem>
						<Text textStyle="body2" color="black" marginBottom=".5rem" fontWeight="700">
							Evaluación actual
						</Text>
						<VStack alignItems="flex-start" spacing=".5rem">
							{evaluacion?.ultima_actualizacion_visitador && (
								<Box>
									<Text textStyle={"body2"} color="gray.700">
										Ultima actualización por el visitador:
									</Text>
									<Text textStyle={"body2"} mt=".25rem">
										{evaluacion?.ultima_actualizacion_visitador}
									</Text>
								</Box>
							)}
							<FormControl>
								{evaluacionSeleccionada?.fecha_en_proceso && (
									<React.Fragment>
										<FormLabel>Fecha visita</FormLabel>
										<Input
											type="date"
											name="fecha_visita"
											onChange={handleChange}
											isDisabled={isDisabled}
											defaultValue={
												evaluacionSeleccionada?.fecha_visita
													? DateTime.fromJSDate(new Date(evaluacionSeleccionada.fecha_visita)).toFormat("yyyy-MM-dd")
													: DateTime.fromJSDate(new Date(evaluacionSeleccionada?.fecha_en_proceso)).toFormat(
															"yyyy-MM-dd",
													  )
											}
										/>
									</React.Fragment>
								)}
							</FormControl>
							<FormControl>
								<FormLabel>Siguiente visita</FormLabel>
								<Select
									name="fecha_proxima_visita"
									onChange={handleChange}
									value={selectedDate}
									isDisabled={isDisabled}
									mb="1rem"
								>
									{next14Months.map((date) => (
										<option key={date.toISOString()} value={DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")}>
											En&nbsp;
											{DateTime.fromJSDate(date).toFormat("MMMM", {
												locale: "es-ES",
											})}
											&nbsp;de&nbsp;{DateTime.fromJSDate(date).toFormat("yyyy")}
										</option>
									))}
								</Select>
							</FormControl>
							{!printView && (
								<React.Fragment>
									<FormControl>
										<FormLabel mt=".5rem">Observaciones del visitador</FormLabel>
										<TextAreaInput
											name="observaciones"
											defaultValue={seccionDataActual?.observaciones || ""}
											isDisabled={isDisabled}
										/>
									</FormControl>
									<FormControl>
										<FormLabel mt=".5rem">Sugerencias de la EB</FormLabel>
										<TextAreaInput
											name="sugerencias_de_la_eb"
											defaultValue={seccionDataActual?.sugerencias_de_la_eb || ""}
											isDisabled={isDisabled}
										/>
									</FormControl>
									<FormControl>
										<FormLabel mt=".5rem">Resumen del visitador</FormLabel>
										<TextAreaInput
											name="resumen_visitador"
											defaultValue={seccionDataActual?.resumen_visitador || ""}
											isDisabled={isDisabled}
										/>
									</FormControl>
								</React.Fragment>
							)}
							{printView && (
								<VStack alignItems="flex-start" spacing="1.5rem">
									{renderComentarios("Observaciones del visitador", seccionDataActual?.observaciones || "-")}
									{renderComentarios("Sugerencias de la EB", seccionDataActual?.sugerencias_de_la_eb || "-")}
									{renderComentarios("Resumen del visitador", seccionDataActual?.resumen_visitador || "-")}
								</VStack>
							)}
						</VStack>
					</GridItem>
				</Grid>
			</Box>

			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={allSectionsCompleted}
						nextSection={allSectionsCompleted ? props.abrirModalCambioEstado : undefined}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_10_completada", flag ? "1" : "0");
						}}
						isDisabled={
							permisosUsuario[0]?.cargo_funcion === "Solo lectura" ||
							evaluacion?.estado_evaluacion === "Completada" ||
							evaluacion?.estado_evaluacion === "Supervisada" ||
							evaluacion?.estado_evaluacion === "Validada"
						}
						section10Disabled={isButtonDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion10;
