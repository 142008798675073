import React from "react";

import { EvaluacionOutput } from "config/types/evaluacion";
import { useAppSelector } from "store/store";

export const useIsDisabled = (evaluacion?: EvaluacionOutput | null) => {
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);
	const [isDisabled, setIsDisabled] = React.useState(false);

	if (!evaluacion) return isDisabled;

	React.useEffect(() => {
		if (permisosUsuario[0]?.cargo_funcion === "Solo lectura") {
			setIsDisabled(true);
		} else if (
			evaluacion.estado_evaluacion === "En proceso" ||
			evaluacion.estado_evaluacion === "Adelantada" ||
			evaluacion.estado_evaluacion === "Programada" ||
			evaluacion.estado_evaluacion === "Rechazada"
		) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [evaluacion.estado_evaluacion]);
	return isDisabled;
};
