import React from "react";

import { Text, Button } from "@chakra-ui/react";

import { checkPermission } from "helpers/checkPermissions";
import { useAppSelector } from "store/store";

interface BotonAdelantarVisitaProps {
	onOpenRechazar: () => void;
}

export const BotonRechazarEvaluacion: React.FC<BotonAdelantarVisitaProps> = (props) => {
	const { onOpenRechazar } = props;
	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);
	const isSupervisor = checkPermission(permisosUsuario, "visitador supervisor");

	return (
		<React.Fragment>
			<Button
				backgroundColor="gray.400"
				w="12rem"
				_hover={{ bg: "gray.500" }}
				loadingText="Enviando"
				_active={{ bg: "gray.400" }}
				onClick={onOpenRechazar}
				mr="1rem"
				isDisabled={isSupervisor && evaluacionSeleccionada?.estado_evaluacion === "Supervisada"}
			>
				<Text textStyle="body3" color="white">
					Rechazar resolución
				</Text>
			</Button>
		</React.Fragment>
	);
};
