import axios, { AxiosRequestConfig } from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { ApiPaginatedResponse } from "config/types";
import { EntidadOutput, EntidadesStatsOutput } from "config/types/entidad";

interface getEntidadesParams {
	busqueda?: string;
	codEB?: string;
	grupoVisitador?: string;
	visitador?: string;
	tipoDeActividad?: string;
	existenAnomalias?: string;
	estadoEvaluacion?: string;
	fechaInicio?: string;
	fechaFinal?: string;
	page?: number;
	order?: string;
}

export const getEntidades: CustomAxiosRequest<getEntidadesParams, ApiPaginatedResponse<EntidadOutput[]>> = (params) => {
	const url = `${envConfig.API_URL}/entidades`;
	const config: AxiosRequestConfig<EntidadOutput[]> = {
		method: "GET",
		url,
		params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

export const getEntidadesStats: CustomAxiosRequest<getEntidadesParams, EntidadesStatsOutput> = (params) => {
	const url = `${envConfig.API_URL}/entidades/stats`;
	const config: AxiosRequestConfig<EntidadesStatsOutput> = {
		method: "GET",
		url,
		params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

export const getPoblacion: CustomAxiosRequest<void, string[]> = () => {
	const url = `${envConfig.API_URL}/poblacion`;
	const config: AxiosRequestConfig<string[]> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};
