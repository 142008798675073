import React from "react";

import { Text, Flex, Skeleton, GridItem, Box } from "@chakra-ui/react";

import { CardContainer } from "common/components/CardContainer";
import { SearchInput } from "common/components/SearchInput";
import { SidebarHeader } from "common/components/SidebarHeader";
import { THUNK_STATE } from "config/types";
import { actions as portfolioActions } from "store/entidades/reducer";
import {
	getEntidadesThunk,
	getEntidadesStatsThunk,
	getGrupoVisitadoresThunk,
	getVisitadoresThunk,
} from "store/entidades/thunks";
import { actions as entidadesActions } from "store/evaluaciones/reducer";
import { useAppDispatch, useAppSelector } from "store/store";

import { MultiColorBar } from "./MulticolorBar";
import { FiltrosSelect } from "../FiltrosSelect/FiltrosSelect";

export const Sidebar: React.FC = () => {
	const { data: entidadesStats, thunkState: entidadesStatsTS } = useAppSelector(
		(state) => state.entidades.entidadesStats,
	);
	const dispatch = useAppDispatch();

	const requestFinished = entidadesStatsTS === THUNK_STATE.FULFILLED;

	React.useEffect(() => {
		dispatch(getEntidadesThunk({}));
		dispatch(getEntidadesStatsThunk({}));
		dispatch(getGrupoVisitadoresThunk());
		dispatch(entidadesActions.resetEvaluacionesSlice());
		dispatch(portfolioActions.resetFilterSelection());
		dispatch(getVisitadoresThunk({}));
	}, []);

	return (
		<GridItem colSpan={1} display={{ sm: "none", md: "block" }}>
			<CardContainer minW="200px" p="1rem 1.5em">
				<SidebarHeader />
				<SearchInput />
				<Box maxH="65vh" overflowY="auto" overflowX="hidden">
					<Text mt="2rem" textStyle="body2" color="black" fontWeight={500} w="100%">
						Estado de las evaluaciones
					</Text>
					<Flex direction="column">
						<Skeleton isLoaded={requestFinished} m="1rem 0rem">
							{entidadesStats && <MultiColorBar stats={entidadesStats} />}
						</Skeleton>
					</Flex>
					<FiltrosSelect />
				</Box>
			</CardContainer>
		</GridItem>
	);
};
