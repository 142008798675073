import axios, { AxiosRequestConfig } from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { grupoVisitadoresOutput, visitadorOutput } from "config/types/visitadores";

export const getGrupoVisitadores: CustomAxiosRequest<null, grupoVisitadoresOutput[]> = () => {
	const url = `${envConfig.API_URL}/grupoVisitadores/`;
	const config: AxiosRequestConfig<grupoVisitadoresOutput> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};

export interface getVisitadoresParams {
	grupoVisitadores?: string;
}

export const getVisitadores: CustomAxiosRequest<getVisitadoresParams, visitadorOutput[]> = (params) => {
	const { grupoVisitadores } = params;
	const url = `${envConfig.API_URL}/visitadores`;
	const config: AxiosRequestConfig<visitadorOutput[]> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		params: { grupoVisitadores },
	};
	return axios(config);
};
