import React, { ChangeEvent } from "react";

import {
	Box,
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Icon,
	Input,
	Link,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FiFile, FiTrash2, FiArrowUp } from "react-icons/fi";
import { useParams } from "react-router";

import { getGoogleDriveFileListThunk } from "store/evaluaciones/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

interface GoogleDriveDocumentsProps {
	isOpen: boolean;
	onClose: () => void;
}

export const GoogleDriveDocuments: React.FC<GoogleDriveDocumentsProps> = (props) => {
	const { codEB } = useParams<{ codEB: string }>();
	const { isOpen, onClose } = props;

	const [attachedFiles, setAttachedFiles] = React.useState<File[]>([]);
	const [loadingFile, setLoadingFile] = React.useState<boolean>(false);

	const documentsFromGoogleDrive = useAppSelector((state) => state.evaluaciones.googleDrive.data.files);
	const folderId = useAppSelector((state) => state.evaluaciones.googleDrive.data.documentsFolder?.id);
	const googleAccessToken = useAppSelector((state) => state.evaluaciones.googleDrive.data.authToken?.access_token);

	const fileInputRef = React.useRef<HTMLInputElement>(null);

	const toast = useToast();
	const dispatch = useAppDispatch();

	const triggerDownloadFile = async (fileId: string) => {
		try {
			const downloadUrl = `https://drive.google.com/file/d/${fileId}/view`;
			const downloadLink = document.createElement("a");
			downloadLink.href = downloadUrl;
			downloadLink.target = "_blank";
			downloadLink.style.display = "none";
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		} catch (err) {
			toast({
				title: "Error",
				description: "Hubo un error al descargar el archivo",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleUploadFile = async () => {
		setLoadingFile(true);

		const successfulUploads: string[] = [];
		const failedUploads: string[] = [];

		for (const file of attachedFiles) {
			const uploadUrl = "https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable";

			const headers = {
				Authorization: `Bearer ${googleAccessToken}`,
				"Content-Type": "application/json; charset=UTF-8",
				"X-Upload-Content-Type": file.type,
				"X-Upload-Content-Length": file.size,
			};

			const metadata = {
				name: file.name,
				mimeType: file.type,
				parents: [folderId],
			};

			try {
				const uploadRequest = axios.post(uploadUrl, metadata, { headers: headers });
				const uploadResponse = await uploadRequest;

				const putUrl = uploadResponse.headers.location;
				const putHeaders = {
					"Content-Type": file.type,
					"Content-Length": file.size,
				};

				const putRequest = axios.put(putUrl, file, { headers: putHeaders });
				const putResponse = await putRequest;

				if (putResponse.status === 200) {
					successfulUploads.push(file.name);
				} else {
					failedUploads.push(file.name);
				}
			} catch (error) {
				failedUploads.push(file.name);
			}
		}

		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
		setAttachedFiles([]);

		if (successfulUploads.length > 0) {
			toast({
				title: "Success",
				description: `Se subio correctamente el archivo: ${successfulUploads.join(", ")}`,
				status: "success",
				duration: 3000,
				isClosable: true,
			});
		}

		if (failedUploads.length > 0) {
			toast({
				title: "Error",
				description: `Fallo al subir el archivo: ${failedUploads.join(", ")}`,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}

		if (folderId)
			setTimeout(() => {
				dispatch(getGoogleDriveFileListThunk({ folderId: folderId }));
			}, 2000);

		setLoadingFile(false);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const selectedFiles = Array.from(e.target.files);
			setAttachedFiles([...attachedFiles, ...selectedFiles]);
		}
	};

	React.useEffect(() => {
		if (folderId && codEB) {
			dispatch(getGoogleDriveFileListThunk({ folderId: folderId }));
		}
	}, [folderId, codEB]);

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent containerProps={{ pt: "10rem" }} pb=".5rem">
				<ModalHeader m=".5rem 0"></ModalHeader>
				<ModalCloseButton />
				<Divider />
				<ModalBody pb={5}>
					<Grid templateColumns="repeat(2, 1fr)" gap={5} mt="1rem">
						<GridItem colSpan={1} h="100%" display="flex" flexDirection="column">
							<Text textStyle="body3" color="black" mb="1.5rem">
								Subir documentos
							</Text>
							<Flex flexDirection="column" flexGrow={1}>
								<Input
									type="file"
									name="file"
									multiple
									ref={fileInputRef}
									onChange={handleFileChange}
									style={{ display: "none" }}
								/>
								{attachedFiles.map((file, index) => (
									<Flex key={index} alignItems="center" justifyContent="center" flexDirection="row" mb="1rem">
										<Text textStyle="body3" color="gray.600">
											{file.name}
										</Text>
										<Icon
											as={FiTrash2}
											fontSize="1rem"
											color="gray.500"
											strokeWidth="1.5"
											ml="1rem"
											_hover={{ color: "gray.800", cursor: "pointer" }}
											onClick={() => {
												const updatedFiles = [...attachedFiles];
												updatedFiles.splice(index, 1);
												setAttachedFiles(updatedFiles);
											}}
										/>
									</Flex>
								))}
								<Button
									colorScheme="gray"
									onClick={() => fileInputRef.current?.click()}
									width="100%"
									mb="1rem"
									isDisabled={loadingFile || !folderId}
								>
									Seleccionar documentos
								</Button>
							</Flex>
							<Button
								colorScheme="blue"
								aria-label="Subir archivos"
								type="submit"
								onClick={handleUploadFile}
								w="100%"
								mt="auto"
								isLoading={loadingFile}
								isDisabled={loadingFile || attachedFiles.length === 0 || !folderId}
								rightIcon={<FiArrowUp />}
							>
								Subir documentos
							</Button>
						</GridItem>
						<GridItem colSpan={1}>
							<Text textStyle="body3" color="black" mb="1.5rem">
								Documentos subidos
							</Text>
							<Box maxH="15rem" overflowY="scroll">
								<List>
									{documentsFromGoogleDrive &&
										documentsFromGoogleDrive.map((file, i) => (
											<ListItem key={i} alignItems="center" mt=".5rem" display="flex">
												<Icon as={FiFile} fontSize="1rem" color="gray.800" strokeWidth="1.25" mr=".5rem" />
												<Link fontSize="body3" color="blue.600" overflow="hidden" mb=".25rem">
													<Text
														maxWidth="12rem"
														overflow="hidden"
														textOverflow="ellipsis"
														whiteSpace="nowrap"
														textStyle="body3"
														onClick={() => triggerDownloadFile(file.id)}
													>
														{file.name}
													</Text>
												</Link>
											</ListItem>
										))}
								</List>
							</Box>
						</GridItem>
					</Grid>
				</ModalBody>
				{/* Pending to confirm if this is needed */}
				{/* <ModalFooter>
					<Flex flexGrow={1} alignItems="center" justifyContent="center">
						<Link
							href={`https://drive.google.com/drive/u/1/folders/${folderId}`}
							isExternal
							fontSize="body3"
							color="blue.600"
							overflow="hidden"
						>
							<Text maxWidth="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" textStyle="body3">
								Ver carpeta en Google Drive
							</Text>
						</Link>
					</Flex>
				</ModalFooter> */}
			</ModalContent>
		</Modal>
	);
};
