import React from "react";

import { Box, FormControl, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { THUNK_STATE } from "config/types";
import { converDate } from "helpers/converDate";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

const Seccion00: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_0);
	const dataAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const { thunkState } = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada);
	const permisosUsuario = useAppSelector((state) => state.usuario.permissions.data);

	const selectorDisabled = React.useMemo(() => {
		if (permisosUsuario[0]?.cargo_funcion === "Solo lectura") {
			return true;
		} else if (
			(evaluacion && evaluacion.estado_evaluacion === "En proceso") ||
			(evaluacion && evaluacion.estado_evaluacion === "Programada") ||
			(evaluacion && evaluacion.estado_evaluacion === "Adelantada") ||
			(evaluacion && evaluacion.estado_evaluacion === "Rechazada")
		)
			return false;
		return true;
	}, [evaluacion]);

	const selectorValue = React.useMemo(() => {
		if (!seccionDataActual) return false;
		return seccionDataActual.seccion_0_completada === 1;
	}, [seccionDataActual]);

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(1);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const capFirst = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	return (
		<Box>
			<Grid gridTemplateColumns="repeat(2, 1fr)">
				{evaluacion?.fecha_adelantada && (
					<React.Fragment>
						<GridItem colSpan={2}>
							<Text textStyle={"body2"} color="black" fontWeight="bold" marginBottom="1.5rem" marginTop="1rem">
								Visita adelantada
							</Text>
						</GridItem>
						<GridItem mb="1.5rem">
							<Text textStyle="body3" color="gray.600">
								Programación inicial:
							</Text>
							<Text as="span" color="black" textStyle="body3">
								{capFirst(
									DateTime.fromISO(String(evaluacion?.fecha_visita_programada)).setLocale("es").toFormat("LLL. yyyy"),
								)}
							</Text>
							<Text textStyle="body3" color="gray.600">
								Nueva programación:
							</Text>
							<Text as="span" color="black" textStyle="body3">
								{capFirst(DateTime.fromISO(String(evaluacion?.fecha_adelantada)).setLocale("es").toFormat("LLL. yyyy"))}
							</Text>
						</GridItem>
						<GridItem>
							<Text textStyle="body3" color="gray.600">
								Motivo del adelanto:
							</Text>
							<Text textStyle="body3" color="black">
								{evaluacion?.motivo_adelanto}
							</Text>
						</GridItem>
					</React.Fragment>
				)}
				<SeccionHeader seccion="00" />

				<GridItem>
					<VStack alignItems="flex-start">
						<React.Fragment>
							<Text textStyle="body3" color="gray.600">
								Fecha validación anterior:
							</Text>
							<Text textStyle="body3">{converDate(dataAnterior?.fecha_validada)}</Text>
						</React.Fragment>
					</VStack>
					<VStack alignItems="flex-start">
						<React.Fragment>
							<Text textStyle="body3" color="gray.600" marginTop="1.5rem">
								Anomalías:
							</Text>
							<Text textStyle="body3">{dataAnterior?.existen_anomalias ? "Con anomalías" : "Sin anomalías"}</Text>
						</React.Fragment>
					</VStack>
				</GridItem>
				<GridItem>
					<VStack alignItems="flex-start">
						<Text textStyle="body3" color="gray.600">
							Resumen del visitador:
						</Text>
						<Text textStyle="body3">{dataAnterior?.ultima_actualizacion_visitador}</Text>
						<Text textStyle="body3">{dataAnterior?.seccion_10?.resumen_visitador}</Text>
					</VStack>
					<VStack alignItems="flex-start">
						<React.Fragment>
							<Text textStyle="body3" color="gray.600">
								Resumen del supervisor:
							</Text>
							<Text textStyle="body3">{dataAnterior?.nombre_supervisor}</Text>
							<Text textStyle="body3">{dataAnterior?.resumen_supervisor}</Text>
						</React.Fragment>
					</VStack>
					<VStack alignItems="flex-start">
						<React.Fragment>
							<Text textStyle="body3" color="gray.600">
								Resumen del administrador:
							</Text>
							<Text textStyle="body3">{dataAnterior?.nombre_administrador}</Text>
							<Text textStyle="body3">{dataAnterior?.resumen_administrador}</Text>
						</React.Fragment>
					</VStack>
				</GridItem>
			</Grid>
			<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
				{THUNK_STATE.FULFILLED === thunkState && (
					<SeccionSwitch
						hasNextSection
						seccionCompletada={selectorValue}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_0_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				)}
			</FormControl>
		</Box>
	);
};

export default Seccion00;
