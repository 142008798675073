import { createAsyncThunk } from "@reduxjs/toolkit";

import {
	deleteDocumento,
	getDocumentos,
	getGoogleDriveFileList,
	getGoogleFolderId,
	postDocumento,
} from "api/documents";
import {
	actualizarCampoEvaluacion,
	getEvaluacion,
	getEvaluacionAnterior,
	getEvaluaciones,
	patchCambiarEstadoEvaluacion,
	postComentario,
	postProgramarEvaluacion,
	putAdelantarEvaluacion,
} from "api/evaluacion";
import { postGoogleToken } from "api/google";
import { ISODate } from "common/interfaces/ISODate";
import { ComentarioInput } from "config/types/comentario";
import { DocumentoInput } from "config/types/documento";
import { CambiarEstadoEvaluacionInput } from "config/types/evaluacion";
import { SLICE_NAMES } from "store/constant";

import { EvaluacionesSlice } from "./reducer";

type getEvaluacionesParams = {
	codEB: string;
};
export const getEvaluacionesThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getEvaluaciones`,
	async (payload: getEvaluacionesParams) => {
		const response = await getEvaluaciones(payload);
		return response.data;
	},
);

type getEvaluacionThunkParams = {
	codEB: string;
	numeroEvaluacion: string;
};
export const getEvaluacionThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getEvaluacionAnterior`,
	async (payload: getEvaluacionThunkParams) => {
		const response = await getEvaluacion(payload);
		return response.data;
	},
);

type getEvaluacionAnteriorThunkParams = {
	codEB: string;
	numeroEvaluacion: number;
};
export const getEvaluacionAnteriorThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getEvaluacion`,
	async (payload: getEvaluacionAnteriorThunkParams) => {
		const response = await getEvaluacionAnterior(payload);
		return response.data;
	},
);

type putAdelantarEvaluacionParams = {
	codEB: string;
	data: { motivo: string; fecha: ISODate; existenAnomalias: boolean };
};
export const putAdelantarEvaluacionThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/putAdelantarEvaluacion`,
	async (payload: putAdelantarEvaluacionParams, thunkApi) => {
		const response = await putAdelantarEvaluacion(payload);
		await thunkApi.dispatch(
			getEvaluacionThunk({ codEB: payload.codEB, numeroEvaluacion: String(response.data.numero_evaluacion) }),
		);
		return response.data;
	},
);

type patchCambiarEstadoEvaluacionParams = {
	codEB: string;
	data: CambiarEstadoEvaluacionInput;
};

export const patchCambiarEstadoEvaluacionThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/patchCambiarEstadoEvaluacion`,
	async (payload: patchCambiarEstadoEvaluacionParams, thunkApi) => {
		const response = await patchCambiarEstadoEvaluacion(payload);
		await thunkApi.dispatch(
			getEvaluacionThunk({ codEB: payload.codEB, numeroEvaluacion: String(response.data.numero_evaluacion) }),
		);
		return response.data;
	},
);

export const actualizarCampoEvaluacionThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/actualizarCampoEvaluacionThunk`,
	async (payload: Parameters<typeof actualizarCampoEvaluacion>[0], thunkApi) => {
		const response = await actualizarCampoEvaluacion(payload);
		const evaluacion = (thunkApi.getState() as Record<string, EvaluacionesSlice>).evaluaciones.evaluacionSeleccionada;
		await thunkApi.dispatch(
			getEvaluacionThunk({ codEB: payload.codEB, numeroEvaluacion: String(evaluacion.data?.numero_evaluacion) }),
		);
		return response.data;
	},
);

type postComentarioThunkParams = {
	codEB: string;
	comentario: ComentarioInput;
};
export const postComentarioThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/postComentario`,
	async (payload: postComentarioThunkParams) => {
		const response = await postComentario(payload);
		return response.data;
	},
);

type postProgramarEvaluacionParams = {
	codeEB: string;
	nombre_eb: string;
	fecha_visita: string;
};

export const postProgramarEvaluacionThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/postProgramarEvaluacion`,
	async (payload: postProgramarEvaluacionParams) => {
		const response = await postProgramarEvaluacion(payload);
		return response.data;
	},
);

// Documentos
type getDocumentosParams = {
	codigoEvaluacion: string;
	numeroEvaluacion: string;
};

export const getDocumentosThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getDocumentos`,
	async (payload: getDocumentosParams) => {
		const response = await getDocumentos(payload);
		return response.data;
	},
);

type postDocumentoParams = {
	data: DocumentoInput;
};

export const postDocumentoThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/postDocumentos`,
	async (payload: postDocumentoParams) => {
		const response = await postDocumento(payload);
		return response.data;
	},
);

type DeleteDocumentoParams = {
	idDocumento: string;
};

export const deleteDocumentoThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/deleteDocumentos`,
	async (payload: DeleteDocumentoParams, thunkApi) => {
		const { idDocumento } = payload;
		const evaluacion = (thunkApi.getState() as Record<string, EvaluacionesSlice>).evaluacionSeleccionada;
		const response = await deleteDocumento({ idDocumento: idDocumento });
		await thunkApi.dispatch(
			getDocumentosThunk({
				codigoEvaluacion: String(evaluacion.evaluacionSeleccionada.data?.cod_eb) || "",
				numeroEvaluacion: String(evaluacion.evaluacionSeleccionada.data?.numero_evaluacion) || "",
			}),
		);
		return response.data;
	},
);

export type GetDocumentoParams = {
	idEntidad: string;
};

export const getGoogleFolderIdThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getGoogleFolderId`,
	async (payload: GetDocumentoParams) => {
		const response = await getGoogleFolderId(payload);
		return response.data;
	},
);

export type GetGoogleDriveFileListParams = {
	folderId: string;
};

export const getGoogleDriveFileListThunk = createAsyncThunk(
	`${SLICE_NAMES.EVALUACIONES}/getGoogleDriveFileList`,
	async (payload: GetGoogleDriveFileListParams) => {
		const response = await getGoogleDriveFileList(payload);
		return response.data;
	},
);

export const postGoogleTokenThunk = createAsyncThunk(`${SLICE_NAMES.EVALUACIONES}/postGoogleToken`, async () => {
	const response = await postGoogleToken();
	return response.data;
});
