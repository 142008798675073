import React from "react";

import { Flex, FlexProps, Img, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import bamWhiteLogo from "assets/images/bam_main_logo_white.svg";
import { useAppSelector } from "store/store";

import { UserInfo } from "./UserInfo";

type TopbarProps = FlexProps;

export const Topbar: React.FC<TopbarProps> = (props) => {
	const userLogged = useAppSelector((state) => state.usuario.datosDeUsuario.email);
	const isProdEnv = window.location.href.includes("bamvisitas.dhub.arup.com");

	return (
		<Flex
			width="100%"
			height="3rem"
			backgroundColor={isProdEnv ? "#224C7C" : "gray.600"}
			alignItems="center"
			justifyContent="space-between"
			zIndex="3"
			{...props}
		>
			<React.Fragment>
				{userLogged && (
					<Flex as={Link} to={"/"} alignItems="center">
						<Img src={bamWhiteLogo} alt="BAM Logo" objectFit="cover" height="2.75rem" />
						<Flex alignItems="center">
							<Text ml="1rem" color="white" textStyle="heading1">
								Gestor de visitas
							</Text>
							{!isProdEnv && (
								<Text ml=".25rem" color="white" textStyle="heading1">
									(Entorno de pruebas)
								</Text>
							)}
						</Flex>
					</Flex>
				)}
				<Flex>
					<UserInfo />
				</Flex>
			</React.Fragment>
		</Flex>
	);
};
