import { EnvConfigurationType } from ".";

export const devConfig: EnvConfigurationType = {
	ENV_NAME: "development",
	AWS_REGION: "eu-west-1",
	API_URL: "https://api.dev.bam.dhub.arup.com",
	PROJECT_REF: "bam",
	GOOGLE_CLIENT_ID: "215060160410-4irhu6gv8qmije9927fuk6iuuhlsbcl4.apps.googleusercontent.com",
	MAPBOX_TOKEN: "pk.eyJ1IjoiZGh1YiIsImEiOiJjbDJoY2JxcHUwYm1jM2puemZvcWdreWRiIn0.tm3OKMghnNvUwPHLob-AYw",
	RELEASE: process.env.REACT_APP_GIT_SHA?.slice(0, 7) || "development",
};
