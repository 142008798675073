import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { envConfig } from "config/env";

import entidadesReducer from "./entidades/reducer";
import evaluacionesReducer from "./evaluaciones/reducer";
import usuarioReducer from "./usuario/reducer";

export const store = configureStore({
	reducer: {
		usuario: usuarioReducer,
		evaluaciones: evaluacionesReducer,
		entidades: entidadesReducer,
	},
	devTools: envConfig.ENV_NAME !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
