import React from "react";

import { Box, Divider, FormControl, FormLabel, Grid, GridItem, HStack, Select, Text, VStack } from "@chakra-ui/react";
import debounce from "lodash.debounce";

import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import InputField from "common/components/InputField/InputField";
import { formatNumber } from "helpers/formatNumber";
import { useAppSelector } from "store/store";

import { ISeccionProps } from "./types";
import { useAutoInputSend } from "./useAutoInputSend";
import { useInputDiffDetector } from "./useInputDiffDetector";
import { useIsDisabled } from "./useIsDisabled";
import { SeccionHeader } from "../SeccionHeader/SeccionHeader";
import SeccionSwitch from "../SeccionSwitch/SeccionSwitch";

enum TIPO_TRANSPORTE {
	Propio = "Propio",
	Alquilado = "Alquilado",
	Prestado = "Prestado",
}

enum TITULAR_LOCALES {
	Alquiler = "Alquiler",
	Propiedad = "Propiedad",
	Usufructo = "Usufructo",
}

const TITULAR_LOCALES_OPCIONES = ["Alquiler", "Propiedad", "Usufructo"];

const TIPO_TRANSPORTE_OPCIONES = ["Propio", "Alquilado", "Prestado"];

const Seccion08: React.FC<ISeccionProps> = (props) => {
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);
	const evaluacionAnterior = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionadaAnterior.data);
	const seccionDataActual = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data?.seccion_8);
	const printView = useAppSelector((state) => state.usuario.printView);

	const isDisabled = useIsDisabled(evaluacion) || seccionDataActual?.seccion_8_completada === 1;
	const selectorDisabled = useIsDisabled(evaluacion);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const changeDetector = useInputDiffDetector(evaluacionAnterior, evaluacion!, "seccion_8");

	const handleNextSectionNavigation = () => props.setSeccionActiva && props.setSeccionActiva(9);

	const debouncedChangeHandler = React.useMemo(
		() =>
			debounce(
				(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
					sendInputValue(event.target.name, event.target.value),
				500,
			),
		[seccionDataActual],
	);
	const [titularLocales, setTitularLocales] = React.useState<TITULAR_LOCALES | undefined>(
		seccionDataActual?.titular_locales as TITULAR_LOCALES | undefined,
	);

	const [tipoTransporte, setTipoTransporte] = React.useState<TIPO_TRANSPORTE | undefined>(
		seccionDataActual?.tipo_de_transporte as TIPO_TRANSPORTE | undefined,
	);

	const checkboxesList = [
		{
			label: "¿Existe un registro de entradas/salidas para controlar existencias en el almacén?",
			name: "existe_registro_entradas_salidas_existencias",
		},
		{
			label: "¿Los documentos sobre entregas/salidas se guardan administrativamente durante 5 años?",
			name: "registro_entradas_salidas_existencias_guardados_5_años",
		},
		{
			label: "¿La información de los beneficiarios se guarda de manera confidencial?",
			name: "confidencialidad_datos_beneficiarios",
		},
		{
			label: "¿La EB cumple con la LOPD?",
			name: "cumplen_lopd",
		},
		{
			label: "¿Realizan renovación de expedientes? ",
			name: "realizan_renovacion_expedientes",
		},
	];

	return (
		<Box>
			<SeccionHeader seccion="08" />
			<Box width="100%">
				<Divider marginBottom="1.5rem" color="gray.200" />
				<Text textStyle="body2" color="black" fontWeight="700">
					Almacén
				</Text>
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={2} rowStart={1} rowSpan={1}>
						<FormControl>
							<FormLabel>Locales a disposición de la entidad benéfica por:</FormLabel>
							<Select
								value={titularLocales}
								name="titular_locales"
								onChange={(event) => {
									const newValue = event.target.value as TITULAR_LOCALES;
									setTitularLocales(newValue);
									debouncedChangeHandler(event);
								}}
								isDisabled={isDisabled}
								placeholder="Selecciona una opción"
								{...changeDetector("titular_locales")}
							>
								{TITULAR_LOCALES_OPCIONES.map((opcion) => (
									<option key={opcion} value={opcion}>
										{opcion}
									</option>
								))}
							</Select>
						</FormControl>
					</GridItem>
					<GridItem colSpan={1} rowStart={1} rowSpan={1}>
						<FormControl>
							<FormLabel>
								Superficie (m<sup>2</sup>)
							</FormLabel>
							<InputField
								name="superficie_del_almacen"
								defaultValue={seccionDataActual?.superficie_del_almacen}
								{...changeDetector("superficie_del_almacen")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem colSpan={1} rowStart={2} rowSpan={1}>
						{seccionDataActual?.limpieza !== undefined && (
							<FormControl>
								<FormLabel>¿Limpieza?</FormLabel>

								<CheckboxGroup
									name="limpieza"
									defaultValue={seccionDataActual?.limpieza}
									onChange={(name, value) => sendInputValue(name, value)}
									changed={changeDetector("limpieza")}
									isDisabled={isDisabled}
								/>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={1} rowStart={2} rowSpan={1}>
						{seccionDataActual?.estanterias !== undefined && (
							<FormControl>
								<FormLabel>¿Estanterías?</FormLabel>
								<CheckboxGroup
									name="estanterias"
									defaultValue={seccionDataActual?.estanterias}
									onChange={(name, value) => sendInputValue(name, value)}
									changed={changeDetector("estanterias")}
									isDisabled={isDisabled}
								/>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={1} rowStart={2} rowSpan={1}>
						{seccionDataActual?.palets !== undefined && (
							<FormControl>
								<FormLabel>¿Palets?</FormLabel>
								<CheckboxGroup
									name="palets"
									defaultValue={seccionDataActual?.palets}
									onChange={(name, value) => sendInputValue(name, value)}
									changed={changeDetector("palets")}
									isDisabled={isDisabled}
								/>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={1} rowStart={3} rowSpan={1}>
						{seccionDataActual?.tiene_frigorificos !== undefined && (
							<FormControl>
								<FormLabel>¿Tiene frigorífico?</FormLabel>
								<CheckboxGroup
									name="tiene_frigorificos"
									defaultValue={seccionDataActual?.tiene_frigorificos}
									onChange={(name, value) => sendInputValue(name, value)}
									changed={changeDetector("tiene_frigorificos")}
									isDisabled={isDisabled}
								/>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={1} rowStart={3} rowSpan={1}>
						<FormControl>
							<FormLabel>Nº de frigoríficos</FormLabel>
							<InputField
								name="numero_frigorificos"
								defaultValue={seccionDataActual?.numero_frigorificos}
								{...changeDetector("numero_frigorificos")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
					<GridItem colSpan={1} rowStart={4} rowSpan={1}>
						{seccionDataActual?.tiene_congeladores !== undefined && (
							<FormControl>
								<FormLabel>¿Tiene congelador?</FormLabel>
								<CheckboxGroup
									name="tiene_congeladores"
									defaultValue={seccionDataActual?.tiene_congeladores}
									onChange={(name, value) => sendInputValue(name, value)}
									changed={changeDetector("tiene_congeladores")}
									isDisabled={isDisabled}
								/>
							</FormControl>
						)}
					</GridItem>
					<GridItem colSpan={1} rowStart={4} rowSpan={1}>
						<FormControl>
							<FormLabel>Nº de congeladores</FormLabel>
							<InputField
								name="numero_congeladores"
								defaultValue={seccionDataActual?.numero_congeladores}
								{...changeDetector("numero_congeladores")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
				<Divider m="1.5rem 0" color="gray.200" />
				<Text textStyle="body2" color="black" marginBottom="1.5rem" fontWeight="700">
					Transporte
				</Text>
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem">
					<GridItem colSpan={2}>
						<FormControl>
							<FormLabel>Tipo transporte</FormLabel>
							<Select
								value={tipoTransporte}
								name="tipo_de_transporte"
								onChange={(event) => {
									const newValue = event.target.value as TIPO_TRANSPORTE;
									setTipoTransporte(newValue);
									debouncedChangeHandler(event);
								}}
								isDisabled={isDisabled}
								placeholder="Selecciona una opción"
								{...changeDetector("tipo_de_transporte")}
							>
								{TIPO_TRANSPORTE_OPCIONES.map((opcion) => (
									<option key={opcion} value={opcion}>
										{opcion}
									</option>
								))}
							</Select>
						</FormControl>
					</GridItem>
					<GridItem colSpan={2} />
					<GridItem>
						{seccionDataActual?.transporte_refrigerado !== undefined && (
							<FormControl>
								<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
									<FormLabel flex={1}>¿Transporte refrigerado?</FormLabel>
									<CheckboxGroup
										name="transporte_refrigerado"
										defaultValue={seccionDataActual?.transporte_refrigerado}
										onChange={(name, value) => sendInputValue(name, value)}
										changed={changeDetector("transporte_refrigerado")}
										isDisabled={isDisabled}
									/>
								</VStack>
							</FormControl>
						)}
					</GridItem>
					<GridItem>
						<FormControl>
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel>Capacidad transporte (kg)</FormLabel>
								<InputField
									name="capacidad_de_transporte"
									defaultValue={formatNumber(seccionDataActual?.capacidad_de_transporte)}
									{...changeDetector("capacidad_de_transporte")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel>
									Capacidad frigorífico (m<sup>3</sup>)
								</FormLabel>
								<InputField
									name="m3_frigorifico"
									defaultValue={formatNumber(seccionDataActual?.m3_frigorifico)}
									{...changeDetector("m3_frigorifico")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
					<GridItem>
						<FormControl>
							<VStack alignItems="flex-start" justifyContent="flex-start" height="100%">
								<FormLabel>
									Capacidad congelador (m<sup>3</sup>)
								</FormLabel>
								<InputField
									name="m3_congelador"
									defaultValue={formatNumber(seccionDataActual?.m3_congelador)}
									{...changeDetector("m3_congelador")}
									isDisabled={isDisabled}
								/>
							</VStack>
						</FormControl>
					</GridItem>
				</Grid>
				<VStack alignItems="flex-start" spacing="1.19rem" style={{ pageBreakInside: "avoid" }}>
					<Text textStyle="body2" color="black" fontWeight="700">
						Documentación
					</Text>
					{checkboxesList.map((checkboxItem, index) => (
						<FormControl key={index}>
							<HStack width="100%">
								<FormLabel flex={1}>{checkboxItem.label}</FormLabel>
								<Box>
									<CheckboxGroup
										name={checkboxItem.name}
										defaultValue={seccionDataActual?.[checkboxItem.name]}
										changed={changeDetector(checkboxItem.name)}
										onChange={(name, value) => sendInputValue(name, value)}
										isDisabled={isDisabled}
									/>
								</Box>
							</HStack>
						</FormControl>
					))}
				</VStack>
				<Grid gridTemplateColumns="repeat(4, 1fr)" gap="2rem" marginTop="1.5rem" style={{ pageBreakInside: "avoid" }}>
					<GridItem colSpan={4}>
						<FormControl>
							<FormLabel>¿Con qué frecuencia los renuevan?</FormLabel>
							<InputField
								name="periodo_de_renovacion_expedientes"
								placeholder="Texto"
								defaultValue={seccionDataActual?.periodo_de_renovacion_expedientes}
								{...changeDetector("periodo_de_renovacion_expedientes")}
								isDisabled={isDisabled}
							/>
						</FormControl>
					</GridItem>
				</Grid>
			</Box>
			{!printView && (
				<FormControl display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
					<SeccionSwitch
						hasNextSection
						seccionCompletada={seccionDataActual?.seccion_8_completada === 1}
						nextSection={handleNextSectionNavigation}
						onSeccionCompletadaChange={(flag) => {
							sendInputValue("seccion_8_completada", flag ? "1" : "0");
						}}
						isDisabled={selectorDisabled}
					/>
				</FormControl>
			)}
		</Box>
	);
};

export default Seccion08;
