import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { THUNK_STATE } from "config/types";

import { EntidadesSlice } from "./reducer";
import {
	getEntidadesStatsThunk,
	getEntidadesThunk,
	getGrupoVisitadoresThunk,
	getPoblacionesThunk,
	getVisitadoresThunk,
} from "./thunks";

export const getEntidadesExtraReducer = (builder: ActionReducerMapBuilder<EntidadesSlice>) => [
	builder.addCase(getEntidadesThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getEntidadesThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getEntidadesThunk.fulfilled, (state, action) => ({
		...state,
		entidades: {
			data: action.payload.data,
			pagination: action.payload.pagination,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const getEntidadesStatsExtraReducer = (builder: ActionReducerMapBuilder<EntidadesSlice>) => [
	builder.addCase(getEntidadesStatsThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getEntidadesStatsThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getEntidadesStatsThunk.fulfilled, (state, action) => ({
		...state,
		entidadesStats: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
		thunkState: THUNK_STATE.FULFILLED,
	})),
];

export const getGrupoVisitadoresExtraReducer = (builder: ActionReducerMapBuilder<EntidadesSlice>) => [
	builder.addCase(getGrupoVisitadoresThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getGrupoVisitadoresThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getGrupoVisitadoresThunk.fulfilled, (state, action) => ({
		...state,
		grupoVisitadores: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

export const getVisitadoresExtraReducer = (builder: ActionReducerMapBuilder<EntidadesSlice>) => [
	builder.addCase(getVisitadoresThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getVisitadoresThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getVisitadoresThunk.fulfilled, (state, action) => ({
		...state,
		visitadores: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];

export const getPoblacionesExtraReducer = (builder: ActionReducerMapBuilder<EntidadesSlice>) => [
	builder.addCase(getPoblacionesThunk.pending, (state) => ({
		...state,
		thunkState: THUNK_STATE.PENDING,
	})),
	builder.addCase(getPoblacionesThunk.rejected, (state) => ({
		...state,
		thunkState: THUNK_STATE.REJECTED,
	})),
	builder.addCase(getPoblacionesThunk.fulfilled, (state, action) => ({
		...state,
		poblaciones: {
			thunkState: THUNK_STATE.FULFILLED,
			data: action.payload,
		},
	})),
];
