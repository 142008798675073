export const correlacionesFormulario = {
	seccion01: {
		// nombre_eb: "Nombre",
		// tipo_de_actividad: "Categoría",
		// grupo_visitador: "Grupo visitadores",
		// grupo_homogeneo: "Grupo homogéneo",
		// status: "Situación",
		// cif: "CIF",
		// registro: "Registro",
		// tiene_estatutos: "Estatutos",
		// compromisos_mutuos: "Compromisos mutuos",
		direccion_actividad: "Dirección actividad",
		municipio_actividad: "Municipio",
		codpost_actividad: "C.P.",
		email: "Email general",
		nombre_responsable: "Nombre responsable",
		apellidos_responsable: "Apellidos responsable",
		telefono_actividad: "Teléfono",
		direccion_postal_direccion_actividad: "Dirección postal",
		direccion_postal_municipio_actividad: "Dirección postal municipio",
		direccion_postal_codpost_actividad: "Dirección postal C.P.",
		direccion_postal_telefono_actividad: "Dirección postal teléfono",
		cargo_responsable: "Cargo responsable",
		email_responsable: "Email responsable",
		telefono_responsable: "Teléfono responsable",
		// lopd_responsable: "Responsable LOPD responsable",
		nombre_contacto: "Nombre persona de contacto",
		apellidos_contacto: "Apellidos persona de contacto",
		cargo_contacto: "Cargo persona de contacto",
		email_contacto: "Email persona de contacto",
		telefono_contacto: "Teléfono persona de contacto",
		// lopd_contacto: "Responsable LOPD persona de contacto",
		// contacts: "Nombre contactos",
		otras_modificaciones: "Otras modificaciones",
	},
	seccion02: {
		beneficiarios_0_18_meses: "Nº de beneficiarios (0-18 meses)",
		beneficiarios_mas_de_18_meses: "Nº de beneficiarios (Mayores de 18 meses)",
		beneficiarios_totales: "Beneficiarios totales",
		dias_de_reparto: "Días de reparto",
		// meses_actividad_ano: "Nº meses actividad/año",
		// derivados_caritas: "Número de beneficiarios derivados de cáritas",
		// derivados_servicios_sociales: "Número de beneficiarios derivados de Servicios Sociales",
		// derivados_propia_entidad: "Número de beneficiarios derivados de la propia entidad",
		// sin_inf_derivados: "Número de beneficiarios derivados sin informe",
		// lugar_de_entrega_digno_confidencialidad_datos_beneficiarios:
		// 	"¿Los beneficiarios son recibidos en lugar digno respetando la confidencialidad cuando facilitan sus datos personales?",
		// tienen_certificados_de_necesidad:
		// 	"¿Tienen certificado de TS o equivalente que avale la necesidad de ayuda alimentaria de los beneficiarios?",
		// cumplen_no_discriminacion: "¿La EB cumple con el requisito de no discriminación?",
		// beneficiarios_aportan_dinero: "Los beneficiarios, ¿aportan algún dinero? (no por alimentos)",
		// eb_asegura_buen_estado_alimentos: "¿La EB asegura que los alimentos llegan en buen estado a los beneficiarios?",
		// orden_de_reparto: "Para recoger los alimentos, ¿se cita a los beneficiarios en algún orden?",
		// reparto_proporcional_a_familiares: "¿El reparto es proporcional al número de miembros familiares?",
		// reparto_gratuito: "¿Los beneficiarios reciben los alimentos gratuitamente?",
		// beneficiarios_firman: "¿Los beneficiarios firman las cantidades de alimentos de cada entrega?",
	},
	seccion03: {
		// porcentaje_propuesto_desayunos: "Porcentajes originales desayunos",
		// porcentaje_propuesto_comidas: "Porcentajes originales comidas",
		// porcentaje_propuesto_meriendas: "Porcentajes originales meriendas",
		// porcentaje_propuesto_cenas: "Porcentajes originales cenas",
		// porcentaje_desayunos: "Porcentajes modificados desayunos",
		// porcentaje_comidas: "Porcentajes modificados comidas",
		// porcentaje_meriendas: "Porcentajes modificados meriendas",
		// porcentaje_cenas: "Porcentajes modificados cenas",
		// numero_desayunos: "Número de desayunos que se reparten",
		// numero_comidas: "Número de comidas que se reparten",
		// numero_meriendas: "Número de meriendas que se reparten",
		// numero_cenas: "Número de cenas que se reparten",
		// dias_semana_desayunos: "Nº días/semana Desayunos",
		// dias_semana_comidas: "Nº días/semana Comidas",
		// dias_semana_meriendas: "Nº días/semana Meriendas",
		// dias_semana_cenas: "Nº días/semana Cenas",
		// unidades_desayuno: "Personas equivalentes desayunos",
		// unidades_comida: "Personas equivalentes comidas",
		// unidades_merienda: "Personas equivalentes meriendas",
		// unidades_cena: "Personas equivalentes cenas",
	},
	seccion04: {
		// cong_almacen: "Congelados almacén",
		// cong_1er_turno: "Congelados turno 1",
		// cong_2do_turno: "Congelados turno 2",
		// cong_3er_turno: "Congelados turno 3",
		// cong_4to_turno: "Congelados turno 4",
		// mm_almacen: "Mercamadrid almacén",
		// mm_1er_turno: "Mercamadrid turno 1",
		// mm_2do_turno: "Mercamadrid turno 2",
		// mm_3er_turno: "Mercamadrid turno 3",
		// mm_4to_turno: "Mercamadrid turno 4",
		// mm_5t0_turno: "Mercamadrid turno 5",
		// fyh_almacen: "Frutas y hortalizas almacén",
		// fyh_1er_turno: "Frutas y hortalizas turno 1",
		// fyh_2do_turno: "Frutas y hortalizas turno 2",
		// fyh_3er_turno: "Frutas y hortalizas turno 3",
		// fyh_4to_turno: "Frutas y hortalizas turno 4",
		// canalseco_almacen: "Canal seco almacén",
		// canalseco_1er_turno: "Canal seco turno 1",
		// canalseco_2do_turno: "Canal seco turno 2",
		// canalseco_3er_turno: "Canal seco turno 3",
		// canalseco_4to_turno: "Canal seco turno 4",
		// comentarios_4: "Comentarios",
		// datos_correctos_4: "¿Los datos son correctos?",
	},
	seccion05: {
		// anoactcong: "Congelados alimentos",
		// anoactalcala: "Alcalá alimentos",
		// anoactsf: "San Fernando alimentos",
		// anoactalcorcon: "Alcorcón alimentos",
		// anoactmerca: "Mercamadrid alimentos",
		// anoactfyhalcala: "Alcalá frutas y hortalizas",
		// anoactfyhsf: "San Fernando frutas y hortalizas",
		// anoactfyhalcorcon: "Alcorcón frutas y hortalizas",
		// comentarios_5: "Comentarios",
		// datos_correctos_5: "¿Los datos son correctos?",
	},
	seccion06: {
		// kilos_ano_medios_propios: "Kg en 12 meses medios propios",
		// euros_ano_medios_propios: "€ en 12 meses medios propios",
		// kilos_ano_otras_aportaciones: "Kg en 12 meses otras aportaciones",
		// euros_ano_otras_aportaciones: "€ en 12 meses otras aportaciones",
		// total_ano_otras_aportaciones: "Total kg otras aportaciones otras aportaciones",
		// comentarios_6: "Comentarios",
		// datos_correctos_6: "¿Los datos son correctos?",
	},
	seccion07: {
		// kg_usuario_mes: "Kg / usuario / mes",
		// comentarios_7: "Comentarios",
		// datos_correctos_7: "¿Los datos son correctos?",
	},
	seccion08: {
		// titular_locales: "Locales a disposición de la EB por:",
		// superficie_del_almacen: "Superficie (m2)",
		// limpieza: "¿Limpieza?",
		// estanterias: "¿Estanterías?",
		// palets: "¿Palets?",
		tiene_frigorificos: "¿Tiene frigorífico?",
		// numero_frigorificos: "Nº de frigoríficos",
		tiene_congeladores: "¿Tiene congelador?",
		// numero_congeladores: "Nº de congeladores",
		tipo_de_transporte: "Tipo transporte",
		// transporte_refrigerado: "¿Transporte refrigerado?",
		// capacidad_de_transporte: "Capacidad transporte (kg)",
		// m3_frigorifico: "Capacidad frigorífico (m2)",
		// m3_congelador: "Capacidad congelador (m2)",
		// existe_registro_entradas_salidas_existencias:
		// 	"¿Existe un registro de entradas/salidas para controlar existencias en el almacén?",
		// registro_entradas_salidas_existencias_guardados_5_años:
		// 	"¿Los documentos sobre entregas/salidas se guardan administrativamente durante 5 años?",
		// confidencialidad_datos_beneficiarios: "¿La información de los beneficiarios se guarda de manera confidencial?",
		// cumplen_lopd: "¿La EB cumple con la LOPD?",
		// realizan_renovacion_expedientes: "¿Realizan renovación de expedientes?",
		// periodo_de_renovacion_expedientes: "¿Con qué frecuencia los renuevan?",
	},
	seccion09: {
		// destino_subvenciones: "La EB asegura que las subvenciones recibidas se destinan a",
		// subvenciones_oficiales_nombre: "Subvenciones oficiales (Organismo)",
		// subvenciones_oficiales_importe: "Importe anual (€)",
		// ayudas_privadas_nombre: "Ayudas privadas",
		// ayudas_privadas_importe: "Importe anual (€) ayudas privadas",
		// ingresos_aportaciones_prestacion_nombre: "Aportaciones por prestación",
		// aportaciones_prestacion_importe: "Importe anual (€) aportes por prestación",
		// otras_fuentes_nombre: "Otras fuentes de ingresos",
		// ap6_otras_fuentes_importe: "Importe anual (€) otras fuentes de ingresos",
		// total_ingresos_ano: "Total ingresos año",
		// numero_asalariados: "Nº personas con salario",
		// importe_salarios: "Nº personas con salario",
		// cargas_sociales_nombre: "Cargas sociales",
		// cargas_sociales_importe: "Importe anual (€) cargas sociales ",
		// alquileres_servicios_nombre: "Alquiler / servicios",
		// alquileres_servicios_importe: "Importe anual (€) alquiler/servicios",
		// compra_alimentos_nombre: "Compra de alimentos",
		// compra_alimentos_importe: "Importe anual (€) compra de alimentos",
		// otros_nombre: "Otros gastos",
		// otros_importe: "Importe anual (€) otros gastos",
		// total_gastos_ano: "Total gastos año",
		// ingresos_gastos: "Diferencia ingresos y gastos",
	},
	// seccion10: {
	// 	observaciones: "Observaciones del visitador",
	// 	sugerencias_de_la_eb: "Sugerencias de la EB",
	// 	resumen_visitador: "Resumen del visitador",
	// },
};
