import axios from "axios";

import { CustomAxiosRequest } from "api";
import { envConfig } from "config/env";
import { Credentials } from "config/types/google";

export const postGoogleToken: CustomAxiosRequest<void, Credentials> = () => {
	const url = `${envConfig.API_URL}/evaluacion/documentos/postGoogleToken`;
	const config = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return axios(config);
};
