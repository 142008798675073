import React from "react";

import { Textarea, TextareaProps } from "@chakra-ui/react";
import { debounce } from "lodash";

import { useAppSelector } from "store/store";

import { useAutoInputSend } from "../../../views/entidad/components/secciones/useAutoInputSend";

type AdditionalProps = {
	name?: string;
	isDisabled?: boolean;
	defaultValue?: string;
};

type IInputFieldProps = TextareaProps & AdditionalProps;

const TextAreaInput: React.FC<IInputFieldProps> = ({ ...props }) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const { defaultValue, ...restProps } = props;
	const [inputValue, setInputValue] = React.useState<string | undefined>(defaultValue || undefined);
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const debouncedChangeHandler = React.useMemo(
		() =>
			debounce(
				(event: React.ChangeEvent<HTMLTextAreaElement>) => sendInputValue(event.target.name, event.target.value),
				700,
			),
		[],
	);

	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;
		setInputValue(value);
		debouncedChangeHandler(event);
	};

	const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		const position = event.target.value.length;
		event.target.selectionStart = position;
		event.target.selectionEnd = position;
	};

	return (
		<Textarea value={inputValue} onChange={handleInputChange} onFocus={handleFocus} ref={inputRef} {...restProps} />
	);
};

export default TextAreaInput;
