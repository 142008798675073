import React, { useState } from "react";

import { Switch, FormLabel, Flex, HStack, IconButton, Icon } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

import { useAppSelector } from "store/store";

interface SeccionSwitchProps {
	seccionCompletada?: boolean;
	onSeccionCompletadaChange?: (seccionCompletada: boolean) => void;
	hasNextSection?: boolean;
	nextSection?: () => void;
	isDisabled?: boolean;
	section10Disabled?: boolean;
}

const SeccionSwitch: React.FC<SeccionSwitchProps> = (props) => {
	const { seccionCompletada, onSeccionCompletadaChange, nextSection, hasNextSection, isDisabled, section10Disabled } =
		props;
	const [isChecked, setIsChecked] = useState<boolean>(seccionCompletada || false);

	const evaluacionSeleccionada = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);

	const botonDataSelector = {
		Programada: { color: "gray.400", hover: "gray.500" },
		"En proceso": { color: "blue.400", hover: "blue.500" },
		Adelantada: { color: "blue.400", hover: "blue.500" },
		Completada: { color: "teal.400", hover: "teal.500" },
		Supervisada: { color: "green.400", hover: "green.500" },
		Rechazada: { color: "gray.400", hover: "gray.500" },
	};

	const estadoBoton = React.useMemo(() => {
		if (!evaluacionSeleccionada?.estado_evaluacion) return;
		return botonDataSelector[evaluacionSeleccionada.estado_evaluacion];
	}, [evaluacionSeleccionada?.estado_evaluacion]);

	const handleSwitchChange = React.useCallback(() => {
		setIsChecked(!isChecked);
		onSeccionCompletadaChange && onSeccionCompletadaChange(!isChecked);
	}, [isChecked, onSeccionCompletadaChange]);

	return (
		<React.Fragment>
			{estadoBoton && (
				<HStack
					width="100%"
					justifyContent="space-between"
					borderTop="1px solid"
					borderColor="gray.200"
					paddingTop="1.5rem"
				>
					<Flex>
						<Switch
							id="email-alerts"
							marginRight="0.75rem"
							onChange={handleSwitchChange}
							isChecked={isChecked}
							isDisabled={isDisabled}
						/>
						<FormLabel htmlFor="seccion-completada" mb="0" textStyle="body3" color="gray.600">
							Capítulo completado
						</FormLabel>
					</Flex>

					{hasNextSection && (
						<IconButton
							aria-label="Next section"
							isDisabled={!isChecked || section10Disabled}
							icon={<Icon as={FiChevronRight} />}
							{...(estadoBoton && {
								backgroundColor: estadoBoton.color,
								_hover: { bg: estadoBoton.hover },
							})}
							onClick={nextSection}
							_active={{ bg: "gray.400" }}
						/>
					)}
				</HStack>
			)}
		</React.Fragment>
	);
};

export default SeccionSwitch;
