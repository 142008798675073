import { createAsyncThunk } from "@reduxjs/toolkit";

import { getEntidades, getEntidadesStats, getPoblacion } from "api/entidad";
import { getGrupoVisitadores, getVisitadores } from "api/visitadores";
import { EntidadesFilterSelection } from "config/types/entidad";
import { SLICE_NAMES } from "store/constant";

type getEntidadesThunkParams = {
	codEB?: string;
	filterSelection?: EntidadesFilterSelection;
	page?: number;
	order?: string;
};
export const getEntidadesThunk = createAsyncThunk(
	`${SLICE_NAMES.ENTIDADES}/getEntidades`,
	async (payload: getEntidadesThunkParams) => {
		const { codEB, filterSelection, page, order } = payload;
		const response = await getEntidades({
			codEB,
			...(filterSelection && filterSelection.busqueda && { busqueda: filterSelection.busqueda }),
			...(filterSelection && filterSelection.grupoVisitador && { grupoVisitador: filterSelection.grupoVisitador }),
			...(filterSelection && filterSelection.visitador && { visitador: filterSelection.visitador }),
			...(filterSelection && filterSelection.tipoDeActividad && { tipoDeActividad: filterSelection.tipoDeActividad }),
			...(filterSelection &&
				filterSelection.existenAnomalias && { existenAnomalias: filterSelection.existenAnomalias }),
			...(filterSelection &&
				filterSelection.estadoEvaluacion && { estadoEvaluacion: filterSelection.estadoEvaluacion.toString() }),
			...(filterSelection && filterSelection.fechaInicio && { fechaInicio: filterSelection.fechaInicio }),
			...(filterSelection && filterSelection.fechaFinal && { fechaFinal: filterSelection.fechaFinal }),
			...(filterSelection && filterSelection.status && { status: filterSelection.status.toString() }),
			...(filterSelection && filterSelection.poblacion && { poblacion: filterSelection.poblacion.toString() }),
			order,
			page,
		});
		return response.data;
	},
);

type getEntidadesStatsThunkParams = {
	codEB?: string;
	filterSelection?: EntidadesFilterSelection;
};
export const getEntidadesStatsThunk = createAsyncThunk(
	`${SLICE_NAMES.ENTIDADES}/getEntidadesStats`,
	async (payload: getEntidadesStatsThunkParams) => {
		const { codEB, filterSelection } = payload;
		const response = await getEntidadesStats({
			codEB,
			...(filterSelection && filterSelection.busqueda && { busqueda: filterSelection.busqueda }),
			...(filterSelection && filterSelection.grupoVisitador && { grupoVisitador: filterSelection.grupoVisitador }),
			...(filterSelection && filterSelection.visitador && { visitador: filterSelection.visitador }),
			...(filterSelection && filterSelection.tipoDeActividad && { tipoDeActividad: filterSelection.tipoDeActividad }),
			...(filterSelection &&
				filterSelection.existenAnomalias && { existenAnomalias: filterSelection.existenAnomalias }),
			...(filterSelection &&
				filterSelection.estadoEvaluacion && { estadoEvaluacion: filterSelection.estadoEvaluacion.toString() }),
			...(filterSelection && filterSelection.fechaInicio && { fechaInicio: filterSelection.fechaInicio }),
			...(filterSelection && filterSelection.fechaFinal && { fechaFinal: filterSelection.fechaFinal }),
			...(filterSelection && filterSelection.poblacion && { poblacion: filterSelection.poblacion.toString() }),
		});
		return response.data;
	},
);

export const getGrupoVisitadoresThunk = createAsyncThunk(`${SLICE_NAMES.ENTIDADES}/getGrupoVisitadores`, async () => {
	const response = await getGrupoVisitadores(null);
	return response.data;
});

export interface getVisitadoresParams {
	grupoVisitadores?: string;
}

export const getVisitadoresThunk = createAsyncThunk(
	`${SLICE_NAMES.ENTIDADES}/getVisitadores`,
	async (payload: getVisitadoresParams) => {
		const response = await getVisitadores(payload);
		return response.data;
	},
);

export const getPoblacionesThunk = createAsyncThunk(`${SLICE_NAMES.ENTIDADES}/getPoblacion`, async () => {
	const response = await getPoblacion();
	return response.data;
});
