import jwtDecode from "jwt-decode";

export interface IToken {
	exp: number;
	iat: number;
	given_name: string;
	family_name: string;
	picture: string;
	email: string;
}

export const isTokenExpired = (token: string) => jwtDecode<{ exp: number }>(token).exp * 1000 + 60000 < Date.now();
