import React from "react";

import { Input, InputProps, Text, Flex } from "@chakra-ui/react";
import { debounce } from "lodash";
import * as Yup from "yup";

import { useAppSelector } from "store/store";

import { useAutoInputSend } from "../../../views/entidad/components/secciones/useAutoInputSend";

type ValidationErrors = {
	[key: string]: string | number | undefined;
};

type AdditionalProps = {
	name?: string;
	isDisabled?: boolean;
	defaultValue?: string | number;
};

type IInputFieldProps = InputProps & AdditionalProps;

const InputField: React.FC<IInputFieldProps> = (props) => {
	const { name, isDisabled, defaultValue } = props;
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = React.useState<string | number | undefined>(defaultValue || undefined);
	const evaluacion = useAppSelector((state) => state.evaluaciones.evaluacionSeleccionada.data);

	const { sendInputValue } = useAutoInputSend(
		evaluacion?.cod_eb.toString() || "",
		evaluacion?.numero_evaluacion.toString() || "",
	);

	const [errors, setErrors] = React.useState<ValidationErrors>({});

	const validationSchema = Yup.object().shape({
		dias_semana_desayunos: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El número mínimo de días es 0")
			.max(7, "El número máximo de días es 7"),
		dias_semana_comidas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El número mínimo de días es 0")
			.max(7, "El número máximo de días es 7"),
		dias_semana_meriendas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El número mínimo de días es 0")
			.max(7, "El número máximo de días es 7"),
		dias_semana_cenas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El número mínimo de días es 0")
			.max(7, "El número máximo de días es 7"),
		email: Yup.string().email("El correo electrónico no es válido"),
		email_responsable: Yup.string().email("El correo electrónico no es válido"),
		porcentaje_desayunos: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El porcentaje mínimo es 0")
			.max(100, "El porcentaje máximo es 100"),
		porcentaje_comidas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El porcentaje mínimo es 0")
			.max(100, "El porcentaje máximo es 100"),
		porcentaje_meriendas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El porcentaje mínimo es 0")
			.max(100, "El porcentaje máximo es 100"),
		porcentaje_cenas: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El porcentaje mínimo es 0")
			.max(100, "El porcentaje máximo es 100"),
		importe_salarios: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El importe mínimo es 0"),
		cargas_sociales_importe: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El importe mínimo es 0"),
		alquileres_servicios_importe: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El importe mínimo es 0"),
		compra_alimentos_importe: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El importe mínimo es 0"),
		otros_importe: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(0, "El importe mínimo es 0"),
		meses_actividad_ano: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.min(1, "El número mínimo de meses es 1")
			.max(12, "El número máximo de meses es 12"),
		numero_frigorificos: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.integer("El número de frigoríficos debe ser un número entero")
			.min(0, "El número mínimo de frigoríficos es 0"),
		numero_congeladores: Yup.number()
			.transform((value) => (isNaN(Number(value)) && value !== undefined ? 0 : value))
			.integer("El número de congeladores debe ser un número entero")
			.min(0, "El número mínimo de congeladores es 0"),
	});

	const validateInput = async (name: string, value: string | number | undefined): Promise<boolean> => {
		if (name && validationSchema.fields[name]) {
			try {
				await validationSchema.fields[name].validate(value);
				setErrors((errors) => ({ ...errors, [name]: undefined }));
				return true;
			} catch (err) {
				setErrors((errors) => ({ ...errors, [name]: (err as Yup.ValidationError).message }));
				return false;
			}
		}
		return true;
	};

	const debouncedChangeHandler = React.useMemo(
		() => debounce((name: string, value: string | number) => sendInputValue(name, value), 700),
		[],
	);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setInputValue(value);

		validateInput(name, value).then((isValid) => {
			if (isValid) {
				if (typeof value === "string" && value.match(/,\d{1,2}$/)) {
					const newValue = value.replace(",", ".");
					debouncedChangeHandler(name, newValue);
				} else {
					debouncedChangeHandler(name, value);
				}
			}
		});
	};

	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const position = event.target.value.length;
		event.target.selectionStart = position;
		event.target.selectionEnd = position;
	};

	return (
		<Flex direction="column" width="100%" alignItems="flex-start" position="relative">
			<Input
				name={name}
				value={inputValue}
				onChange={handleInputChange}
				onFocus={handleFocus}
				ref={inputRef}
				isDisabled={isDisabled}
				{...props}
				borderColor={name && errors[name] ? "red" : "gray.300"}
			/>
			{name && errors[name] && (
				<Text textStyle="body4" color="red" position="absolute" bottom="-20px" left="0">
					{errors[name]}
				</Text>
			)}
		</Flex>
	);
};

export default InputField;
