import React from "react";

import { Box, Flex } from "@chakra-ui/react";

import { EntidadesStatsOutput } from "config/types/entidad";

interface IMultiColorBarProps {
	stats: EntidadesStatsOutput;
}

export const MultiColorBar: React.FC<IMultiColorBarProps> = (props) => {
	const { stats } = props;

	const sumStats = Object.values(stats).reduce((a, b) => a + b, 0);

	const colors = {
		Validada: "#85DB80",
		Supervisada: "#77DCCC",
		Completada: "#6CC9F6",
		Rechazada: "#FA777C",
		"En proceso": "#FEB377",
		Adelantada: "#718096",
		Programada: "#CBD5E0",
	};

	const orderedKeys = Object.keys(colors).filter((key) => stats[key]);

	return (
		<Box width="100%" backgroundColor="gray.100" borderRadius="0.25rem">
			<Flex flexDir="row" width="100%" minWidth="15%" height="1rem" borderRadius="0.25rem" overflow="hidden">
				{orderedKeys.map((key, index) => (
					<Box
						key={index}
						width={(stats[key] / sumStats) * 100 + "%"}
						backgroundColor={colors[key]}
						transition="width 0.5s ease"
					/>
				))}
			</Flex>
		</Box>
	);
};
