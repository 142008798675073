import React from "react";

import { Box, Flex, GridItem, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";
import { useParams } from "react-router-dom";

import { TabletTableHeader } from "common/components/TabletHeader";
import { THUNK_STATE } from "config/types";
import { getEntidadesThunk } from "store/entidades/thunks";
import {
	getEvaluacionAnteriorThunk,
	getEvaluacionThunk,
	getEvaluacionesThunk,
	postGoogleTokenThunk,
} from "store/evaluaciones/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { Conversation } from "./components/Conversation/Conversation";
import { Evaluacion } from "./components/Evaluacion/Evaluacion";
import { Print } from "./components/Print/Print";
import { Sidebar } from "./components/Sidebar/Sidebar";

export const Entidad: React.FC = () => {
	const { codEB, numeroEvaluacion } = useParams();
	const dispatch = useAppDispatch();

	const printView = useAppSelector((state) => state.usuario.printView);
	const googleToken = useAppSelector((state) => state.evaluaciones.googleDrive.data.authToken);

	const { thunkState, permissions } = useAppSelector((state) => state.usuario);
	const userStatus = permissions && permissions.data && permissions.data[0]?.situacion;
	const userIsActive = userStatus === "Alta";

	React.useEffect(() => {
		if (!googleToken?.access_token) {
			dispatch(postGoogleTokenThunk());
		}

		if (googleToken && googleToken.expiry_date && googleToken.expiry_date < new Date().getTime()) {
			dispatch(postGoogleTokenThunk());
		}
	}, [googleToken]);

	React.useEffect(() => {
		if (codEB && numeroEvaluacion) {
			dispatch(getEntidadesThunk({ codEB, filterSelection: { status: ["alta", "baja"] } }));
			dispatch(getEvaluacionThunk({ codEB, numeroEvaluacion }));
			dispatch(getEvaluacionAnteriorThunk({ codEB, numeroEvaluacion: Number(numeroEvaluacion) }));
			dispatch(getEvaluacionesThunk({ codEB }));
		}
	}, [codEB, numeroEvaluacion]);

	return (
		<React.Fragment>
			{THUNK_STATE.FULFILLED === thunkState && userIsActive && (
				<SimpleGrid columns={{ sm: 1, md: 4 }} spacingX="1.5rem" spacingY="1.5rem" mt="2rem" pb="5rem">
					<GridItem colSpan={1} display={{ sm: "none", md: "block" }}>
						<Sidebar />
					</GridItem>
					<GridItem colSpan={1} display={{ sm: "block", md: "none" }}>
						<Flex align="center" justify="space-between" width="100%">
							<TabletTableHeader />
						</Flex>
					</GridItem>
					<GridItem colSpan={2}>
						<Evaluacion />
					</GridItem>
					<GridItem colSpan={1} display={{ sm: "none", md: "block" }}>
						<Conversation />
					</GridItem>
				</SimpleGrid>
			)}
			{THUNK_STATE.FULFILLED === thunkState && !userIsActive && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					mt="2rem"
					maxH={"calc(95dvh - 10dvh)"}
				>
					<Box
						p="2rem"
						borderRadius="0.5rem"
						backgroundColor="white"
						mt="5rem"
						textAlign="center"
						borderColor={"gray.200"}
						borderWidth="2px"
					>
						<Icon as={FiAlertTriangle} boxSize="2rem" color="red" h="2.5rem" w="2.5rem" />
						<Text textStyle="heading5" fontWeight="bold" color="gray.700">
							No tienes permisos para ver esta página
						</Text>
						<Text textStyle="body1" color="gray.600">
							Contacta con el administrador
						</Text>
					</Box>
				</Box>
			)}
			{printView && <Print />}
		</React.Fragment>
	);
};
